/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  showPriceValue,
  showAlert,
  checkingDiscount,
} from "../Helpers/SettingHelper";
import { format, add } from "date-fns";
import {
  instant_confirmation,
  calday,
  dollar,
  closeicon,
  mpin,
  bcal,
  bpro,
  actinfo1,
} from "../Helpers/Images";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Button, Divider, AvatarGroup, Avatar } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./CustomerPackageActivities/CustomerPackageActivities.scss";
import FlightIcon from "../../common/images/p_flight.svg";
import TicketIcon from "../../common/images/p_ticket.svg";
import BedIcon from "../../common/images/p_bed.svg";
import CalendarIcon from "../../common/images/calendar1.svg";
import ProfileIcon from "../../common/images/profile.svg";
import MoonIcon from "../../common/images/moon.svg";
import GlobalIcon from "../../common/images/global.svg";
import CarIcon from "../../common/images/p_car.svg";
import DiscountIcon from "../../common/images/discount.svg";
import AsstBannerBgVec from "../../common/images/asst-banner-bg.svg";
import AsstWhatappIcon from "../../common/images/asst-whatsapp-icon.svg";
import AsstCallIcon from "../../common/images/asst-call-icon.svg";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AssistancePopup from "../Customerpackage/AssistanceHelpPopup/AssistancePopup";
import AssistanceCard from "./AssistanceHelpPopup/AssistanceCard";
import AssetChevronRight from "../../common/images/asst-chevron-right.svg";
var Parser = require("html-react-parser");
var base64 = require("base-64");
class Customerpackageactivities extends Component {
  constructor(props) {
    super(props);
    /*    console.log(this.props,'props active') */
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";

    var searchdata = "";
    var checkinTime = "";
    var end_date = "";
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      checkinTime = searchdata.checkinTime;
      end_date = searchdata.end_date;
    } else {
      this.props.history.push("/");
    }
    var packageSlug = "";
    if (
      this.props.location.state.packageSlug !== "" &&
      typeof this.props.location.state.packageSlug !== undefined &&
      typeof this.props.location.state.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.location.state.packageSlug;
    } else {
      this.props.history.push("/");
    }
    var ownpackage = false;
    if (packageSlug === "create-own-package") {
      ownpackage = true;
    }

    var flightList =
      this.props.location.state.flightList !== "" &&
      typeof this.props.location.state.flightList !== undefined &&
      typeof this.props.location.state.flightList !== "undefined"
        ? this.props.location.state.flightList
        : [];

    var selectedflight =
      this.props.location.state.selectedflight !== "" &&
      typeof this.props.location.state.selectedflight !== undefined &&
      typeof this.props.location.state.selectedflight !== "undefined"
        ? this.props.location.state.selectedflight
        : "";
    var flightPrice =
      this.props.location.state.flightPrice !== "" &&
      typeof this.props.location.state.flightPrice !== undefined &&
      typeof this.props.location.state.flightPrice !== "undefined"
        ? this.props.location.state.flightPrice
        : "";
    var flightcheapestPrice =
      this.props.location.state.flightcheapestPrice !== "" &&
      typeof this.props.location.state.flightcheapestPrice !== undefined &&
      typeof this.props.location.state.flightcheapestPrice !== "undefined"
        ? this.props.location.state.flightcheapestPrice
        : "";

    var flightPriceType =
      this.props.location.state.flightPriceType !== "" &&
      typeof this.props.location.state.flightPriceType !== undefined &&
      typeof this.props.location.state.flightPriceType !== "undefined"
        ? this.props.location.state.flightPriceType
        : "";

    var addFlight =
      this.props.location.state.addFlight !== "" &&
      typeof this.props.location.state.addFlight !== undefined &&
      typeof this.props.location.state.addFlight !== "undefined"
        ? this.props.location.state.addFlight
        : "No";
    var addFlightReturn =
      this.props.location.state.addFlightReturn !== "" &&
      typeof this.props.location.state.addFlightReturn !== undefined &&
      typeof this.props.location.state.addFlightReturn !== "undefined"
        ? this.props.location.state.addFlightReturn
        : "No";

    var selectedHotelID =
      this.props.location.state.selectedHotelID !== "" &&
      typeof this.props.location.state.selectedHotelID !== undefined &&
      typeof this.props.location.state.selectedHotelID !== "undefined"
        ? this.props.location.state.selectedHotelID
        : "";

    var selectedHotel =
      this.props.location.state.selectedHotel !== "" &&
      typeof this.props.location.state.selectedHotel !== undefined &&
      typeof this.props.location.state.selectedHotel !== "undefined"
        ? this.props.location.state.selectedHotel
        : [];

    var selectedRoom =
      this.props.location.state.selectedRoom !== "" &&
      typeof this.props.location.state.selectedRoom !== undefined &&
      typeof this.props.location.state.selectedRoom !== "undefined"
        ? this.props.location.state.selectedRoom
        : "";

    var activities =
      this.props.location.state.activities !== "" &&
      typeof this.props.location.state.activities !== undefined &&
      typeof this.props.location.state.activities !== "undefined"
        ? this.props.location.state.activities
        : [];
    var oldactivities =
      this.props.location.state.oldactivities !== "" &&
      typeof this.props.location.state.oldactivities !== undefined &&
      typeof this.props.location.state.oldactivities !== "undefined"
        ? this.props.location.state.oldactivities
        : [];

    var removeFlight =
      this.props.location.state.removeFlight !== "" &&
      typeof this.props.location.state.removeFlight !== undefined &&
      typeof this.props.location.state.removeFlight !== "undefined"
        ? this.props.location.state.removeFlight
        : "No";

    var transferList =
      this.props.location.state.transferList !== "" &&
      typeof this.props.location.state.transferList !== undefined &&
      typeof this.props.location.state.transferList !== "undefined"
        ? this.props.location.state.transferList
        : [];

    let selectedTransfer =this.props.location.state?.selectedTransfer ?? "";

    var reserveTransfer =
      this.props.location.state.reserveTransfer !== "" &&
      typeof this.props.location.state.reserveTransfer !== undefined &&
      typeof this.props.location.state.reserveTransfer !== "undefined"
        ? this.props.location.state.reserveTransfer
        : [];

    var stayList =
      this.props.location.state.stayList !== "" &&
      typeof this.props.location.state.stayList !== undefined &&
      typeof this.props.location.state.stayList !== "undefined"
        ? this.props.location.state.stayList
        : [];

    var addTransfer =
      this.props.location.state.addTransfer !== "" &&
      typeof this.props.location.state.addTransfer !== undefined &&
      typeof this.props.location.state.addTransfer !== "undefined"
        ? this.props.location.state.addTransfer
        : "No";
    var emptyFlight =
      this.props.location.state.emptyFlight !== "" &&
      typeof this.props.location.state.emptyFlight !== undefined &&
      typeof this.props.location.state.emptyFlight !== "undefined"
        ? this.props.location.state.emptyFlight
        : "Yes";

    var removetransfer =
      this.props.location.state.removetransfer !== "" &&
      typeof this.props.location.state.removetransfer !== undefined &&
      typeof this.props.location.state.removetransfer !== "undefined"
        ? this.props.location.state.removetransfer
        : "No";

    var emptyhotel =
      this.props.location.state.emptyhotel !== "" &&
      typeof this.props.location.state.emptyhotel !== undefined &&
      typeof this.props.location.state.emptyhotel !== "undefined"
        ? this.props.location.state.emptyhotel
        : "Yes";
    var emptytransfer =
      this.props.location.state.emptytransfer !== "" &&
      typeof this.props.location.state.emptytransfer !== undefined &&
      typeof this.props.location.state.emptytransfer !== "undefined"
        ? this.props.location.state.emptytransfer
        : "Yes";
    var removedLastActivity =
      this.props.location.state.removedLastActivity !== "" &&
      typeof this.props.location.state.removedLastActivity !== undefined &&
      typeof this.props.location.state.removedLastActivity !== "undefined"
        ? this.props.location.state.removedLastActivity
        : false;

    var ArrivalDateFormat =
      this.props.location.state.ArrivalDateFormat !== "" &&
      typeof this.props.location.state.ArrivalDateFormat !== undefined &&
      typeof this.props.location.state.ArrivalDateFormat !== "undefined"
        ? this.props.location.state.ArrivalDateFormat
        : "";

    var packagedetails =
      this.props.location.state.packagedetails !== "" &&
      typeof this.props.location.state.packagedetails !== undefined &&
      typeof this.props.location.state.packagedetails !== "undefined"
        ? this.props.location.state.packagedetails
        : "";
    var ttdOnly = false;
    if (
      this.props.location.state.searchdata.ttdOnly !== "" &&
      typeof this.props.location.state.searchdata.ttdOnly !== undefined &&
      typeof this.props.location.state.searchdata.ttdOnly !== "undefined"
    ) {
      ttdOnly = this.props.location.state.searchdata.ttdOnly;
    }
    this.state = {
      userID: userID,
      isMobile: window.innerWidth <= 480,
      /* Search Data & Package Details */
      searchdata: searchdata,
      packageSlug: packageSlug,
      packageDetails: packagedetails,
      end_date: end_date,
      ttdOnly: ttdOnly,
      /* Fligh */
      flightList: flightList,
      selectedflight: selectedflight,
      flightPrice: flightPrice,
      flightcheapestPrice: flightcheapestPrice,
      flightPriceType: flightPriceType,
      addFlight: addFlight,
      addFlightReturn: addFlightReturn,
      removeFlight: removeFlight,

      flightPriceTypeReturn:
        this.props.location.state?.flightPriceTypeReturn || "",
      mystiflyPricestatusReturn:
        this.props.location.state?.mystiflyPricestatusReturn || "",
      flightPriceReturn: this.props.location.state?.flightPriceReturn || "",
      flightcheapestPriceReturn:
        this.props.location.state?.flightcheapestPriceReturn || "",

      /* Activities */
      activities: activities,
      oldactivities: oldactivities,
      removedLastActivity: removedLastActivity,
      ArrivalDateFormat: ArrivalDateFormat,
      /* Hotel */
      selectedHotel: selectedHotel,
      selectedHotelID: selectedHotelID,
      stayList: stayList,
      selectedRoom: selectedRoom,
      /* Transfer */
      transferList: transferList,
      selectedTransfer: selectedTransfer,
      reserveTransfer: reserveTransfer,
      addTransfer: addTransfer,
      removetransfer: removetransfer,

      reserveTransferReturn:
        this.props.location?.state?.reserveTransferReturn || [],
      transferPriceReturn: 0,
      addTransferReturn: this.props.location?.state?.addTransferReturn || "No",
      removetransferReturn:
        this.props.location?.state?.removetransferReturn || "No",

      /*Empty Status*/
      emptyFlight: emptyFlight,
      emptyhotel: emptyhotel,
      emptytransfer: emptytransfer,
      ownpackage: ownpackage,
      platformfee: 10,
      totalActvitiesPrice: 0,
      totalActivity: 0,
      totalFlightPrice: 0,
      totalFlightPriceReturn: 0,
      subTotal: 0,
      grandTotal: 0,
      adultsQty: 0,
      childQty: 0,
      infantQty: 0,
      adultPrice: 0,
      childPrice: 0,
      infantPrice: 0,
      totalHotelPrice: 0,
      transferPrice: 0,
      pouppackage: "",
      triggerLogin: false,
      activityItem: [],
      activityIndex: null,
      dayIndex: null,
      showFareBreakdown: false,
      showSelectInfo: false,
      isFlight: false,
      isActivities: false,
      isVacation: true,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      showAssistanceCard: false,
      showAssistancePopup: "",
      expandedItems: [],
      selectedFlightType:this.props.location.state?.selectedFlightType ?? '',
      selectedFlightTypeReturn:this.props.location.state?.selectedFlightTypeReturn ?? '',
    };
  }
  componentDidMount() {
    this.calculatePrice();
    if (this.state.searchdata?.packageType !== "uop") {
      this.interval = setTimeout(() => {
        this.setState({ showAssistanceCard: true });
      }, 60000);
    }
  }

  tripDetails = () => {
    return (
      <>
        {this.state.packageSlug === "create-own-package" && (
          <div className="rhs-white-box pack-info-box">
            <h5>
              Trip details{" "}
              <a href={void 0} onClick={this.openCalendar}>
                Edit days
              </a>
            </h5>

            <ul>
              <li>
                <span>
                  <img src={mpin} /> From
                </span>
                <span>
                  {this.state.searchdata.sourceCity}{" "}
                  {this.state.searchdata.source_airport_code}
                </span>
              </li>
              <li>
                <span>
                  <img src={mpin} /> To
                </span>
                <span>
                  {this.state.searchdata.destinationCity}{" "}
                  {this.state.searchdata.destination_airport_code}
                </span>
              </li>
              <li>
                <span>
                  <img src={bcal} /> Start date
                </span>
                <span>
                  {new Date(
                    this.state.searchdata.start_date
                  ).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "2-digit",
                  })}
                </span>
              </li>
              <li>
                <span>
                  <img src={bcal} /> End date
                </span>
                <span>
                  {new Date(this.state.end_date).toLocaleDateString("en-IN", {
                    day: "numeric",
                    month: "short",
                    year: "2-digit",
                  })}
                </span>
              </li>
              <li>
                <span>
                  <img src={bpro} /> Travelers
                </span>
                <span>
                  &nbsp;&nbsp;&nbsp; &nbsp;
                  {this.state.searchdata.adults} x adult&nbsp;
                  {this.state.searchdata.child > 0 && (
                    <>{this.state.searchdata.child} x child</>
                  )}
                  {this.state.searchdata.infant > 0 && (
                    <> {this.state.searchdata.infant} x infant</>
                  )}
                </span>
              </li>
            </ul>
            {!this.state.isClicked && (
              <div className="cal-price">
                <a href={void 0} className="button" onClick={this.handleClick}>
                  Calculate price
                </a>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  loadRatingstar(points, type = "") {
    if (type === "hotel") {
      if (points === "Economy*") {
        points = "1.0";
      } else if (points === "Budget **") {
        points = "2.0";
      } else if (points === "Standard ***") {
        points = "3.0";
      } else if (points === "Superior ****") {
        points = "4.0";
      } else if (points === "Luxury *****") {
        points = "5.0";
      } else if (points === "Serviced Apartment") {
        points = "0.0";
      } else if (points === "Unrated") {
        points = "0.0";
      }
    }

    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  calculatePrice() {
    try {
      var adults =
        this.state.searchdata.adults !== "" &&
        typeof this.state.searchdata.adults !== undefined &&
        typeof this.state.searchdata.adults !== "undefined"
          ? this.state.searchdata.adults
          : "";
      var child =
        this.state.searchdata.child !== "" &&
        typeof this.state.searchdata.child !== undefined &&
        typeof this.state.searchdata.child !== "undefined"
          ? this.state.searchdata.child
          : 0;

      var infant =
        this.state.searchdata.infant !== "" &&
        typeof this.state.searchdata.infant !== undefined &&
        typeof this.state.searchdata.infant !== "undefined"
          ? this.state.searchdata.infant
          : 0;
      var totalActvitiesPrice = 0;
      var totalActivity = 0;
      var adultPrice = 0;
      var childPrice = 0;
      var infantPrice = 0;
      if (this.state.activities.length > 0) {
        this.state.activities.map((item) => {
          if (item.activities.length > 0) {
            item.activities.map((item1) => {
              if (item1 !== "") {
                if (
                  item1.ticket_type.length > 0 &&
                  item1.activity_availablity === "Yes"
                ) {
                  totalActivity++;
                  item1.ticket_type.map((item2) => {
                    var currentQty =
                      item2.quantity !== "" ? parseInt(item2.quantity) : 0;
                    if (currentQty > 0) {
                      if (item2.package_price_name === "ADULT") {
                        adultPrice =
                          parseFloat(adultPrice) +
                          parseFloat(item2.package_pricenettPrice) *
                            parseInt(currentQty);
                      }
                      if (item2.package_price_name === "CHILD") {
                        childPrice =
                          parseFloat(childPrice) +
                          parseFloat(item2.package_pricenettPrice) *
                            parseInt(currentQty);
                      }
                      totalActvitiesPrice =
                        parseFloat(totalActvitiesPrice) +
                        parseFloat(item2.package_pricenettPrice) *
                          parseInt(currentQty);
                    }
                  });
                }
              }
            });
          }
        });
      }
      var flightPriceList = this.state.flightPrice;
      if (this.state.selectedFlightType === "cheapest") {
        flightPriceList = this.state.flightcheapestPrice;
      }
      var flight_Price = 0;
      if (
        flightPriceList.length !== 0 &&
        typeof flightPriceList !== undefined &&
        typeof flightPriceList !== "undefined"
      ) {
        flight_Price = parseFloat(flightPriceList.totalPayAmount);
        if (flightPriceList.pricedetails.length > 0) {
          flightPriceList.pricedetails.map((item) => {
            if (item.passengerCode === "ADT") {
              adultPrice =
                parseFloat(adultPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "CHD") {
              childPrice =
                parseFloat(childPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "INF") {
              infantPrice =
                parseFloat(infantPrice) + parseFloat(item.passengerTotalprice);
            }
          });
        }
      }
      var flightPriceListReturn = this.state.flightPriceReturn;
      if (this.state.selectedFlightTypeReturn === "cheapest") {
          flightPriceListReturn = this.state.flightcheapestPriceReturn;
        }
      var flight_PriceReturn = 0;
      if (
        flightPriceListReturn.length !== 0 &&
        typeof flightPriceListReturn !== undefined &&
        typeof flightPriceListReturn !== "undefined"
      ) {
        flight_PriceReturn = parseFloat(flightPriceListReturn.totalPayAmount);
        if (flightPriceListReturn.pricedetails.length > 0) {
          flightPriceListReturn.pricedetails.map((item) => {
            if (item.passengerCode === "ADT") {
              adultPrice =
                parseFloat(adultPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "CHD") {
              childPrice =
                parseFloat(childPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "INF") {
              infantPrice =
                parseFloat(infantPrice) + parseFloat(item.passengerTotalprice);
            }
          });
        }
      }
      var totalHotelPrice = 0;
      if (
        this.state.selectedRoom.length > 0 &&
        Object.keys(this.state.selectedHotel).length > 0
      ) {
        this.state.selectedRoom.map((item) => {
          var rate_total = 0;
          if (
            this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== "" &&
            typeof this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== undefined &&
            typeof this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== "undefined"
          ) {
            if (
              this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== "" &&
              typeof this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== undefined &&
              typeof this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== "undefined"
            ) {
              rate_total =
                this.state.selectedHotel.room_info[item.roomMainIndex][
                  item.roomIndex
                ].room_charges[item.priceIndex].room_total_amount;
            }
          }

          totalHotelPrice =
            parseFloat(totalHotelPrice) + parseFloat(rate_total);
        });
      }

      var transferPrice = 0;
      if (
        this.state.reserveTransfer !== "" &&
        this.state.addTransfer === "Yes" &&
        this.state.removetransfer == "No"
      ) {
        transferPrice = this.state.reserveTransfer.total_amount;
      }
      var transferPriceReturn = 0;
      if (
        this.state.reserveTransferReturn !== "" &&
        this.state.addTransferReturn === "Yes" &&
        this.state.removetransferReturn == "No"
      ) {
        transferPriceReturn = this.state.reserveTransferReturn.total_amount;
      }

      var addflightPrice = 0;
      if (this.state.addFlight === "Yes") {
        addflightPrice = flight_Price.toFixed("2");
      }

      var addflightPriceReturn = 0;
      if (this.state.addFlightReturn === "Yes") {
        addflightPriceReturn = flight_PriceReturn.toFixed("2");
      }
      /*       console.log(
        parseFloat(totalActvitiesPrice),"activi",
        parseFloat(addflightPrice) ,"flight",
        parseFloat(addflightPriceReturn) ,"flight retru",
        parseFloat(this.state.platformfee) ,"platfrom",
        parseFloat(totalHotelPrice) ,"hotel",
        parseFloat(transferPrice) ,"transfer",
        parseFloat(transferPriceReturn),"retrun transfer","prices") */
      var subTotal = (
        parseFloat(totalActvitiesPrice) +
        parseFloat(addflightPrice) +
        parseFloat(addflightPriceReturn) +
        parseFloat(this.state.platformfee) +
        parseFloat(totalHotelPrice) +
        parseFloat(transferPrice) +
        parseFloat(transferPriceReturn)
      ).toFixed(2);

      var grandTotal = subTotal;
      if (this.state.packageDetails !== "") {
        if (this.state.packageDetails.discount !== "") {
          var discount = checkingDiscount(
            this.state.packageDetails.discount,
            subTotal
          );
          grandTotal = subTotal - discount.amount;
        }
      }

      this.setState({
        totalActivity: totalActivity,
        totalActvitiesPrice: totalActvitiesPrice,
        totalFlightPrice: flight_Price.toFixed("2"),
        totalFlightPriceReturn:
          parseFloat(flight_PriceReturn) > 0
            ? flight_PriceReturn.toFixed("2")
            : 0,
        subTotal: subTotal,
        grandTotal: grandTotal,
        adultsQty: adults,
        childQty: child,
        infantQty: infant,
        adultPrice: adultPrice.toFixed(2),
        childPrice: childPrice.toFixed(2),
        infantPrice: infantPrice.toFixed(2),
        totalHotelPrice: totalHotelPrice,
        transferPriceReturn: transferPriceReturn,
        transferPrice: transferPrice,
      });
    } catch (err) {
      console.log(err);
    }
  }
  incdec(ticketIndex, activityIndex, dayIndex, type) {
    var finalActivity = [];
    this.state.activities.map((item, mdayIndex) => {
      if (mdayIndex === dayIndex) {
        var activityList = [];
        item.activities.map((activityItem, mactivityIndex) => {
          if (activityIndex === mactivityIndex) {
            var ticket_type = [];
            if (activityItem !== "") {
              if (activityItem.ticket_type.length > 0) {
                activityItem.ticket_type.map((ticketItem, mticketIndex) => {
                  if (ticketIndex === mticketIndex) {
                    var itemList = ticketItem;
                    var selectQty = "";
                    var currentQty =
                      ticketItem.quantity !== "" ? ticketItem.quantity : "";
                    if (currentQty !== "") {
                      var totalPasanger = 0;
                      if (ticketItem.package_price_name === "ADULT") {
                        totalPasanger = this.state.adultsQty;
                      } else if (ticketItem.package_price_name === "CHILD") {
                        totalPasanger = this.state.childQty;
                      } else {
                        totalPasanger =
                          parseInt(this.state.adultsQty) +
                          parseInt(this.state.childQty);
                      }
                      var allowQty = "No";
                      if (totalPasanger > currentQty) {
                        allowQty = "Yes";
                      }
                      if (type === "dec") {
                        allowQty = "Yes";
                      }
                      if (allowQty === "Yes") {
                        if (type === "inc") {
                          selectQty = parseInt(currentQty) + 1;
                        } else {
                          selectQty = parseInt(currentQty) - 1;
                        }
                      } else {
                        selectQty = currentQty;
                      }
                    } else {
                      if (type === "inc") {
                        selectQty = 1;
                      }
                    }
                    if (selectQty <= 0) {
                      selectQty = "";
                    }
                    itemList["quantity"] = selectQty;
                    ticket_type.push(itemList);
                  } else {
                    ticket_type.push(ticketItem);
                  }
                });
              }
            }
            activityList.push({
              activity_activity_package_id:
                activityItem.activity_activity_package_id,
              activity_activity_product: activityItem.activity_activity_product,
              creator_activity_day: activityItem.creator_activity_day,
              distance: activityItem.distance,
              globaltix_categories: activityItem.globaltix_categories,
              guided: activityItem.guided,
              latitude: activityItem.latitude,
              longitude: activityItem.longitude,
              ttd_duration: activityItem.ttd_duration,
              merchant_id: activityItem.merchant_id,
              product_category_name: activityItem.product_category_name,
              product_commission: activityItem.product_commission,
              product_payable_amount: activityItem.product_payable_amount,
              product_rating: activityItem.product_rating,
              product_slug: activityItem.product_slug,
              product_starting_price: activityItem.product_starting_price,
              product_tag: activityItem.product_tag,
              product_thumbnail: activityItem.product_thumbnail,
              product_total_package: activityItem.product_total_package,
              product_total_review: activityItem.product_total_review,
              products_description: activityItem.products_description,
              products_globaltix_id: activityItem.products_globaltix_id,
              products_id: activityItem.products_id,
              products_keywords: activityItem.products_keywords,
              products_name: activityItem.products_name,
              ticket_type: ticket_type,
              customer_activity: activityItem.customer_activity,
              activity_availablity: activityItem.activity_availablity,
              activity_slot_availablity: activityItem.activity_slot_availablity,
              activity_slot_list: activityItem.activity_slot_list,
              selected_slot: activityItem.selected_slot,
              package_confirmation: activityItem.package_confirmation,
              package_ticketValidity: activityItem.package_ticketValidity,
              package_ticketFormat: activityItem.package_ticketFormat,
              product_instantconfirmation:
                activityItem.product_instantconfirmation,
              package_name: activityItem.package_name,
              package_description: activityItem.package_description,
              package_inclusions: activityItem.package_inclusions,
              package_howtouse: activityItem.package_howtouse,
              package_cancellationNotes: activityItem.package_cancellationNotes,
              package_termsAndConditions:
                activityItem.package_termsAndConditions,
              products_addressLine: activityItem.products_addressLine,
              products_postalCode: activityItem.products_postalCode,
              product_operatingHours: activityItem.product_operatingHours,
            });
          } else {
            activityList.push(activityItem);
          }
        });
        finalActivity.push({
          date: item.date,
          day: item.day,
          emptyactivity: item.emptyactivity,
          activities: activityList,
        });
      } else {
        finalActivity.push(item);
      }
    });
    this.setState({ activities: finalActivity }, function () {
      this.calculatePrice();
    });
  }

  selectTimeSlot(dayIndex, activitiyIndex, timeslot) {
    var finalActivity = [];
    if (this.state.activities.length > 0) {
      this.state.activities.map((item, index) => {
        if (index === dayIndex) {
          if (item.activities.length > 0) {
            var newdayactivity = [];
            item.activities.map((actitem, actindex) => {
              if (actindex === activitiyIndex) {
                var updateActivities = actitem;
                updateActivities["selected_slot"] = [];
                updateActivities["selected_slot"].push(timeslot);
                newdayactivity.push(updateActivities);
              } else {
                newdayactivity.push(actitem);
              }
            });
            finalActivity.push({
              day: item.day,
              date: item.date,
              emptyactivity: item.emptyactivity,
              activities: newdayactivity,
            });
          } else {
            finalActivity.push(item);
          }
        } else {
          finalActivity.push(item);
        }
      });
    }
    this.setState({ activities: finalActivity });
  }

  continuebooknow() {
    var error = 0;
    var message = "";
    if (this.state.activities.length > 0 && error === 0) {
      var activityerror = "";
      this.state.activities.map((item) => {
        if (item.activities.length > 0) {
          item.activities.map((actItem) => {
            if (actItem !== "") {
              if (
                actItem.activity_slot_availablity === "Yes" &&
                actItem.selected_slot.length === 0
              ) {
                activityerror +=
                  "<p> Please select the time slot for " +
                  actItem.products_name +
                  " - Day" +
                  item.day +
                  "</p>";
              }
              var totalQty = 0;
              actItem.ticket_type.map((ticketTypeItem) => {
                if (ticketTypeItem.quantity !== "") {
                  totalQty =
                    parseInt(totalQty) + parseInt(ticketTypeItem.quantity);
                }
              });
              if (totalQty === 0) {
                activityerror +=
                  "<p> Please select any one passenger for " +
                  actItem.products_name +
                  " - Day" +
                  item.day +
                  "</p>";
                error++;
              }
            }
          });
        }
      });
      if (activityerror !== "") {
        showAlert("Error", activityerror);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        return false;
      }
    }
    var emptyActivity = "Yes";
    if (this.state.activities.length > 0) {
      this.state.activities.map((item) => {
        if (item.activities.length > 0) {
          emptyActivity = "No";
        }
      });
    }

    if (error === 0) {
      if (this.state.userID === "") {
        this.setState({ triggerLogin: true });
        return false;
      }
    }
    if (error === 0) {
      var searchdata = this.state.searchdata;
      var packageDetails = this.state.packageDetails;

      if (this.state.packageSlug === "create-own-package") {
        packageDetails = {};
        packageDetails["discount"] = "";
      }
      searchdata["end_date"] = this.state.end_date;
      var bookingdetails = this.props.location.state;

      bookingdetails["activities"] = this.state.activities;
      bookingdetails["oldactivities"] = this.state.oldactivities;
      bookingdetails["removedLastActivity"] = this.state.removedLastActivity;
      bookingdetails["totalActvitiesPrice"] = this.state.totalActvitiesPrice;
      this.props.history.push({
        pathname: "/summary",
        state: bookingdetails,
      });
    } else {
      showAlert("Error", message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  productDetails(product) {
    this.setState({ pouppackage: product }, function () {
      $.magnificPopup.open({
        items: {
          src: "#pacakge_popup",
        },
        type: "inline",
        mainClass: "package-info",
      });
    });
  }
  closePopup() {
    $.magnificPopup.close();
  }
  handleSelectInfo(activityItem, activityIndex, dayIndex) {
    this.setState(
      {
        activityItem: activityItem,
        activityIndex: activityIndex,
        dayIndex: dayIndex,
      },
      function () {
        this.setState({ showSelectInfo: true });
      }
    );
  }
  sateValChange = (field, value) => {
    if (field === "proceedtocontinue" && value === "Yes") {
      var newuserID =
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") != "undefined"
          ? cookie.load("UserId")
          : "";
      this.setState({ userID: newuserID }, function () {
        $.magnificPopup.close();
        this.continuebooknow();
      });
    }
    this.setState({ [field]: value });
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  goBack() {
    var bookingdetails = this.props.location.state;
    bookingdetails.selectedHotelID = base64.encode(
      bookingdetails.selectedHotelID
    );
    this.props.history.push({
      pathname: "/search-package/" + this.state.packageSlug,
      state: bookingdetails,
    });
  }

  handleAssistancePopup(value) {
    this.setState({ showAssistancePopup: value });
  }

  handleExpand = (activity) => {
    this.setState({
      expandedItems: this.state.expandedItems.includes(
        activity.activity_activity_package_id
      )
        ? [...this.state.expandedItems].filter(
            (id) => activity.activity_activity_package_id !== id
          )
        : [...this.state.expandedItems, activity.activity_activity_package_id],
    });
  };

  render() {
    /* console.log(this.state,"state values active") */
    var discountDetails = "";
    if (this.state.packageDetails !== "") {
      if (this.state.packageDetails.discount !== "") {
        discountDetails = checkingDiscount(
          this.state.packageDetails.discount,
          this.state.subTotal
        );
      }
    }
    return (
      <>
        <Header
          {...this.props}
          pagestate={this.state}
          sateValChange={this.sateValChange}
        />
        <div className="cp-activities-main">
          <div className="cp-activities-main__col1">
            <div className="cp-activities-main__col1__header">
              <ArrowBackIosIcon onClick={this.goBack.bind(this)} />
              <span>Choose travelers for your things to do</span>
            </div>
            <div className="cp-activities-main__col1__ttd-list">
              {this.state.activities.map((item, dayIndex) => {
                return item.activities.map((activityItem, activityIndex) => {
                  var totalPrice = 0;
                  if (activityItem !== "") {
                    if (activityItem.ticket_type.length > 0) {
                      activityItem.ticket_type.map((ticketItem) => {
                        if (ticketItem.quantity !== "") {
                          totalPrice +=
                            parseFloat(ticketItem.package_pricenettPrice) *
                            parseInt(ticketItem.quantity);
                        }
                      });
                    }
                    function formatDate(inputDate) {
                      const date = new Date(inputDate);
                      const year = date.getFullYear();
                      const month = (date.getMonth() + 1)
                        .toString()
                        .padStart(2, "0"); // Month is zero-based
                      const day = date.getDate().toString().padStart(2, "0");

                      return `${year}-${month}-${day}`;
                    }

                    // Adjust activity date dynamically for next-day flight
                    let adjustedDate = new Date(item.date);
                    let originalDate = new Date(
                      this.state.searchdata.start_date
                    );

                    // Normalize dates to midnight for date-only comparison
                    adjustedDate.setHours(0, 0, 0, 0);
                    originalDate.setHours(0, 0, 0, 0);

                    if (this.state.flightPrice?.onwayInfo?.length) {
                      let totalOnway = this.state.flightPrice.onwayInfo.length;
                      let arrivalDateTime = new Date(
                        this.state.flightPrice.onwayInfo[
                          totalOnway - 1
                        ].ArrivalDateTime
                      );

                      // Normalize arrival date to midnight
                      let flightArrivalDay = new Date(arrivalDateTime);
                      flightArrivalDay.setHours(0, 0, 0, 0);
                      /*  console.log(flightArrivalDay,"flightArrivalDay,",originalDate,"originalDate") */
                      // Compare only dates, not times
                      if (flightArrivalDay > originalDate) {
                        // Shift activity dates after flight arrival
                        adjustedDate.setDate(adjustedDate.getDate() + 1);
                      } else {
                        // Follow normal dates
                        adjustedDate = new Date(item.date); // Reset to original activity date
                      }
                    }

                    return (
                      <div
                        key={activityIndex}
                        className="cp-activities-main__col1__ttd-list__card"
                      >
                        <div className="cp-activities-main__col1__ttd-list__card__header">
                          <div className="cp-activities-main__col1__ttd-list__card__header__col1">
                            <div className="cp-activities-main__col1__ttd-list__card__header__col1__pName">
                              {activityItem.products_name}
                            </div>
                            <a
                              href={void 0}
                              onClick={this.productDetails.bind(
                                this,
                                activityItem
                              )}
                            >
                              <span>View more info</span>
                            </a>
                          </div>
                          <div className="cp-activities-main__col1__ttd-list__card__header__col2">
                            <div className="cp-activities-main__col1__ttd-list__card__header__col2__vDate">
                              {" "}
                              Visit: {formatDate(adjustedDate)}{" "}
                              <span>Day: {item.day}</span>
                            </div>
                            {activityItem.package_confirmation === "1" && (
                              <div className="aftr-req">
                                After confirmation required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="cp-activities-main__col1__ttd-list__card__content">
                          <div className="cp-activities-main__col1__ttd-list__card__content__col1">
                            {activityItem.activity_slot_list.length > 0 && (
                              <div className="tit-info">
                                <span>Select time slot</span>
                                <div className="vi-cover">
                                  {activityItem.activity_slot_list
                                    .slice(
                                      0,
                                      this.state.expandedItems.includes(
                                        activityItem.activity_activity_package_id
                                      )
                                        ? item.activities?.length
                                        : 6
                                    )
                                    .map((slotItem, slotIndex) => {
                                      return (
                                        <a
                                          key={slotIndex}
                                          href={void 0}
                                          className={
                                            activityItem.selected_slot !==
                                              null &&
                                            activityItem.selected_slot.length >
                                              0 &&
                                            activityItem.selected_slot[0]
                                              .slot_globaltix_id ===
                                              slotItem.slot_globaltix_id
                                              ? "active"
                                              : ""
                                          }
                                          onClick={this.selectTimeSlot.bind(
                                            this,
                                            dayIndex,
                                            activityIndex,
                                            slotItem
                                          )}
                                        >
                                          {slotItem.slot_time_format}
                                        </a>
                                      );
                                    })}
                                  {activityItem.activity_slot_list?.length >
                                    6 && (
                                    <a
                                      href={void 0}
                                      onClick={() =>
                                        this.handleExpand(activityItem)
                                      }
                                    >
                                      {this.state.expandedItems.includes(
                                        activityItem.activity_activity_package_id
                                      )
                                        ? "Show less"
                                        : "Show more"}
                                    </a>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className={`ttd-total-pack`}>
                              <div>
                                {totalPrice <= 0 ? (
                                  <>
                                    <strong>
                                      Starting Price{" "}
                                      {activityItem.product_starting_price}
                                    </strong>{" "}
                                  </>
                                ) : (
                                  <strong>
                                    Total{" "}
                                    {showPriceValue(
                                      totalPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                )}
                                <p>
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                  Please read the package information before
                                  booking.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="cp-activities-main__col1__ttd-list__card__content__col2">
                            {activityItem !== "" &&
                              activityItem.ticket_type.length > 0 && (
                                <>
                                  {activityItem.ticket_type.map(
                                    (ticketItem, ticketIndex) => {
                                      var checkingTicketType = "No";
                                      if (
                                        ticketItem.package_price_name ===
                                        "ADULT"
                                      ) {
                                        if (this.state.adultsQty > 0) {
                                          checkingTicketType = "Yes";
                                        }
                                      } else if (
                                        ticketItem.package_price_name ===
                                        "CHILD"
                                      ) {
                                        if (this.state.childQty > 0) {
                                          checkingTicketType = "Yes";
                                        }
                                      } else {
                                        var totalPQty =
                                          parseInt(this.state.adultsQty) +
                                          parseInt(this.state.childQty);
                                        if (totalPQty > 0) {
                                          checkingTicketType = "Yes";
                                        }
                                      }
                                      if (checkingTicketType === "Yes") {
                                        return (
                                          <div
                                            className={`cp-activities-main__col1__ttd-list__card__content__col2__row ${
                                              ticketIndex !==
                                                activityItem.ticket_type
                                                  ?.length -
                                                  1 && "margin-btm"
                                            }`}
                                            key={ticketIndex}
                                          >
                                            <div className="ad-ingo">
                                              <p>
                                                {ticketItem.package_price_name}{" "}
                                                {((ticketItem.package_price_maxPurchaseQty !==
                                                  "" &&
                                                  ticketItem.package_price_maxPurchaseQty !==
                                                    null) ||
                                                  (ticketItem.package_price_minPurchaseQty !==
                                                    "" &&
                                                    ticketItem.package_price_minPurchaseQty !==
                                                      null)) && (
                                                  <em>
                                                    {ticketItem.package_price_minPurchaseQty !==
                                                      "" &&
                                                      ticketItem.package_price_minPurchaseQty !==
                                                        null &&
                                                      "Min " +
                                                        ticketItem.package_price_minPurchaseQty}{" "}
                                                    -{" "}
                                                    {ticketItem.package_price_maxPurchaseQty !==
                                                      "" &&
                                                      ticketItem.package_price_maxPurchaseQty !==
                                                        null &&
                                                      "Max " +
                                                        ticketItem.package_price_maxPurchaseQty}
                                                  </em>
                                                )}
                                              </p>
                                            </div>
                                            <div className="ad-ingo-rhs">
                                              <div className="qty-bx">
                                                <span
                                                  className="qty-minus"
                                                  onClick={this.incdec.bind(
                                                    this,
                                                    ticketIndex,
                                                    activityIndex,
                                                    dayIndex,
                                                    "dec"
                                                  )}
                                                >
                                                  <i
                                                    className="fa fa-minus"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <input
                                                  type="text"
                                                  className="qty-input"
                                                  readOnly={true}
                                                  value={
                                                    ticketItem.quantity !== ""
                                                      ? ticketItem.quantity
                                                      : ""
                                                  }
                                                />
                                                <span
                                                  className="qty-plus"
                                                  onClick={this.incdec.bind(
                                                    this,
                                                    ticketIndex,
                                                    activityIndex,
                                                    dayIndex,
                                                    "inc"
                                                  )}
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </>
                              )}
                          </div>
                        </div>

                        {/* <div className="ttd-p-lhs">
                          <h4>{activityItem.products_name}</h4>
                          <div className="vist-info">
                            <ul>
                              <li className="vist-date">
                                Visit: {formatDate(adjustedDate)}
                              </li>
                              <li className="vist-date">Day: {item.day}</li>
                              {activityItem.package_confirmation === "1" && (
                                <li className="aftr-req">
                                  After confirmation required
                                </li>
                              )}
                              <li className="vw-more">
                                <a
                                  href={void 0}
                                  onClick={this.productDetails.bind(
                                    this,
                                    activityItem
                                  )}
                                >
                                  <span>View more info..</span>
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="tit-info">
                            {activityItem.activity_slot_list.length > 0 && (
                              <>
                                <span>Select time slot</span>
                                <div className="vi-cover">
                                  {activityItem.activity_slot_list.map(
                                    (slotItem, slotIndex) => {
                                      return (
                                        <a
                                          key={slotIndex}
                                          href={void 0}
                                          className={
                                            activityItem.selected_slot !==
                                              null &&
                                            activityItem.selected_slot.length >
                                              0 &&
                                            activityItem.selected_slot[0]
                                              .slot_globaltix_id ===
                                              slotItem.slot_globaltix_id
                                              ? "active"
                                              : ""
                                          }
                                          onClick={this.selectTimeSlot.bind(
                                            this,
                                            dayIndex,
                                            activityIndex,
                                            slotItem
                                          )}
                                        >
                                          {slotItem.slot_time_format}
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="ttd-total-pack">
                            {totalPrice <= 0 ? (
                              <>
                                <strong>
                                  Starting Price{" "}
                                  <span>
                                    {activityItem.product_starting_price}
                                  </span>
                                </strong>{" "}
                              </>
                            ) : (
                              <strong>
                                Total{" "}
                                <span>
                                  {showPriceValue(
                                    totalPrice,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </span>
                              </strong>
                            )}
                            <p>
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                              Please read the package information before
                              booking.
                            </p>
                          </div>
                        </div>
                        <div className="ttd-p-rhs">
                          {activityItem !== "" &&
                            activityItem.ticket_type.length > 0 && (
                              <ul>
                                {activityItem.ticket_type.map(
                                  (ticketItem, ticketIndex) => {
                                    var checkingTicketType = "No";
                                    if (
                                      ticketItem.package_price_name === "ADULT"
                                    ) {
                                      if (this.state.adultsQty > 0) {
                                        checkingTicketType = "Yes";
                                      }
                                    } else if (
                                      ticketItem.package_price_name === "CHILD"
                                    ) {
                                      if (this.state.childQty > 0) {
                                        checkingTicketType = "Yes";
                                      }
                                    } else {
                                      var totalPQty =
                                        parseInt(this.state.adultsQty) +
                                        parseInt(this.state.childQty);
                                      if (totalPQty > 0) {
                                        checkingTicketType = "Yes";
                                      }
                                    }
                                    if (checkingTicketType === "Yes") {
                                      return (
                                        <li key={ticketIndex}>
                                          <div className="ad-ingo">
                                            <p>
                                              {ticketItem.package_price_name}{" "}
                                              {((ticketItem.package_price_maxPurchaseQty !==
                                                "" &&
                                                ticketItem.package_price_maxPurchaseQty !==
                                                  null) ||
                                                (ticketItem.package_price_minPurchaseQty !==
                                                  "" &&
                                                  ticketItem.package_price_minPurchaseQty !==
                                                    null)) && (
                                                <em>
                                                  {ticketItem.package_price_minPurchaseQty !==
                                                    "" &&
                                                    ticketItem.package_price_minPurchaseQty !==
                                                      null &&
                                                    "Min " +
                                                      ticketItem.package_price_minPurchaseQty}{" "}
                                                  -{" "}
                                                  {ticketItem.package_price_maxPurchaseQty !==
                                                    "" &&
                                                    ticketItem.package_price_maxPurchaseQty !==
                                                      null &&
                                                    "Max " +
                                                      ticketItem.package_price_maxPurchaseQty}
                                                </em>
                                              )}
                                            </p>
                                          </div>
                                          <div className="ad-ingo-rhs">
                                            <div className="qty-bx">
                                              <span
                                                className="qty-minus"
                                                onClick={this.incdec.bind(
                                                  this,
                                                  ticketIndex,
                                                  activityIndex,
                                                  dayIndex,
                                                  "dec"
                                                )}
                                              >
                                                <i
                                                  className="fa fa-minus"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                              <input
                                                type="text"
                                                className="qty-input"
                                                readOnly={true}
                                                value={
                                                  ticketItem.quantity !== ""
                                                    ? ticketItem.quantity
                                                    : ""
                                                }
                                              />
                                              <span
                                                className="qty-plus"
                                                onClick={this.incdec.bind(
                                                  this,
                                                  ticketIndex,
                                                  activityIndex,
                                                  dayIndex,
                                                  "inc"
                                                )}
                                              >
                                                {" "}
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  }
                                )}
                              </ul>
                            )}
                        </div> */}
                      </div>
                    );
                  }
                });
              })}
            </div>
          </div>
          <div className="cp-activities-main__col2">
            <div className="cp-activities-main__col2__box">
              <div className="cp-activities-main__col2__box__title">
                Summary{" "}
              </div>
              <div className="cp-activities-main__col2__box__content">
                <div className="cp-activities-main__col2__box__content__row">
                  <div className="cp-activities-main__col2__box__content__row__col1">
                    <img src={ProfileIcon} alt="flight" />
                    <div className="cp-activities-main__col2__box__content__row__col1__label">
                      Persons
                    </div>
                  </div>
                  <div className="cp-activities-main__col2__box__content__row__col2">
                    <div className="cp-activities-main__col2__box__content__row__col2__val">
                      <span>
                        {this.state.searchdata.adults} Adult{" "}
                        {this.state.searchdata.child > 0 && (
                          <>{this.state.searchdata.child} Child</>
                        )}
                        {this.state.searchdata.infant > 0 && (
                          <> {this.state.searchdata.infant} Infant </>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cp-activities-main__col2__box__content__row">
                  <div className="cp-activities-main__col2__box__content__row__col1">
                    <img src={CalendarIcon} alt="flight" />
                    <div className="cp-activities-main__col2__box__content__row__col1__label">
                      Dates
                    </div>
                  </div>
                  <div className="cp-activities-main__col2__box__content__row__col2">
                    <div className="cp-activities-main__col2__box__content__row__col2__val">
                      <span>
                        {" "}
                        {new Date(
                          this.state.searchdata.start_date
                        ).toLocaleDateString("en-IN", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}{" "}
                        to{" "}
                        {new Date(this.state.end_date).toLocaleDateString(
                          "en-IN",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cp-activities-main__col2__box__content__row">
                  <div className="cp-activities-main__col2__box__content__row__col1">
                    <img src={MoonIcon} alt="flight" />
                    <div className="cp-activities-main__col2__box__content__row__col1__label">
                      Days
                    </div>
                  </div>
                  <div className="cp-activities-main__col2__box__content__row__col2">
                    <div className="cp-activities-main__col2__box__content__row__col2__val">
                      <span>{this.state.activities.length} Day Trip</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cp-activities-main__col2__box__divider"></div>
              <div className="cp-activities-main__col2__box__title">
                Itineraries Total
              </div>
              <div className="cp-activities-main__col2__box__content">
                {this.state.totalFlightPrice > 0 &&
                  this.state.addFlight === "Yes" && (
                    <div className="cp-activities-main__col2__box__content__row">
                      <div className="cp-activities-main__col2__box__content__row__col1">
                        <img src={FlightIcon} alt="flight" />
                        <div className="cp-activities-main__col2__box__content__row__col1__label">
                          Onward Flight fee
                        </div>
                      </div>
                      <div className="cp-activities-main__col2__box__content__row__col2">
                        <div className="cp-activities-main__col2__box__content__row__col2__val">
                          {showPriceValue(
                            this.state.totalFlightPrice,
                            this.state.currentCurrency,
                            this.state.currencyRate
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.totalFlightPriceReturn > 0 &&
                  this.state.addFlightReturn === "Yes" && (
                    <div className="cp-activities-main__col2__box__content__row">
                      <div className="cp-activities-main__col2__box__content__row__col1">
                        <img src={FlightIcon} alt="flight" />
                        <div className="cp-activities-main__col2__box__content__row__col1__label">
                          Return Flight fee
                        </div>
                      </div>
                      <div className="cp-activities-main__col2__box__content__row__col2">
                        <div className="cp-activities-main__col2__box__content__row__col2__val">
                          {showPriceValue(
                            this.state.totalFlightPriceReturn,
                            this.state.currentCurrency,
                            this.state.currencyRate
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.totalHotelPrice > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={BedIcon} alt="flight" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label">
                        Hotel fee
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__val">
                        {showPriceValue(
                          this.state.totalHotelPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.transferPrice > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={CarIcon} alt="car" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label">
                        Onwards Transfer fee
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__val">
                        {showPriceValue(
                          this.state.transferPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.transferPriceReturn > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={CarIcon} alt="car" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label">
                        Return Transfer fee
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__val">
                        {showPriceValue(
                          this.state.transferPriceReturn,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.totalActvitiesPrice > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={TicketIcon} alt="ticket" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label">
                        Things to do fee
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__val">
                        {showPriceValue(
                          this.state.totalActvitiesPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.state.packageDetails?.creator_package_source === "uop" &&
                  this.state.uopPricing !== "" && (
                    <>
                      {this.state.uopPricing?.ticketDetails &&
                        this.state?.uopPricing?.ticketDetails?.map(
                          (item, index) => {
                            if (item?.selected === true) {
                              return (
                                <div
                                  className="cp-activities-main__col2__box__content__row"
                                  key={index}
                                >
                                  <div className="cp-activities-main__col2__box__content__row__col1">
                                    <div className="cp-activities-main__col2__box__content__row__col1__label">
                                      {item.ticketType}{" "}
                                    </div>
                                  </div>
                                  <div className="cp-activities-main__col2__box__content__row__col2">
                                    <div className="cp-activities-main__col2__box__content__row__col2__val">
                                      {showPriceValue(
                                        item.totalPrice,
                                        this.state.currentCurrency,
                                        this.state.currencyRate
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          }
                        )}

                      {this.state.uopPricing?.addOns &&
                        this.state.uopPricing?.addOns.map((item, index) => {
                          if (item?.selected === true) {
                            var price =
                              this.state.selectedseason === "peak"
                                ? item.peakPrice
                                : item.nonPeakPrice;

                            return (
                              <div
                                className="cp-activities-main__col2__box__content__row"
                                key={index}
                              >
                                <div className="cp-activities-main__col2__box__content__row__col1">
                                  <div className="cp-activities-main__col2__box__content__row__col1__label">
                                    {item.title}
                                  </div>
                                </div>
                                <div className="cp-activities-main__col2__box__content__row__col2">
                                  <div className="cp-activities-main__col2__box__content__row__col2__val">
                                    {showPriceValue(
                                      price,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}

                      {this.state.uopPricing?.type === "standard" && (
                        <div className="cp-activities-main__col2__box__content__row">
                          <div className="cp-activities-main__col2__box__content__row__col1">
                            <div className="cp-activities-main__col2__box__content__row__col1__label">
                              Pacakge Fee
                            </div>
                          </div>
                          <div className="cp-activities-main__col2__box__content__row__col2">
                            <div className="cp-activities-main__col2__box__content__row__col2__val">
                              {showPriceValue(
                                this.state.packageFee,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {parseFloat(this.state.platformfee) > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={GlobalIcon} alt="global" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label">
                        Platform fee
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__val">
                        {showPriceValue(
                          this.state.platformfee,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="cp-activities-main__col2__box__divider"></div>
              <div className="cp-activities-main__col2__box__content">
                {discountDetails !== "" && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <img src={DiscountIcon} alt="discount" />
                      <div className="cp-activities-main__col2__box__content__row__col1__label2">
                        {discountDetails.title}
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__discount">
                        -
                        {showPriceValue(
                          discountDetails.amount,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(this.state.grandTotal) > 0 && (
                  <div className="cp-activities-main__col2__box__content__row">
                    <div className="cp-activities-main__col2__box__content__row__col1">
                      <div className="cp-activities-main__col2__box__content__row__col1__label1">
                        Total
                      </div>
                    </div>
                    <div className="cp-activities-main__col2__box__content__row__col2">
                      <div className="cp-activities-main__col2__box__content__row__col2__price">
                        {showPriceValue(
                          this.state.grandTotal,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                className="cp-activities-main__col2__box__content__checkout-btn"
                onClick={() => this.continuebooknow()}
              >
                Checkout here
              </Button>
            </div>
            <AssistanceCard
              handleAssistancePopup={this.handleAssistancePopup.bind(this)}
            />
          </div>
        </div>
        <ModalPopup
          open={this.state.showAssistanceCard}
          handleClose={() => this.setState({ showAssistanceCard: false })}
        >
          <AssistanceCard
            handleAssistancePopup={this.handleAssistancePopup.bind(this)}
          />
        </ModalPopup>
        <AssistancePopup
          showAssistancePopup={this.state.showAssistancePopup}
          handleClose={() => this.setState({ showAssistancePopup: "" })}
          isMobile={this.state.isMobile}
          searchdata={this.state.searchdata}
          packageSlug={this.state.packageSlug}
          creatorPackageID={this.state.packageDetails.creator_package_id}
        />
        {/* <section className="innersection">
          <div className="container">
            <div className="detail-bottom">
              <div className="detail-bottom-inner">
                <div className="detail-bottom-lhs">
                  <div className="arrow-back-title">
                    {this.state.isMobile ? (
                      <ArrowBackIosIcon onClick={this.goBack.bind(this)} />
                    ) : (
                      <a
                        href={void 0}
                        onClick={this.goBack.bind(this)}
                        className="ab-back-icon"
                      >
                        <i
                          className="fa fa-long-arrow-left"
                          aria-hidden="true"
                        ></i>
                      </a>
                    )}
                    <h2>Choose travelers for your things to do</h2>
                  </div>
                  <div className="ttd-package-card">
                    {this.state.totalActivity > 0 &&
                      (this.state.isMobile ? (
                        <ul>
                          {this.state.activities.map((item, dayIndex) => {
                            return item.activities.map(
                              (activityItem, activityIndex) => {
                                var totalPrice = 0;
                                if (activityItem !== "") {
                                  if (activityItem.ticket_type.length > 0) {
                                    activityItem.ticket_type.map(
                                      (ticketItem) => {
                                        if (ticketItem.quantity !== "") {
                                          totalPrice +=
                                            parseFloat(
                                              ticketItem.package_pricenettPrice
                                            ) * parseInt(ticketItem.quantity);
                                        }
                                      }
                                    );
                                  }
                                  function formatDate(inputDate) {
                                    const date = new Date(inputDate);
                                    const year = date.getFullYear();
                                    const month = (date.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0"); // Month is zero-based
                                    const day = date
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");

                                    return `${year}-${month}-${day}`;
                                  }
                                  // Adjust activity date dynamically for next-day flight
                                  let adjustedDate = new Date(item.date);
                                  let originalDate = new Date(
                                    this.state.searchdata.start_date
                                  );

                                  if (
                                    this.state.flightPrice &&
                                    this.state.flightPrice.onwayInfo
                                  ) {
                                    let totalOnway =
                                      this.state.flightPrice.onwayInfo.length;
                                    let arrivalDateTime = new Date(
                                      this.state.flightPrice.onwayInfo[
                                        totalOnway - 1
                                      ].ArrivalDateTime
                                    );

                                    let flightArrivalDay = arrivalDateTime;
                                    let tourStartDay = originalDate;
                                    if (flightArrivalDay > tourStartDay) {
                                      // Shift activity dates after flight arrival
                                      adjustedDate = add(adjustedDate, {
                                        days: 1,
                                      });
                                    }
                                  }

                                  return (
                                    <li
                                      key={activityIndex}
                                      className="things-to-do-error"
                                    >
                                      <div className="ttd-p-lhs">
                                        <div className="row">
                                          <div className="col1">
                                            <h4>
                                              {activityItem.products_name}
                                            </h4>
                                          </div>
                                          <div className="col2">
                                            <a
                                              href={void 0}
                                              onClick={this.productDetails.bind(
                                                this,
                                                activityItem
                                              )}
                                            >
                                              <span>Details</span>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="vist-info">
                                          <ul>
                                            <li className="vist-date">
                                              Visit: {formatDate(adjustedDate)}
                                            </li>
                                            <li className="vist-date">
                                              Day: {item.day}
                                            </li>
                                            {activityItem.package_confirmation ===
                                              "1" && (
                                              <li className="aftr-req">
                                                After confirmation required
                                              </li>
                                            )}
                                          </ul>
                                        </div>

                                        <div className="tit-info">
                                          {activityItem.activity_slot_list
                                            .length > 0 && (
                                            <>
                                              <span>Select time slot</span>
                                              <div className="vi-cover">
                                                {activityItem.activity_slot_list.map(
                                                  (slotItem, slotIndex) => {
                                                    return (
                                                      <a
                                                        key={slotIndex}
                                                        href={void 0}
                                                        className={
                                                          activityItem.selected_slot !==
                                                            null &&
                                                          activityItem
                                                            .selected_slot
                                                            .length > 0 &&
                                                          activityItem
                                                            .selected_slot[0]
                                                            .slot_globaltix_id ===
                                                            slotItem.slot_globaltix_id
                                                            ? "active"
                                                            : ""
                                                        }
                                                        onClick={this.selectTimeSlot.bind(
                                                          this,
                                                          dayIndex,
                                                          activityIndex,
                                                          slotItem
                                                        )}
                                                      >
                                                        {
                                                          slotItem.slot_time_format
                                                        }
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </div>

                                        <div className="ttd-total-pack">
                                          {totalPrice <= 0 ? (
                                            <>
                                              <strong>
                                                Starting Price{" "}
                                                <span>
                                                  {
                                                    activityItem.product_starting_price
                                                  }
                                                </span>
                                              </strong>{" "}
                                            </>
                                          ) : (
                                            <strong>
                                              Total{" "}
                                              <span>
                                                {showPriceValue(
                                                  totalPrice,
                                                  this.state.currentCurrency,
                                                  this.state.currencyRate
                                                )}
                                              </span>
                                            </strong>
                                          )}
                                          <p>
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                            Please read the package information
                                            before booking.
                                          </p>
                                        </div>
                                        <div className="ttd-action-btn">
                                          <a
                                            href={void 0}
                                            onClick={this.handleSelectInfo.bind(
                                              this,
                                              activityItem,
                                              activityIndex,
                                              dayIndex
                                            )}
                                            className="button packageinfo"
                                          >
                                            View & Select
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              }
                            );
                          })}
                        </ul>
                      ) : (
                        <ul>
                          {this.state.activities.map((item, dayIndex) => {
                            return item.activities.map(
                              (activityItem, activityIndex) => {
                                var totalPrice = 0;
                                if (activityItem !== "") {
                                  if (activityItem.ticket_type.length > 0) {
                                    activityItem.ticket_type.map(
                                      (ticketItem) => {
                                        if (ticketItem.quantity !== "") {
                                          totalPrice +=
                                            parseFloat(
                                              ticketItem.package_pricenettPrice
                                            ) * parseInt(ticketItem.quantity);
                                        }
                                      }
                                    );
                                  }
                                  function formatDate(inputDate) {
                                    const date = new Date(inputDate);
                                    const year = date.getFullYear();
                                    const month = (date.getMonth() + 1)
                                      .toString()
                                      .padStart(2, "0"); // Month is zero-based
                                    const day = date
                                      .getDate()
                                      .toString()
                                      .padStart(2, "0");

                                    return `${year}-${month}-${day}`;
                                  }
                                  // Adjust activity date dynamically for next-day flight
                                  let adjustedDate = new Date(item.date);
                                  let originalDate = new Date(
                                    this.state.searchdata.start_date
                                  );

                                  if (
                                    this.state.flightPrice &&
                                    this.state.flightPrice.onwayInfo
                                  ) {
                                    let totalOnway =
                                      this.state.flightPrice.onwayInfo.length;
                                    let arrivalDateTime = new Date(
                                      this.state.flightPrice.onwayInfo[
                                        totalOnway - 1
                                      ].ArrivalDateTime
                                    );

                                    let flightArrivalDay = arrivalDateTime;
                                    let tourStartDay = originalDate;
                                    if (flightArrivalDay > tourStartDay) {
                                      // Shift activity dates after flight arrival
                                      adjustedDate = add(adjustedDate, {
                                        days: 1,
                                      });
                                    }
                                  }
                                  return (
                                    <li
                                      key={activityIndex}
                                      className="things-to-do-error"
                                    >
                                      <div className="ttd-p-lhs">
                                        <h4>{activityItem.products_name}</h4>
                                        <div className="vist-info">
                                          <ul>
                                            <li className="vist-date">
                                              Visit: {formatDate(adjustedDate)}
                                            </li>
                                            <li className="vist-date">
                                              Day: {item.day}
                                            </li>
                                            {activityItem.package_confirmation ===
                                              "1" && (
                                              <li className="aftr-req">
                                                After confirmation required
                                              </li>
                                            )}
                                            <li className="vw-more">
                                              <a
                                                href={void 0}
                                                onClick={this.productDetails.bind(
                                                  this,
                                                  activityItem
                                                )}
                                              >
                                                <span>View more info..</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="tit-info">
                                          {activityItem.activity_slot_list
                                            .length > 0 && (
                                            <>
                                              <span>Select time slot</span>
                                              <div className="vi-cover">
                                                {activityItem.activity_slot_list.map(
                                                  (slotItem, slotIndex) => {
                                                    return (
                                                      <a
                                                        key={slotIndex}
                                                        href={void 0}
                                                        className={
                                                          activityItem.selected_slot !==
                                                            null &&
                                                          activityItem
                                                            .selected_slot
                                                            .length > 0 &&
                                                          activityItem
                                                            .selected_slot[0]
                                                            .slot_globaltix_id ===
                                                            slotItem.slot_globaltix_id
                                                            ? "active"
                                                            : ""
                                                        }
                                                        onClick={this.selectTimeSlot.bind(
                                                          this,
                                                          dayIndex,
                                                          activityIndex,
                                                          slotItem
                                                        )}
                                                      >
                                                        {
                                                          slotItem.slot_time_format
                                                        }
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </div>

                                        <div className="ttd-total-pack">
                                          {totalPrice <= 0 ? (
                                            <>
                                              <strong>
                                                Starting Price{" "}
                                                <span>
                                                  {
                                                    activityItem.product_starting_price
                                                  }
                                                </span>
                                              </strong>{" "}
                                            </>
                                          ) : (
                                            <strong>
                                              Total{" "}
                                              <span>
                                                {showPriceValue(
                                                  totalPrice,
                                                  this.state.currentCurrency,
                                                  this.state.currencyRate
                                                )}
                                              </span>
                                            </strong>
                                          )}
                                          <p>
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                            Please read the package information
                                            before booking.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="ttd-p-rhs">
                                        {activityItem !== "" &&
                                          activityItem.ticket_type.length >
                                            0 && (
                                            <ul>
                                              {activityItem.ticket_type.map(
                                                (ticketItem, ticketIndex) => {
                                                  var checkingTicketType = "No";
                                                  if (
                                                    ticketItem.package_price_name ===
                                                    "ADULT"
                                                  ) {
                                                    if (
                                                      this.state.adultsQty > 0
                                                    ) {
                                                      checkingTicketType =
                                                        "Yes";
                                                    }
                                                  } else if (
                                                    ticketItem.package_price_name ===
                                                    "CHILD"
                                                  ) {
                                                    if (
                                                      this.state.childQty > 0
                                                    ) {
                                                      checkingTicketType =
                                                        "Yes";
                                                    }
                                                  } else {
                                                    var totalPQty =
                                                      parseInt(
                                                        this.state.adultsQty
                                                      ) +
                                                      parseInt(
                                                        this.state.childQty
                                                      );
                                                    if (totalPQty > 0) {
                                                      checkingTicketType =
                                                        "Yes";
                                                    }
                                                  }
                                                  if (
                                                    checkingTicketType === "Yes"
                                                  ) {
                                                    return (
                                                      <li key={ticketIndex}>
                                                        <div className="ad-ingo">
                                                          <p>
                                                            {
                                                              ticketItem.package_price_name
                                                            }{" "}
                                                            {((ticketItem.package_price_maxPurchaseQty !==
                                                              "" &&
                                                              ticketItem.package_price_maxPurchaseQty !==
                                                                null) ||
                                                              (ticketItem.package_price_minPurchaseQty !==
                                                                "" &&
                                                                ticketItem.package_price_minPurchaseQty !==
                                                                  null)) && (
                                                              <em>
                                                                {ticketItem.package_price_minPurchaseQty !==
                                                                  "" &&
                                                                  ticketItem.package_price_minPurchaseQty !==
                                                                    null &&
                                                                  "Min " +
                                                                    ticketItem.package_price_minPurchaseQty}{" "}
                                                                -{" "}
                                                                {ticketItem.package_price_maxPurchaseQty !==
                                                                  "" &&
                                                                  ticketItem.package_price_maxPurchaseQty !==
                                                                    null &&
                                                                  "Max " +
                                                                    ticketItem.package_price_maxPurchaseQty}
                                                              </em>
                                                            )}
                                                          </p>
                                                        </div>
                                                        <div className="ad-ingo-rhs">
                                                          <div className="qty-bx">
                                                            <span
                                                              className="qty-minus"
                                                              onClick={this.incdec.bind(
                                                                this,
                                                                ticketIndex,
                                                                activityIndex,
                                                                dayIndex,
                                                                "dec"
                                                              )}
                                                            >
                                                              <i
                                                                className="fa fa-minus"
                                                                aria-hidden="true"
                                                              ></i>
                                                            </span>
                                                            <input
                                                              type="text"
                                                              className="qty-input"
                                                              readOnly={true}
                                                              value={
                                                                ticketItem.quantity !==
                                                                ""
                                                                  ? ticketItem.quantity
                                                                  : ""
                                                              }
                                                            />
                                                            <span
                                                              className="qty-plus"
                                                              onClick={this.incdec.bind(
                                                                this,
                                                                ticketIndex,
                                                                activityIndex,
                                                                dayIndex,
                                                                "inc"
                                                              )}
                                                            >
                                                              {" "}
                                                              <i
                                                                className="fa fa-plus"
                                                                aria-hidden="true"
                                                              ></i>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          )}
                                      </div>
                                    </li>
                                  );
                                }
                              }
                            );
                          })}
                        </ul>
                      ))}
                  </div>
                </div>
                {!this.state.isMobile && (
                  <div className="package-details-rhs">
                    {this.state.packageSlug !== "create-own-package" && (
                      <div className="sumry-box-top">
                        <div className="mini-header">
                          <h5>Summary</h5>
                        </div>
                        <div className="mini-box-body">
                          <span>
                            {this.state.searchdata.adults} Adult{" "}
                            {this.state.searchdata.child > 0 && (
                              <>{this.state.searchdata.child} Child</>
                            )}
                            {this.state.searchdata.infant > 0 && (
                              <> {this.state.searchdata.infant} Infant </>
                            )}
                            | {this.state.activities.length} Day Trip
                          </span>
                          <span>
                            {" "}
                            {new Date(
                              this.state.searchdata.start_date
                            ).toLocaleDateString("en-IN", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}{" "}
                            to{" "}
                            {new Date(this.state.end_date).toLocaleDateString(
                              "en-IN",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    {this.tripDetails()}
                    {this.state.ownpackage === false && (
                      <div className="pack-creator-box">
                        <div className="pcreator-img">
                          <img
                            src={
                              this.state.packageDetails.creator_image !== "" &&
                              this.state.packageDetails.creator_image !== null
                                ? this.state.packageDetails.creator_image
                                : actinfo1
                            }
                          />
                        </div>
                        <div className="pcreator-def">
                          <h5>
                            {this.state.packagedetails
                              ?.creator_package_source === "uop"
                              ? "Operated by"
                              : "Created by"}
                            <strong>
                              <a
                                href={void 0}
                                onClick={() => {
                                  var cid =
                                    this.state.packageDetails.creator_id;
                                  this.props.history.push(
                                    "/public_profile",
                                    cid
                                  );
                                }}
                              >
                                {" "}
                                {this.state.packageDetails.creator_handle_name}
                              </a>
                            </strong>{" "}
                          </h5>

                          <div className="star-ratin-creator">
                            <div className="star-rating">
                              {this.loadRatingstar(
                                this.state.packageDetails.creator_rating
                              )}
                            </div>
                            {this.state.packageDetails.creator_rating !== "" &&
                              this.state.packageDetails.creator_rating !==
                                null && (
                                <span>
                                  <strong>
                                    {this.state.packageDetails.creator_rating}
                                  </strong>{" "}
                                  (
                                  {
                                    this.state.packageDetails
                                      .creator_total_rating
                                  }
                                  )
                                </span>
                              )}
                          </div>
                          <div className="main-creator"> Level 1 creator</div>
                        </div>
                      </div>
                    )}
                    {this.state.addFlight ||
                    this.state.addTransfer ||
                    this.state.selectedRoom !== "" ||
                    this.state.activities.some(
                      (day) => day.activities.length > 0
                    ) ? (
                      <>
                        <div className="detail-bottom-rhs-price rhs-white-box">
                          <h5>Price details</h5>
                          <ul>
                            {this.state.totalFlightPrice > 0 &&
                              this.state.addFlight === "Yes" && (
                                <li>
                                  <span className="service-content">
                                    <strong>Onward Flight fee</strong>
                                  </span>
                                  <span className="service-amount">
                                    <strong>
                                      {showPriceValue(
                                        this.state.totalFlightPrice,
                                        this.state.currentCurrency,
                                        this.state.currencyRate
                                      )}
                                    </strong>
                                  </span>
                                </li>
                              )}
                            {this.state.totalFlightPriceReturn > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Return Flight fee</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.totalFlightPriceReturn,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                              </li>
                            )}
                            {this.state.totalHotelPrice > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Hotel fee</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.totalHotelPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                              </li>
                            )}
                            {this.state.transferPrice > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Onwards Transfer fee</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.transferPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                              </li>
                            )}
                            {this.state.transferPriceReturn > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Return Transfer fee</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.transferPriceReturn,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                              </li>
                            )}
                            {this.state.totalActivity > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Things to do fee</strong>
                                </span>
                                {this.state.totalActvitiesPrice > 0 && (
                                  <span className="service-amount">
                                    <strong>
                                      {showPriceValue(
                                        this.state.totalActvitiesPrice,
                                        this.state.currentCurrency,
                                        this.state.currencyRate
                                      )}
                                    </strong>
                                  </span>
                                )}
                              </li>
                            )}
                            {parseFloat(this.state.platformfee) > 0 && (
                              <ul className="sub-taxes">
                                <li>
                                  <span className="service-content">
                                    Platform fee
                                  </span>
                                  <span className="service-amount">
                                    {showPriceValue(
                                      this.state.platformfee,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </span>
                                </li>
                              </ul>
                            )}
                            {discountDetails !== "" && (
                              <ul className="sub-taxes">
                                <li>
                                  <span className="service-content">
                                    {discountDetails.title}
                                  </span>
                                  <span className="service-amount">
                                    -
                                    {showPriceValue(
                                      discountDetails.amount,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </span>
                                </li>
                              </ul>
                            )}

                            {parseFloat(this.state.grandTotal) > 0 && (
                              <li>
                                <span className="service-content">Total</span>
                                <span className="service-amount">
                                  {showPriceValue(
                                    this.state.grandTotal,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="booknow-btm">
                          <a
                            className="button"
                            href={void 0}
                            onClick={this.continuebooknow.bind(this)}
                          >
                            Continue
                          </a>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section> */}
        {this.state.pouppackage !== "" && (
          <div
            id="pacakge_popup"
            className="mfp-hide common-popup pacakge_popup"
          >
            <div className="cp-header textcenter">
              <h3>
                Package <span>information</span>
              </h3>
              {/* <a
                className="date-package-popup"
                href={void 0}
                onClick={this.closePopup.bind(this)}
              >
                <img src={closeicon} />
              </a> */}
            </div>

            <div className="pp-info">
              <div className="pp-info-blue">
                <ul>
                  <li>
                    <img src={calday} alt="calday" />
                    {this.state.pouppackage.package_ticketValidity ===
                    "Duration"
                      ? " Open dated valid upto " +
                        this.state.pouppackage.package_definedDuration +
                        " days"
                      : "Fixed Date"}
                  </li>
                  <li>
                    <img src={dollar} alt="dollar" />

                    {this.state.pouppackage.package_ticketFormat === "QRCODE"
                      ? "Cancellable and refundable"
                      : "Non Cancellable and non refundable"}
                  </li>
                  {this.state.pouppackage.product_instantconfirmation ===
                    "1" && (
                    <li>
                      <img src={instant_confirmation} alt="confirmation" />
                      Instant confirmation
                    </li>
                  )}
                </ul>
              </div>
              <div className="pp-info-text">
                <h5>
                  Package <span>title</span>
                </h5>
                <p>{this.state.pouppackage.package_name}</p>
                {this.state.pouppackage.package_description !== "" &&
                  this.state.pouppackage.package_description !== null &&
                  typeof this.state.pouppackage.package_description !==
                    undefined &&
                  typeof this.state.pouppackage.package_description !==
                    "undefined" && (
                    <>
                      <h5>
                        Package <span>Description</span>
                      </h5>
                      {Parser(this.state.pouppackage.package_description)}
                    </>
                  )}

                {this.state.pouppackage.package_inclusions !== "" &&
                  this.state.pouppackage.package_inclusions !== null &&
                  typeof this.state.pouppackage.package_inclusions !==
                    undefined &&
                  typeof this.state.pouppackage.package_inclusions !==
                    "undefined" && (
                    <>
                      <h5>
                        What's <span>Included</span>
                      </h5>

                      <ul>
                        {this.state.pouppackage.package_inclusions
                          .split("#$")
                          .map((item, index3) => {
                            return <li key={index3}>{item}</li>;
                          })}
                      </ul>
                    </>
                  )}
                {this.state.pouppackage.package_howtouse !== "" &&
                  this.state.pouppackage.package_howtouse !== null &&
                  typeof this.state.pouppackage.package_howtouse !==
                    undefined &&
                  typeof this.state.pouppackage.package_howtouse !==
                    "undefined" && (
                    <>
                      <h5>
                        How to <span>Use</span>
                      </h5>
                      <ul>
                        {this.state.pouppackage.package_howtouse
                          .split("#$")
                          .map((item, index3) => {
                            return <li key={index3}>{item}</li>;
                          })}
                      </ul>
                    </>
                  )}
                {this.state.pouppackage.package_cancellationNotes !== "" &&
                  this.state.pouppackage.package_cancellationNotes !== null &&
                  typeof this.state.pouppackage.package_cancellationNotes !==
                    undefined &&
                  typeof this.state.pouppackage.package_cancellationNotes !==
                    "undefined" && (
                    <>
                      <h5>
                        Cancellation <span>Details</span>
                      </h5>
                      <ul>
                        {this.state.pouppackage.package_cancellationNotes
                          .split("#$")
                          .map((item, index2) => {
                            if (item !== "" && item !== null) {
                              return <li key={index2}>{Parser(item)}</li>;
                            }
                          })}
                      </ul>
                    </>
                  )}
                {this.state.pouppackage.package_termsAndConditions !== "" &&
                  this.state.pouppackage.package_termsAndConditions !== null &&
                  typeof this.state.pouppackage.package_termsAndConditions !==
                    undefined &&
                  typeof this.state.pouppackage.package_termsAndConditions !==
                    "undefined" && (
                    <>
                      <h5>
                        Terms & <span>Conditions</span>
                      </h5>

                      {Parser(
                        this.state.pouppackage.package_termsAndConditions
                      )}
                    </>
                  )}
                {this.state.pouppackage.products_addressLine !== "" &&
                  this.state.pouppackage.products_addressLine !== null && (
                    <>
                      <h5>Location</h5>
                      <ul>
                        <li>
                          {this.state.pouppackage.products_addressLine}-{" "}
                          {this.state.pouppackage.products_postalCode}
                        </li>
                      </ul>
                    </>
                  )}
                {this.state.pouppackage.product_operatingHours !== "" &&
                  this.state.pouppackage.product_operatingHours !== null &&
                  typeof this.state.pouppackage.product_operatingHours !==
                    undefined &&
                  typeof this.state.pouppackage.product_operatingHours !==
                    "undefined" && (
                    <>
                      <h5>
                        Opening <span>hours</span>
                      </h5>
                      <ul>
                        <li>
                          {Parser(
                            this.state.pouppackage.product_operatingHours
                          )}
                        </li>
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </div>
        )}

        <BottomSheet
          open={this.state.showSelectInfo}
          blocking={false}
          onDismiss={() => this.setState({ showSelectInfo: false })}
          className="searchContainer__bottomSheetMain"
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="ttd-p-lhs">
              <div className="row">
                <div className="col1">
                  <h4>{this.state.activityItem.products_name}</h4>
                </div>
                <div className="col2">
                  <a
                    href={void 0}
                    onClick={this.productDetails.bind(
                      this,
                      this.state.activityItem
                    )}
                  >
                    <span>Details</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="pp-info">
              <div className="pp-info-blue">
                <ul>
                  <li>
                    <img src={calday} alt="calday" />
                    {this.state.pouppackage.package_ticketValidity ===
                    "Duration"
                      ? " Open dated valid upto " +
                        this.state.pouppackage.package_definedDuration +
                        " days"
                      : "Fixed Date"}
                  </li>
                  <li>
                    <img src={dollar} alt="dollar" />

                    {this.state.pouppackage.package_ticketFormat === "QRCODE"
                      ? "Cancellable and refundable"
                      : "Non Cancellable and non refundable"}
                  </li>
                  {this.state.pouppackage.product_instantconfirmation ===
                    "1" && (
                    <li>
                      <img src={instant_confirmation} alt="confirmation" />
                      Instant confirmation
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="ttd-p-rhs">
              {this.state.activityItem?.ticket_type?.length > 0 && (
                <ul>
                  {this.state.activityItem?.ticket_type.map(
                    (ticketItem, ticketIndex) => {
                      var checkingTicketType = "No";
                      if (ticketItem.package_price_name === "ADULT") {
                        if (this.state.adultsQty > 0) {
                          checkingTicketType = "Yes";
                        }
                      } else if (ticketItem.package_price_name === "CHILD") {
                        if (this.state.childQty > 0) {
                          checkingTicketType = "Yes";
                        }
                      } else {
                        var totalPQty =
                          parseInt(this.state.adultsQty) +
                          parseInt(this.state.childQty);
                        if (totalPQty > 0) {
                          checkingTicketType = "Yes";
                        }
                      }
                      if (checkingTicketType === "Yes") {
                        return (
                          <li key={ticketIndex}>
                            <div className="ad-ingo">
                              <p>
                                {ticketItem.package_price_name}{" "}
                                {((ticketItem.package_price_maxPurchaseQty !==
                                  "" &&
                                  ticketItem.package_price_maxPurchaseQty !==
                                    null) ||
                                  (ticketItem.package_price_minPurchaseQty !==
                                    "" &&
                                    ticketItem.package_price_minPurchaseQty !==
                                      null)) && (
                                  <em>
                                    {ticketItem.package_price_maxPurchaseQty !==
                                      "" &&
                                      ticketItem.package_price_maxPurchaseQty !==
                                        null &&
                                      "Min " +
                                        ticketItem.package_price_maxPurchaseQty}{" "}
                                    -{" "}
                                    {ticketItem.package_price_minPurchaseQty !==
                                      "" &&
                                      ticketItem.package_price_minPurchaseQty !==
                                        null &&
                                      "Max " +
                                        ticketItem.package_price_minPurchaseQty}
                                  </em>
                                )}
                              </p>
                            </div>
                            <div className="ad-ingo-rhs">
                              <div className="qty-bx">
                                <span
                                  className="qty-minus"
                                  onClick={this.incdec.bind(
                                    this,
                                    ticketIndex,
                                    this.state.activityIndex,
                                    this.state.dayIndex,
                                    "dec"
                                  )}
                                >
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <input
                                  type="text"
                                  className="qty-input"
                                  readOnly={true}
                                  value={
                                    ticketItem.quantity !== ""
                                      ? ticketItem.quantity
                                      : ""
                                  }
                                />
                                <span
                                  className="qty-plus"
                                  onClick={this.incdec.bind(
                                    this,
                                    ticketIndex,
                                    this.state.activityIndex,
                                    this.state.dayIndex,
                                    "inc"
                                  )}
                                >
                                  {" "}
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              )}
            </div>
            {this.state.activityItem?.activity_slot_list?.length > 0 && (
              <div className="tit-info">
                <span>Select time slot</span>
                <div className="vi-cover">
                  {this.state.activityItem?.activity_slot_list?.map(
                    (slotItem, slotIndex) => {
                      return (
                        <a
                          key={slotIndex}
                          href={void 0}
                          className={
                            this.state.activityItem.selected_slot !== null &&
                            this.state.activityItem.selected_slot.length > 0 &&
                            this.state.activityItem.selected_slot[0]
                              .slot_globaltix_id === slotItem.slot_globaltix_id
                              ? "active"
                              : ""
                          }
                          onClick={this.selectTimeSlot.bind(
                            this,
                            this.state.dayIndex,
                            this.state.activityIndex,
                            slotItem
                          )}
                        >
                          {slotItem.slot_time_format}
                        </a>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            <div className="selectBtn">
              <a
                href={void 0}
                onClick={() => this.setState({ showSelectInfo: false })}
                className="button packageinfo"
              >
                Select
              </a>
            </div>
          </div>
        </BottomSheet>
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row asst-main">
                <div
                  className="asst-cntnr"
                  onClick={() => this.setState({ showAssistanceCard: true })}
                >
                  <div className="asst-row">
                    <AvatarGroup>
                      <Avatar
                        alt="Gowtham"
                        src="https://images.fizota.com/Gowtham.webp"
                      />
                      <Avatar
                        alt="Bose"
                        src="https://images.fizota.com/Bose.webp"
                      />
                    </AvatarGroup>
                    <span>Chat, Callback and Free! 1 on 1 Support</span>
                    <img src={AssetChevronRight} alt="AssetChevronRight" />
                  </div>
                </div>
                <div className="col-1">
                  <div className="title">
                    Total <span>fare</span>
                  </div>
                  <div className="price">
                    {showPriceValue(
                      this.state.grandTotal,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </div>

                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>
                </div>
                <div className="col-2">
                  <Button
                    className="action"
                    onClick={this.continuebooknow.bind(this)}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          blocking={false}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              {this.state.totalFlightPrice > 0 &&
                this.state.addFlight === "Yes" && (
                  <li>
                    <span className="service-content">
                      <strong>Onward Flight fee</strong>
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          this.state.totalFlightPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </li>
                )}
              {this.state.totalFlightPriceReturn > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Return Flight fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalFlightPriceReturn,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.totalHotelPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Hotel fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalHotelPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.transferPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Onwards Transfer fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.transferPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.transferPriceReturn > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Return Transfer fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.transferPriceReturn,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.totalActvitiesPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Things to do fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalActvitiesPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {parseFloat(this.state.platformfee) > 0 && (
                <ul className="sub-taxes">
                  <li>
                    <span className="service-content">Platform fee</span>
                    <span className="service-amount">
                      {showPriceValue(
                        this.state.platformfee,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </span>
                  </li>
                </ul>
              )}
              {discountDetails !== "" && (
                <ul className="sub-taxes">
                  <li>
                    <span className="service-content">
                      {discountDetails.title}
                    </span>
                    <span className="service-amount">
                      -
                      {showPriceValue(
                        discountDetails.amount,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </span>
                  </li>
                </ul>
              )}
            </ul>
            <Divider />
            <div className="details">
              <div className="title">
                Total <span>fare</span>
              </div>
              <div className="col-2">
                <div className="price">
                  {showPriceValue(
                    this.state.grandTotal,
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )}
                </div>
              </div>
            </div>
            <div className="action">
              <Button className="btn" onClick={this.continuebooknow.bind(this)}>
                Checkout
              </Button>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

export default Customerpackageactivities;
