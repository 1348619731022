import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUrl, siteUrl } from "../../Helpers/Config";
import cookie from "react-cookies";
import "./AssistancePopup.scss";
import { toast } from "react-toastify";
import ModalPopup from "../../../common/components/ModalPopup/ModalPopup";
import AsstPopupBgVec from "../../../common/images/asst-popup-bg-vector.svg";
import AssistanceErrorIcon from "../../../common/images/assistance-error-icon.svg";
import SubmitArrowIcon from "../../../common/images/add-room-chevron-right.svg";
import TagInfoIcon from "../../../common/images/Tag_info_icon.svg";
import {
  AvatarGroup,
  Avatar,
  FormControl,
  Button,
  CircularProgress,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import qs from "qs";
import base64 from "base-64";

const AssistancePopup = (props) => {
  const {
    showAssistancePopup,
    handleClose,
    isMobile,
    searchdata,
    packageSlug,
    creatorPackageID,
  } = props;
  const [state, setState] = useState({
    showAssistanceLoader: false,
    travelerName: "",
    travelerEmail: "",
    travelerContactMedium: [],
    travelerContactSession: [],
    travelerMobileNo: "",
    supportAssistanceError: {},
  });
  // const [shareID, setShareID] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    if (showAssistancePopup) handleShareUrl();
  }, [showAssistancePopup]);

  const handleShareUrl = () => {
    const postObject = {
      package_slug: packageSlug,
      details: searchdata,
      share_type: "user",
      creator_package_id: creatorPackageID,
    };
    axios
      .post(apiUrl + "customer/userShare", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          // setShareID(res.data.result_set.share_id);
          setShareUrl(
            `${siteUrl}search-package/${packageSlug}/${searchdata?.packageType}/share?share_id=${res.data.result_set.share_id}`
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const handleTravelerMobileNo = (value) => {
    setState((prevState) => {
      const updatedFormData = { ...prevState };

      updatedFormData["travelerMobileNo"] = value;

      // Remove error related to this field if it exists
      if (updatedFormData.supportAssistanceError?.["travelerMobileNo"]) {
        delete updatedFormData.supportAssistanceError["travelerMobileNo"];
      }

      return updatedFormData;
    });
  };

  const handleAssistanceForm = (event, field) => {
    try {
      const { name, value, checked } = event.target;

      // Regex patterns for validation
      const patterns = {
        travelerEmail: /^[a-zA-Z0-9._%+-]*@?[a-zA-Z0-9.-]*\.?[a-zA-Z]*$/,
        travelerName: /^[a-zA-Z]*$/,
      };

      // Validate input if a pattern exists
      if (patterns[name]?.test(value) === false) return;

      setState((prevState) => {
        const updatedFormData = { ...prevState };

        // Handle checkbox array updates
        if (
          ["travelerContactMedium", "travelerContactSession"].includes(name)
        ) {
          updatedFormData[name] = checked
            ? updatedFormData[name].includes(value) // Only add if not already present
              ? updatedFormData[name] // No change if already present
              : [...updatedFormData[name], value] // Add if not present
            : updatedFormData[name].filter((item) => item !== value); // Remove if unchecked
        }
        // Handle text input updates
        else {
          updatedFormData[name] = value;
        }

        // Remove error related to this field if it exists
        if (updatedFormData.supportAssistanceError[name]) {
          delete updatedFormData.supportAssistanceError[name];
        }

        return updatedFormData;
      });
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleFormData = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSupportAssistance = async () => {
    try {
      handleFormData("showAssistanceLoader", true);

      if (supportAssistanceFormValidation()) {
        const formData = new FormData();
        formData.append("name", state.travelerName);
        formData.append("email", base64.encode(state.travelerEmail));
        formData.append("package_url", shareUrl);
        formData.append("mobile", base64.encode(state.travelerMobileNo));
        formData.append("medium", state.travelerContactMedium);
        formData.append("preferred_time", state.travelerContactSession);
        formData.append("enquiry_type", showAssistancePopup);
        formData.append("package_name", packageSlug);
        const response = await axios.post(
          `${apiUrl}package/support_enquiry`,
          formData,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          }
        );
        if (response?.data?.status === "ok") {
          handleClose();
          toast.success(response?.data?.message, { autoClose: 4000 });
        } else {
          handleClose();
          toast.warning("Failed to Connect. Please try again", {
            autoClose: 4000,
          });
        }
      }
      handleFormData("showAssistanceLoader", false);
    } catch (err) {
      console.error("Error", err);
    }
  };

  const supportAssistanceFormValidation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Email validation regex

    const errorObject = {
      ...(!state.travelerName && { travelerName: "Name is required" }),
      ...(!state.travelerEmail && { travelerEmail: "Email is required" }),
      ...(state.travelerEmail &&
        !emailRegex.test(state.travelerEmail) && {
          travelerEmail: "Invalid email format",
        }),
      ...(!state.travelerMobileNo && {
        travelerMobileNo: "Contact number is required",
      }),
      ...(!state.travelerContactMedium.length &&
        showAssistancePopup === "one-to-one" && {
          travelerContactMedium: "Medium is required",
        }),
      ...(!state.travelerContactSession.length && {
        travelerContactSession: "Session is required",
      }),
    };

    if (Object.keys(errorObject).length > 0) {
      handleFormData("supportAssistanceError", errorObject);
      return false;
    }

    return true;
  };

  return (
    <>
      <ModalPopup
        open={showAssistancePopup !== ""}
        handleClose={() => handleClose()}
      >
        <div className="asst-popup-main__row">
          <div className="asst-popup-main__row__col1">
            <img
              className="asst-popup-main__row__col1__bgVec"
              src={AsstPopupBgVec}
              alt="AsstPopupBgVec"
            />
            <div className="asst-popup-main__row__col1__heading">
              <AvatarGroup>
                <Avatar
                  alt="Gowtham"
                  src="https://images.fizota.com/Gowtham.webp"
                />
                <Avatar alt="Bose" src="https://images.fizota.com/Bose.webp" />
              </AvatarGroup>
              {showAssistancePopup === "callback" ? (
                <>
                  <p>Need Help?</p>
                  <p>Request a callback!</p>
                </>
              ) : (
                <>
                  <p>Free! 1-on-1</p>
                  <p>Trip Planning</p>
                </>
              )}
            </div>
            <div className="asst-popup-main__row__col1__subTxt">
              {showAssistancePopup === "callback"
                ? "Start planning your dream vacation today! Submit your contact details, and a travel expert will contact you shortly."
                : "Connect with our travel experts via Google Meet, Zoom, Teams, or other platforms for personalized vacation planning."}
            </div>
            {!isMobile && (
              <div className="asst-popup-main__row__col1__service">
                <ul>
                  <li>4.9/5 by happy travelers worldwide!</li>
                  <li>Hyper customize your vacation</li>
                  <li>Curated Itineraries from local providers</li>
                  <li>Optimized itinerary & instant booking</li>
                  <li>Zero transaction fee</li>
                  <li>24/7 Support</li>
                </ul>
              </div>
            )}
          </div>
          <div className="asst-popup-main__row__col2">
            <h3 className="asst-popup-main__row__col2__heading">
              Enter details
            </h3>
            <div className="asst-popup-main__row__col2__inputs">
              <label for="name">Name</label>
              <input
                id="traveler-name"
                type="text"
                name="travelerName"
                placeholder="Traveler name"
                value={state.travelerName}
                onChange={(e) => handleAssistanceForm(e)}
                className={
                  state.supportAssistanceError["travelerName"]
                    ? "errorInput"
                    : ""
                }
              />
              {state.supportAssistanceError["travelerName"] && (
                <span className="asst-popup-main__row__col2__inputs__errorText">
                  <img src={AssistanceErrorIcon} alt="AssistanceErrorIcon" />
                  {state.supportAssistanceError["travelerName"]}
                </span>
              )}
              <label for="email">Email</label>
              <input
                id="email-address"
                type="text"
                name="travelerEmail"
                placeholder="Email address"
                value={state.travelerEmail}
                onChange={(e) => handleAssistanceForm(e)}
                className={
                  state.supportAssistanceError["travelerEmail"]
                    ? "errorInput"
                    : ""
                }
              />
              {state.supportAssistanceError["travelerEmail"] && (
                <span className="asst-popup-main__row__col2__inputs__errorText">
                  <img src={AssistanceErrorIcon} alt="AssistanceErrorIcon" />
                  {state.supportAssistanceError["travelerEmail"]}
                </span>
              )}
              <label for="phone">Phone no</label>
              <FormControl sx={{ m: 1, width: "25ch" }}>
                <PhoneInput
                  country={"sg"}
                  name="travelerMobileNo"
                  value={state.travelerMobileNo}
                  onChange={(e) => handleTravelerMobileNo(e)}
                  className={
                    state.supportAssistanceError["travelerMobileNo"]
                      ? "errorInput"
                      : ""
                  }
                />
              </FormControl>
              {state.supportAssistanceError["travelerMobileNo"] && (
                <span className="asst-popup-main__row__col2__inputs__errorText">
                  <img src={AssistanceErrorIcon} alt="AssistanceErrorIcon" />
                  {state.supportAssistanceError["travelerMobileNo"]}
                </span>
              )}
              {showAssistancePopup !== "callback" && (
                <div className="asst-popup-main__row__col2__inputs__checkbox-grp">
                  <p>Please select the medium</p>
                  {["google-meet", "zoom", "teams", "others"].map((medium) => (
                    <label key={medium}>
                      <input
                        type="checkbox"
                        name="travelerContactMedium"
                        value={medium}
                        onChange={(e) =>
                          handleAssistanceForm(e, "travelerContactMedium")
                        }
                      />{" "}
                      {medium.charAt(0).toUpperCase() + medium.slice(1)}
                    </label>
                  ))}
                  {state.supportAssistanceError["travelerContactMedium"] && (
                    <span className="asst-popup-main__row__col2__inputs__errorText">
                      <img
                        src={AssistanceErrorIcon}
                        alt="AssistanceErrorIcon"
                      />
                      {state.supportAssistanceError["travelerContactMedium"]}
                    </span>
                  )}
                </div>
              )}
              <div className="asst-popup-main__row__col2__inputs__checkbox-grp">
                <p>Please select the session</p>
                {["morning", "afternoon", "evening"].map((session) => (
                  <label key={session}>
                    <input
                      type="checkbox"
                      name="travelerContactSession"
                      value={session}
                      onChange={(e) =>
                        handleAssistanceForm(e, "travelerContactSession")
                      }
                    />{" "}
                    {session.charAt(0).toUpperCase() + session.slice(1)}
                  </label>
                ))}
                {state.supportAssistanceError["travelerContactSession"] && (
                  <span className="asst-popup-main__row__col2__inputs__errorText">
                    <img src={AssistanceErrorIcon} alt="AssistanceErrorIcon" />
                    {state.supportAssistanceError["travelerContactSession"]}
                  </span>
                )}
              </div>
            </div>
            <div className="asst-popup-main__row__col2__btns">
              <div className="asst-popup-main__row__col2__btns__scroll-shadow"></div>
              <Button onClick={() => handleSupportAssistance()}>
                {state.showAssistanceLoader ? (
                  <CircularProgress className="spinner" size={25} />
                ) : (
                  <>
                    Submit
                    <img src={SubmitArrowIcon} alt="SubmitArrowIcon" />
                  </>
                )}
              </Button>
              <div className="asst-popup-main__row__col2__btns__agreeTxt">
                <img src={TagInfoIcon} alt="TagInfoIcon" />
                <p>
                  By clicking "Submit” you agree to our{" "}
                  <Link to={"/terms-and-conditions"} target="_blank">
                    Terms and Conditions
                  </Link>{" "}
                  &{" "}
                  <Link to={"/privacy-policy"} target="_blank">
                    Privacy Policy
                  </Link>
                  .{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </>
  );
};

export default AssistancePopup;
