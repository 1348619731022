/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import "./VacationSummary.scss";
import { checkingDiscount } from "../Helpers/SettingHelper";
import FlightIcon from "../../common/images/p_flight.svg";
import TicketIcon from "../../common/images/p_ticket.svg";
import BedIcon from "../../common/images/p_bed.svg";
import CalendarIcon from "../../common/images/calendar1.svg";
import ProfileIcon from "../../common/images/profile.svg";
import MoonIcon from "../../common/images/moon.svg";
import GlobalIcon from "../../common/images/global.svg";
import CarIcon from "../../common/images/p_car.svg";
import DiscountIcon from "../../common/images/discount.svg";
import editImg from "../../common/images/Edit.svg";
import {
  InfoOutlined as InfoIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from "@mui/icons-material";
import Footer from "../Layout/Footer";
import { Button, Divider, AvatarGroup, Avatar } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import cookie from "react-cookies";
import { showPriceValue } from "../Helpers/SettingHelper";
import AsstBannerBgVec from "../../common/images/asst-banner-bg.svg";
import AsstWhatappIcon from "../../common/images/asst-whatsapp-icon.svg";
import AsstCallIcon from "../../common/images/asst-call-icon.svg";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AssistancePopup from "../Customerpackage/AssistanceHelpPopup/AssistancePopup";
import AssistanceCard from "../Customerpackage/AssistanceHelpPopup/AssistanceCard";
import AssetChevronRight from "../../common/images/asst-chevron-right.svg";
var base64 = require("base-64");
import { format, add } from "date-fns";
class VacationSummary extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";

    var searchdata = "";
    var checkinTime = "";
    var end_date = "";
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      checkinTime = searchdata.checkinTime;
      end_date = searchdata.end_date;
    } else {
      this.props.history.push("/");
    }
    var packageSlug = "";
    if (
      this.props.location.state.packageSlug !== "" &&
      typeof this.props.location.state.packageSlug !== undefined &&
      typeof this.props.location.state.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.location.state.packageSlug;
    } else {
      this.props.history.push("/");
    }
    var ownpackage = false;
    if (packageSlug === "create-own-package") {
      ownpackage = true;
    }

    var flightList =
      this.props.location.state.flightList !== "" &&
      typeof this.props.location.state.flightList !== undefined &&
      typeof this.props.location.state.flightList !== "undefined"
        ? this.props.location.state.flightList
        : [];

    var selectedflight =
      this.props.location.state.selectedflight !== "" &&
      typeof this.props.location.state.selectedflight !== undefined &&
      typeof this.props.location.state.selectedflight !== "undefined"
        ? this.props.location.state.selectedflight
        : "";
    var flightPrice =
      this.props.location.state.flightPrice !== "" &&
      typeof this.props.location.state.flightPrice !== undefined &&
      typeof this.props.location.state.flightPrice !== "undefined"
        ? this.props.location.state.flightPrice
        : "";

    var addFlight =
      this.props.location.state.addFlight !== "" &&
      typeof this.props.location.state.addFlight !== undefined &&
      typeof this.props.location.state.addFlight !== "undefined"
        ? this.props.location.state.addFlight
        : "No";
    var addFlightReturn =
      this.props.location.state.addFlightReturn !== "" &&
      typeof this.props.location.state.addFlightReturn !== undefined &&
      typeof this.props.location.state.addFlightReturn !== "undefined"
        ? this.props.location.state.addFlightReturn
        : "No";

    var selectedHotelID =
      this.props.location.state.selectedHotelID !== "" &&
      typeof this.props.location.state.selectedHotelID !== undefined &&
      typeof this.props.location.state.selectedHotelID !== "undefined"
        ? this.props.location.state.selectedHotelID
        : "";

    var selectedHotel =
      this.props.location.state.selectedHotel !== "" &&
      typeof this.props.location.state.selectedHotel !== undefined &&
      typeof this.props.location.state.selectedHotel !== "undefined"
        ? this.props.location.state.selectedHotel
        : [];

    var selectedRoom =
      this.props.location.state.selectedRoom !== "" &&
      typeof this.props.location.state.selectedRoom !== undefined &&
      typeof this.props.location.state.selectedRoom !== "undefined"
        ? this.props.location.state.selectedRoom
        : "";

    var activities =
      this.props.location.state.activities !== "" &&
      typeof this.props.location.state.activities !== undefined &&
      typeof this.props.location.state.activities !== "undefined"
        ? this.props.location.state.activities
        : [];
    var oldactivities =
      this.props.location.state.oldactivities !== "" &&
      typeof this.props.location.state.oldactivities !== undefined &&
      typeof this.props.location.state.oldactivities !== "undefined"
        ? this.props.location.state.oldactivities
        : [];

    var removeFlight =
      this.props.location.state.removeFlight !== "" &&
      typeof this.props.location.state.removeFlight !== undefined &&
      typeof this.props.location.state.removeFlight !== "undefined"
        ? this.props.location.state.removeFlight
        : "No";

    var transferList =
      this.props.location.state.transferList !== "" &&
      typeof this.props.location.state.transferList !== undefined &&
      typeof this.props.location.state.transferList !== "undefined"
        ? this.props.location.state.transferList
        : [];

    var selectedTransfer = this.props.location.state?.selectedTransfer ?? "";

    var reserveTransfer =
      this.props.location.state.reserveTransfer !== "" &&
      typeof this.props.location.state.reserveTransfer !== undefined &&
      typeof this.props.location.state.reserveTransfer !== "undefined"
        ? this.props.location.state.reserveTransfer
        : [];

    var stayList =
      this.props.location.state.stayList !== "" &&
      typeof this.props.location.state.stayList !== undefined &&
      typeof this.props.location.state.stayList !== "undefined"
        ? this.props.location.state.stayList
        : [];

    var addTransfer =
      this.props.location.state.addTransfer !== "" &&
      typeof this.props.location.state.addTransfer !== undefined &&
      typeof this.props.location.state.addTransfer !== "undefined"
        ? this.props.location.state.addTransfer
        : "No";
    var emptyFlight =
      this.props.location.state.emptyFlight !== "" &&
      typeof this.props.location.state.emptyFlight !== undefined &&
      typeof this.props.location.state.emptyFlight !== "undefined"
        ? this.props.location.state.emptyFlight
        : "Yes";

    var removetransfer =
      this.props.location.state.removetransfer !== "" &&
      typeof this.props.location.state.removetransfer !== undefined &&
      typeof this.props.location.state.removetransfer !== "undefined"
        ? this.props.location.state.removetransfer
        : "No";

    var emptyhotel =
      this.props.location.state.emptyhotel !== "" &&
      typeof this.props.location.state.emptyhotel !== undefined &&
      typeof this.props.location.state.emptyhotel !== "undefined"
        ? this.props.location.state.emptyhotel
        : "Yes";
    var emptytransfer =
      this.props.location.state.emptytransfer !== "" &&
      typeof this.props.location.state.emptytransfer !== undefined &&
      typeof this.props.location.state.emptytransfer !== "undefined"
        ? this.props.location.state.emptytransfer
        : "Yes";
    var removedLastActivity =
      this.props.location.state.removedLastActivity !== "" &&
      typeof this.props.location.state.removedLastActivity !== undefined &&
      typeof this.props.location.state.removedLastActivity !== "undefined"
        ? this.props.location.state.removedLastActivity
        : false;

    var ArrivalDateFormat =
      this.props.location.state.ArrivalDateFormat !== "" &&
      typeof this.props.location.state.ArrivalDateFormat !== undefined &&
      typeof this.props.location.state.ArrivalDateFormat !== "undefined"
        ? this.props.location.state.ArrivalDateFormat
        : "";

    var packagedetails =
      this.props.location.state.packagedetails !== "" &&
      typeof this.props.location.state.packagedetails !== undefined &&
      typeof this.props.location.state.packagedetails !== "undefined"
        ? this.props.location.state.packagedetails
        : "";
    const emptyActivity = this.props.location.state?.emptyActivity ?? "Yes";
    var flightPriceType = "";
    if (
      this.props.location.state.flightPriceType !== "" &&
      typeof this.props.location.state.flightPriceType !== undefined &&
      typeof this.props.location.state.flightPriceType !== "undefined"
    ) {
      flightPriceType = this.props.location.state.flightPriceType;
    }
    var viewSelectedFlight =
      this.props.location.state.viewSelectedFlight !== "" &&
      typeof this.props.location.state.viewSelectedFlight !== undefined &&
      typeof this.props.location.state.viewSelectedFlight !== "undefined"
        ? this.props.location.state.viewSelectedFlight
        : false;
    var ttdOnly = false;
    if (
      this.props.location.state.searchdata.ttdOnly !== "" &&
      typeof this.props.location.state.searchdata.ttdOnly !== undefined &&
      typeof this.props.location.state.searchdata.ttdOnly !== "undefined"
    ) {
      ttdOnly = this.props.location.state.searchdata.ttdOnly;
    }
    this.state = {
      userID: userID,
      /* Search Data & Package Details */
      searchdata: searchdata,
      packageSlug: packageSlug,
      packageDetails: packagedetails,
      end_date: end_date,
      /* Fligh */
      flightList: flightList,
      selectedflight: selectedflight,
      flightPrice: flightPrice,
      addFlight: addFlight,
      addFlightReturn: addFlightReturn,
      removeFlight: removeFlight,
      viewSelectedFlight: viewSelectedFlight,
      flightPriceType: flightPriceType,

      flightPriceTypeReturn:
        this.props.location.state?.flightPriceTypeReturn || "",
      mystiflyPricestatusReturn:
        this.props.location.state?.mystiflyPricestatusReturn || "",
      flightPriceReturn: this.props.location.state?.flightPriceReturn || "",
      flightcheapestPrice: this.props.location.state?.flightcheapestPrice ?? "",
      flightcheapestPriceReturn:
        this.props.location.state?.flightcheapestPriceReturn || "",

      /* Activities */
      activities: activities,
      oldactivities: oldactivities,
      removedLastActivity: removedLastActivity,
      ArrivalDateFormat: ArrivalDateFormat,
      emptyActivity: emptyActivity,
      /* Hotel */
      selectedHotel: selectedHotel,
      selectedHotelID: selectedHotelID,
      stayList: stayList,
      selectedRoom: selectedRoom,
      /* Transfer */
      transferList: transferList,
      selectedTransfer: selectedTransfer,
      reserveTransfer: reserveTransfer,
      addTransfer: addTransfer,
      removetransfer: removetransfer,

      transferListReturn: this.props.location.state?.transferListReturn || [],
      selectedTransferReturn:
        this.props.location.state?.selectedTransferReturn ?? "",
      reserveTransferReturn:
        this.props.location.state?.reserveTransferReturn || "No",
      addTransferReturn: this.props.location.state?.addTransferReturn || "No",
      removetransferReturn:
        this.props.location.state?.removetransferReturn || "NO",

      /*Empmty Status*/
      emptyFlight: emptyFlight,
      emptyhotel: emptyhotel,
      emptytransfer: emptytransfer,
      ownpackage: ownpackage,
      /*prices */
      platformFee: 10,
      totalActvitiesPrice: 0,
      totalActivity: 0,
      totalFlightPrice: 0,
      totalFlightPriceReturn: 0,
      totalHotelPrice: 0,
      transferPrice: 0,
      transferPriceReturn: 0,
      subTotal: 0,
      grandTotal: 0,
      adultsQty: 0,
      childQty: 0,
      infantQty: 0,
      adultPrice: 0,
      childPrice: 0,
      infantPrice: 0,
      ttdOnly: ttdOnly,
      activities_count: 0,
      finalDropoffTime1: "",
      transferdate1: "",
      finalDropoffTime2: "",
      transferdate2: "",

      isFlight: false,
      isActivities: false,
      isVacation: true,
      stayStartDate: this.props.location.state?.stayStartDate || start_date,
      stayEndDate: this.props.location.state?.stayEndDate || end_date,
      activityStartDate:
        this.props.location.state?.activityStartDate || start_date,
      activityEndDate: this.props.location.state?.activityEndDate || end_date,
      returnFlightDate: this.props.location.state?.returnFlightDate || end_date,
      transferStartDate:
        this.props.location.state?.transferStartDate || start_date,
      transferEndDate: this.props.location.state?.transferEndDate || end_date,

      showFareBreakdown: false,
      isMobile: window.innerWidth <= 480,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      showAssistanceCard: false,
      showAssistancePopup: "",
      selectedFlightType:this.props.location.state?.selectedFlightType ?? '',
      selectedFlightTypeReturn:this.props.location.state?.selectedFlightTypeReturn ?? '',
    };
  }

  componentDidMount() {
    this.updateActivityCount();
    this.calculatePrice();
    if (
      this.state.addTransfer === "Yes" ||
      this.state.addTransferReturn === "Yes"
    ) {
      this.calculateFinalDropoffTimes();
    }
    if (this.state.searchdata?.packageType !== "uop") {
      this.interval = setTimeout(() => {
        this.setState({ showAssistanceCard: true });
      }, 60000);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activities !== this.state.activities) {
      this.updateCount();
    }
  }

  calculatePrice() {
    try {
      var adults =
        this.state.searchdata.adults !== "" &&
        typeof this.state.searchdata.adults !== undefined &&
        typeof this.state.searchdata.adults !== "undefined"
          ? this.state.searchdata.adults
          : "";
      var child =
        this.state.searchdata.child !== "" &&
        typeof this.state.searchdata.child !== undefined &&
        typeof this.state.searchdata.child !== "undefined"
          ? this.state.searchdata.child
          : 0;

      var infant =
        this.state.searchdata.infant !== "" &&
        typeof this.state.searchdata.infant !== undefined &&
        typeof this.state.searchdata.infant !== "undefined"
          ? this.state.searchdata.infant
          : 0;
      var totalActvitiesPrice = 0;
      var totalActivity = 0;
      var adultPrice = 0;
      var childPrice = 0;
      var infantPrice = 0;
      if (this.state.activities.length > 0) {
        this.state.activities.map((item) => {
          if (item.activities.length > 0) {
            item.activities.map((item1) => {
              if (item1 !== "") {
                if (
                  item1.ticket_type.length > 0 &&
                  item1.activity_availablity === "Yes"
                ) {
                  totalActivity++;
                  item1.ticket_type.map((item2) => {
                    var currentQty =
                      item2.quantity !== "" ? parseInt(item2.quantity) : 0;
                    if (currentQty > 0) {
                      if (item2.package_price_name === "ADULT") {
                        adultPrice =
                          parseFloat(adultPrice) +
                          parseFloat(item2.package_pricenettPrice) *
                            parseInt(currentQty);
                      }
                      if (item2.package_price_name === "CHILD") {
                        childPrice =
                          parseFloat(childPrice) +
                          parseFloat(item2.package_pricenettPrice) *
                            parseInt(currentQty);
                      }
                      totalActvitiesPrice =
                        parseFloat(totalActvitiesPrice) +
                        parseFloat(item2.package_pricenettPrice) *
                          parseInt(currentQty);
                    }
                  });
                }
              }
            });
          }
        });
      }
      var flightPriceList = this.state.flightPrice;
      if (this.state.selectedFlightType === "cheapest") {
        flightPriceList = this.state.flightcheapestPrice;
      }
      let flightPriceTotal = 0;

      if (
        flightPriceList &&
        typeof flightPriceList === "object" &&
        flightPriceList.totalPayAmount
      ) {
        let totalPayAmount = flightPriceList.totalPayAmount.trim(); // Clean up the value
        if (!isNaN(totalPayAmount)) {
          flightPriceTotal = parseFloat(totalPayAmount);
          flightPriceList?.pricedetails?.map((item) => {
            if (item.passengerCode === "ADT") {
              adultPrice =
                parseFloat(adultPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "CHD") {
              childPrice =
                parseFloat(childPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "INF") {
              infantPrice =
                parseFloat(infantPrice) + parseFloat(item.passengerTotalprice);
            }
          });
        }
      }

      if (
        flightPriceList.length !== "" &&
        typeof flightPriceList !== undefined &&
        typeof flightPriceList !== "undefined"
      ) {
        flightPriceTotal = parseFloat(flightPriceList?.totalPayAmount);
        if (flightPriceList?.pricedetails?.length > 0) {
          flightPriceList?.pricedetails?.map((item) => {
            if (item.passengerCode === "ADT") {
              adultPrice =
                parseFloat(adultPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "CHD") {
              childPrice =
                parseFloat(childPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "INF") {
              infantPrice =
                parseFloat(infantPrice) + parseFloat(item.passengerTotalprice);
            }
          });
        }
      }
      var flightPriceListReturn = this.state.flightPriceReturn;
      if (this.state.selectedFlightTypeReturn === "cheapest") {
        flightPriceListReturn = this.state.flightcheapestPriceReturn;
      }
      let flightPriceReturn = 0;
      if (
        flightPriceListReturn.length !== 0 &&
        typeof flightPriceListReturn !== undefined &&
        typeof flightPriceListReturn !== "undefined"
      ) {
        flightPriceReturn = parseFloat(flightPriceListReturn.totalPayAmount);
        if (flightPriceListReturn?.pricedetails?.length > 0) {
          flightPriceListReturn?.pricedetails?.map((item) => {
            if (item.passengerCode === "ADT") {
              adultPrice =
                parseFloat(adultPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "CHD") {
              childPrice =
                parseFloat(childPrice) + parseFloat(item.passengerTotalprice);
            } else if (item.passengerCode === "INF") {
              infantPrice =
                parseFloat(infantPrice) + parseFloat(item.passengerTotalprice);
            }
          });
        }
      }

      let totalHotelPrice = 0;
      if (
        this.state.selectedRoom.length > 0 &&
        Object.keys(this.state.selectedHotel).length > 0
      ) {
        this.state.selectedRoom.map((item) => {
          var rate_total = 0;
          if (
            this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== "" &&
            typeof this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== undefined &&
            typeof this.state.selectedHotel.room_info[item.roomMainIndex][
              item.roomIndex
            ] !== "undefined"
          ) {
            if (
              this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== "" &&
              typeof this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== undefined &&
              typeof this.state.selectedHotel.room_info[item.roomMainIndex][
                item.roomIndex
              ].room_charges[item.priceIndex] !== "undefined"
            ) {
              rate_total =
                this.state.selectedHotel.room_info[item.roomMainIndex][
                  item.roomIndex
                ].room_charges[item.priceIndex].room_total_amount;
            }
          }

          totalHotelPrice =
            parseFloat(totalHotelPrice) + parseFloat(rate_total);
        });
      }

      var transferPrice = 0;
      if (
        this.state.reserveTransfer !== "" &&
        this.state.addTransfer === "Yes" &&
        this.state.removetransfer == "No"
      ) {
        transferPrice = this.state.reserveTransfer.total_amount;
      }
      var transferPriceReturn = 0;
      if (
        this.state.reserveTransferReturn !== "" &&
        this.state.addTransferReturn === "Yes" &&
        this.state.removetransferReturn == "No"
      ) {
        transferPriceReturn = this.state.reserveTransferReturn.total_amount;
      }

      var addflightPrice = 0;
      if (this.state.addFlight === "Yes") {
        addflightPrice = flightPriceTotal.toFixed("2");
      }
      var addflightPriceReturn = 0;
      if (this.state.addFlightReturn === "Yes") {
        addflightPriceReturn = flightPriceReturn.toFixed("2");
      }

      var subTotal = (
        parseFloat(totalActvitiesPrice) +
        parseFloat(addflightPrice) +
        parseFloat(addflightPriceReturn) +
        parseFloat(this.state.platformFee) +
        parseFloat(totalHotelPrice) +
        parseFloat(transferPrice) +
        parseFloat(transferPriceReturn)
      ).toFixed(2);

      var grandTotal = subTotal;
      if (this.state.packageDetails !== "") {
        if (this.state.packageDetails.discount !== "") {
          var discount = checkingDiscount(
            this.state.packageDetails.discount,
            subTotal
          );
          grandTotal = subTotal - discount.amount;
        }
      }

      this.setState({
        totalActivity: totalActivity,
        totalActvitiesPrice: totalActvitiesPrice,
        totalFlightPrice: flightPriceTotal.toFixed("2"),
        totalFlightPriceReturn:
          parseFloat(flightPriceReturn) > 0
            ? flightPriceReturn.toFixed("2")
            : 0,
        subTotal: subTotal,
        grandTotal: grandTotal,
        adultsQty: adults,
        childQty: child,
        infantQty: infant,
        adultPrice: adultPrice.toFixed(2),
        childPrice: childPrice.toFixed(2),
        infantPrice: infantPrice.toFixed(2),
        totalHotelPrice: totalHotelPrice,
        transferPriceReturn: transferPriceReturn,
        transferPrice: transferPrice,
      });
    } catch (err) {
      console.log(err);
    }
  }
  updateActivityCount() {
    var count = 0;
    this.state.activities.map((item, index) =>
      item.activities.map((activity, timeindex) => {
        if (activity !== "") {
          count++;
        }
      })
    );

    this.setState({ activities_count: count });
  }

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  continuebooknow() {
    var error = 0;
    var message = "";
    /* if (this.state.addFlight === "No" && this.state.removeFlight === "No") {
      message += "<p>Please add flight</p>";
      error++;
    } */
    if (this.state.packageSlug !== "create-own-package") {
      if (
        this.state.selectedHotelID === "" &&
        this.state.packageSlug !== "create-own-package" &&
        this.state.ttdOnly === false
      ) {
        message += "<p>Please select any one hotel</p>";
        error++;
      }
    }
    /* if (this.state.selectedTransfer === "") {
    message += "<p>Please add transfer</p>";
    error++;
  } */

    /*  var emptyActivity = "Yes";
    var removeActivityList = [];
    if (this.state.activities.length > 0) {
      this.state.activities.map((item) => {
        if (item.activities.length > 0) {
          emptyActivity = "No";
          item.activities.map((actvityItem) => {
            if (actvityItem !== "") {
              if (actvityItem.activity_availablity !== "Yes") {
                removeActivityList.push(
                  actvityItem.products_name +
                  "(" +
                  actvityItem.package_name +
                  ")"
                );
              }
            }
          });
        }
      });
    }
    if (removeActivityList.length > 0) {
      this.setState({ removeActivityList: removeActivityList }, function () {
        $.magnificPopup.open({
          items: {
            src: "#activity-not-avail-alert",
          },
          type: "inline",
        });
      });
      return false;
    } */

    if (error === 0) {
      var searchdata = this.state.searchdata;
      var packageDetails = this.state.packageDetails;

      if (this.state.packageSlug === "create-own-package") {
        packageDetails = {};
        packageDetails["discount"] = "";
      }

      /*  searchdata["end_date"] = this.state.end_date;
      searchdata["start_date"] = this.state.start_date;
      searchdata["adults"] = this.state.adults;
      searchdata["child"] = this.state.child;
      searchdata["infant"] = this.state.infant;
      searchdata["room_count"] = this.state.room_count; */
      var bookingdetails = this.props.location.state;
      if (bookingdetails) {
        localStorage.setItem("bookingdetails", JSON.stringify(bookingdetails));
      }
      if (this.state.totalAvailctvities > 0) {
        this.props.history.push({
          pathname: "/" + this.state.packageSlug + "/package-activities",
          state: bookingdetails,
        });
      } else {
        if (this.state.userID === "") {
          this.setState({ triggerLogin: true, continuestep: true });
        } else {
          this.props.history.push({
            pathname: "/booking",
            state: bookingdetails,
          });
        }
      }
    } else {
      showAlert("Error", message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }
  editPackage() {
    const bookingdetails = { ...this.props?.location?.state };
    const selectedHotelID = this.props.location?.state?.selectedHotelID
      ? base64.encode(this.props.location.state.selectedHotelID)
      : "";
    bookingdetails.selectedHotelID = selectedHotelID;
    this.props.history.push({
      pathname: "/search-package/" + this.state.packageSlug,
      state: bookingdetails,
    });
  }
  convertDateFormat(dateString) {
    if (typeof dateString === "string") {
      var [day, month, year] = dateString.split("/");
      const date = new Date(year, month - 1, day);
      const monthAbbr = date.toLocaleString("default", { month: "short" });
      const yearShort = date.getFullYear().toString().slice(-2);
      return `${day} ${monthAbbr} ${yearShort}`;
    }
  }
  calculateFinalDropoffTimes = () => {
    let originalTime =
      this.state.flightPrice?.onwayInfo[
        this.state.flightPrice?.onwayInfo?.length - 1
      ]?.ArrivalTime;
    let hours = parseInt(originalTime.substring(0, 2), 10);
    let minutes = parseInt(originalTime.substring(3), 10);
    let additionalMinutes = parseInt(
      this.state.transferList[this.state.selectedTransfer]?.duration_min
    );
    let totalMinutes = hours * 60 + minutes + additionalMinutes;
    let finalHours = Math.floor(totalMinutes / 60);
    let finalMinutes = totalMinutes % 60;
    let finalTime =
      ("0" + finalHours).slice(-2) + ":" + ("0" + finalMinutes).slice(-2);

    let dropoffTime2 =
      this.state.reserveTransferReturn?.pickuptime_for_journey1;
    let dropoffHours2 =
      typeof dropoffTime2 === "string"
        ? parseInt(dropoffTime2.substring(0, 2), 10)
        : 0;
    let dropoffMinutes2 =
      typeof dropoffTime2 === "string"
        ? parseInt(dropoffTime2.substring(2), 10)
        : 0;
    let durationMin2 = parseInt(
      this.state.transferListReturn[this.state.selectedTransferReturn]
        ?.duration_min
    );
    let finalHours2 =
      dropoffHours2 +
      Math.floor((dropoffMinutes2 + parseInt(durationMin2)) / 60);
    let finalMinutes2 = (dropoffMinutes2 + durationMin2) % 60;
    finalHours2 = finalHours2 % 24;

    let transferdate1 = this.convertDateFormat(
      this.state.reserveTransfer?.pickupdate_for_journey1
    );
    let transferdate2 = this.convertDateFormat(
      this.state.reserveTransferReturn?.pickupdate_for_journey1
    );
    this.setState({
      finalDropoffTime1: /* `${finalHours1
          .toString()
          .padStart(2, "0")}:${finalMinutes1.toString().padStart(2, "0")}` */ finalTime,
      transferdate1: transferdate1,
      finalDropoffTime2: `${finalHours2
        .toString()
        .padStart(2, "0")}:${finalMinutes2.toString().padStart(2, "0")}`,
      transferdate2: transferdate2,
    });
  };

  goBack = () => {
    var bookingdetails = this.props.location.state;
    bookingdetails.selectedHotelID = base64.encode(
      bookingdetails.selectedHotelID
    );
    if (this.state.totalActvitiesPrice > 0) {
      this.props.history.push({
        pathname: "/" + this.state.packageSlug + "/package-activities",
        state: bookingdetails,
      });
    } else {
      this.props.history.push({
        pathname: "/search-package/" + this.state.packageSlug,
        state: bookingdetails,
      });
    }
  };

  handleAssistancePopup(value) {
    this.setState({ showAssistancePopup: value });
  }

  render() {
    let discountDetails = "";
    if (this.state.packageDetails !== "") {
      if (this.state.packageDetails.discount !== "") {
        discountDetails = checkingDiscount(
          this.state.packageDetails.discount,
          this.state.subTotal
        );
      }
    }
    let flightPrice = this.state.flightPrice;
    if (this.state.selectedFlightType === "cheapest") {
      flightPrice = this.state.flightcheapestPrice;
    }
    let flightPriceReturn = this.state.flightPriceReturn;
    if (this.state.selectedFlightTypeReturn === "cheapest") {
      flightPriceReturn = this.state.flightcheapestPriceReturn;
    }

    return (
      <>
        <Header
          {...this.props}
          pagestate={this.state}
          sateValChange={this.sateValChange}
        />
        <div className="vacationSummary">
          <div className="vacationSummary__heading">
            <ArrowBackIosIcon onClick={this.goBack} />
            <div className="vacationSummary__heading__title">
              Your vacation summary
              <div className="vacationSummary__heading__title__sub">
                Carefully read your summary before booking
              </div>
            </div>
          </div>
          <div className="vacationSummary__row">
            <div className="vacationSummary__row__col1">
              {this.state.addFlight === "Yes" && (
                <div className="vacationSummary__row__col1__box">
                  <div className="vacationSummary__row__col1__box__row1">
                    <div className="vacationSummary__row__col1__box__row1__title">
                      Your flight for the vacation{" "}
                      <span>
                        {flightPriceReturn?.onwayInfo?.length > 0
                          ? "Flight round trip"
                          : "One Way"}
                      </span>
                    </div>
                    <div
                      className="vacationSummary__row__col1__box__row1__edit"
                      onClick={this.editPackage.bind(this)}
                    >
                      <img src={editImg} />
                      Edit
                    </div>
                  </div>
                  <div className="vacationSummary__row__col1__box__row2">
                    {flightPrice?.onwayInfo?.length > 0 &&
                      this.state.addFlight === "Yes" && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Onward information
                          </div>
                          <ul>
                            <li>
                              Depature from{" "}
                              {
                                flightPrice?.onwayInfo[0]
                                  ?.DepartureAirportLocation
                              }{" "}
                              at{" "}
                              {
                                flightPrice?.onwayInfo[0]
                                  ?.DepartureTime
                              }{" "}
                              on{" "}
                              {
                                flightPrice?.onwayInfo[0]
                                  ?.DepartureDate
                              }
                            </li>
                            <li>
                              Arrival at{" "}
                              {
                                flightPrice?.onwayInfo[
                                  flightPrice?.onwayInfo?.length - 1
                                ]?.ArrivalAirportLocation
                              }{" "}
                              at{" "}
                              {flightPrice?.onwayInfo[flightPrice?.onwayInfo?.length - 1]?.ArrivalTime}{" "}
                              on{" "}
                              {flightPrice?.onwayInfo[flightPrice?.onwayInfo?.length - 1]?.ArrivalDate}
                            </li>
                            <li>
                              {flightPrice?.onwayInfo?.length === 1
                                ? "Direct Flight"
                                : flightPrice?.onwayInfo?.map(
                                    (stop, index) => (
                                      <div key={index}>
                                        {index <
                                          flightPrice?.onwayInfo
                                            ?.length -
                                            1 && `Stop ${index + 1}:`}{" "}
                                        {index <
                                          flightPrice?.onwayInfo
                                            .length -
                                            1 &&
                                          ` at ${flightPrice?.onwayInfo[index]?.ArrivalAirportLocation}.`}
                                      </div>
                                    )
                                  )}
                            </li>
                            <li>
                              Journey Time :{" "}
                              {flightPrice?.JourneyDuration}
                            </li>
                            <li>
                              {
                                flightPrice?.onwayInfo[0]
                                  ?.OperatingCarrieName
                              }{" "}
                            </li>
                          </ul>
                        </div>
                      )}
                    {flightPriceReturn?.onwayInfo?.length > 0 &&
                      this.state.addFlightReturn === "Yes" && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Return information
                          </div>
                          <ul>
                            <li>
                              Depature from{" "}
                              {flightPriceReturn?.onwayInfo[0]?.DepartureAirportLocation}{" "}
                              at{" "}
                              {flightPriceReturn?.onwayInfo[0]?.DepartureTime}{" "}
                              on{" "}
                              {flightPriceReturn?.onwayInfo[0]?.DepartureDate}
                            </li>
                            <li>
                              Arrival at{" "}
                              {flightPriceReturn?.onwayInfo[flightPriceReturn?.onwayInfo?.length - 1]?.ArrivalAirportLocation
                              }{" "}
                              at{" "}
                              {flightPriceReturn?.onwayInfo[flightPriceReturn?.onwayInfo?.length - 1]?.ArrivalTime}{" "}
                              on{" "}
                              {flightPriceReturn?.onwayInfo[flightPriceReturn?.onwayInfo?.length - 1]?.ArrivalDate
                              }
                            </li>
                            <li>
                              {flightPriceReturn?.onwayInfo?.length === 1
                                ? "Direct Flight"
                                : flightPriceReturn?.onwayInfo?.map(
                                    (stop, index) => (
                                      <div key={index}>
                                        {index <
                                          flightPriceReturn
                                            ?.onwayInfo?.length -
                                            1 && `Stop ${index + 1}:`}{" "}
                                        {index < flightPriceReturn.onwayInfo.length - 1 &&
                                          ` at ${flightPriceReturn?.onwayInfo[index]?.ArrivalAirportLocation}.`}
                                      </div>
                                    )
                                  )}
                            </li>
                            <li>
                              Journey Time :{" "}
                              {flightPriceReturn?.JourneyDuration}
                            </li>
                            <li>
                              {flightPriceReturn?.onwayInfo[0]?.OperatingCarrieName}{" "}
                            </li>
                          </ul>
                        </div>
                      )}
                  </div>
                  <div className="vacationSummary__row__col1__box__priceMain">
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {this.state.addFlight === "Yes" &&
                        this.state.addFlightReturn === "Yes"
                          ? showPriceValue(
                              parseFloat(this.state.totalFlightPrice || 0) +
                                parseFloat(
                                  this.state.totalFlightPriceReturn || 0
                                ),
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )
                          : this.state.addFlight === "Yes"
                          ? showPriceValue(
                              parseFloat(this.state.totalFlightPrice || 0),
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )
                          : this.state.addFlightReturn === "Yes"
                          ? showPriceValue(
                              parseFloat(this.state.totalFlightPriceReturn || 0),
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )
                          : null}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                </div>
              )}
              {this.state.selectedRoom?.length > 0 && (
                <div className="vacationSummary__row__col1__box">
                  <div className="vacationSummary__row__col1__box__row1">
                    <div className="vacationSummary__row__col1__box__row1__title">
                      Your stay for the vacation{" "}
                      {this.state.selectedRoom?.length > 1 ? (
                        <span> Multi Stay</span>
                      ) : (
                        <span>Single Stay</span>
                      )}
                    </div>
                    <div
                      className="vacationSummary__row__col1__box__row1__edit"
                      onClick={this.editPackage.bind(this)}
                    >
                      <img src={editImg} />
                      Edit
                    </div>
                  </div>
                  {this.state.selectedHotel &&
                    this.state.selectedRoom.length > 0 && (
                      <div className="vacationSummary__row__col1__box__row2">
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Stay information{" "}
                          </div>
                          <ul>
                            <li>{this.state.selectedHotel?.hotel_name}.</li>
                            <li>{this.state.selectedHotel?.address}.</li>
                            <li>
                              {this.state.stayList.map((item, index) => {
                                return (
                                  this.state.selectedRoom.length > 0 &&
                                  this.state.selectedHotelID ===
                                    item.hotel_id && (
                                    <div key={index}>
                                      <div>
                                        {this.state.selectedRoom.map(
                                          (roomItem, roomIndex) => {
                                            // Ensure room_info exists before accessing indexes
                                            if (
                                              !this.state.selectedHotel
                                                .room_info ||
                                              !this.state.selectedHotel
                                                .room_info[
                                                roomItem.roomMainIndex
                                              ]
                                            ) {
                                              return null;
                                            }

                                            let selectedRoomType =
                                              this.state.selectedHotel
                                                .room_info[
                                                roomItem.roomMainIndex
                                              ][roomItem.roomIndex];

                                            // Ensure room_charges exists before accessing indexes
                                            if (
                                              !selectedRoomType.room_charges
                                            ) {
                                              return null;
                                            }

                                            let selectedRoomRate =
                                              selectedRoomType.room_charges[
                                                roomItem.priceIndex
                                              ];

                                            return (
                                              <div key={roomIndex}>
                                                {this.state.selectedRoom
                                                  .length === 1
                                                  ? "Room Type"
                                                  : "Room"}{" "}
                                                {this.state.selectedRoom
                                                  .length > 1 &&
                                                  `${
                                                    parseInt(roomIndex) + 1
                                                  }: `}
                                                {
                                                  selectedRoomType.room_type_name
                                                }{" "}
                                                -{" "}
                                                {
                                                  selectedRoomRate.rate_description
                                                }
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )
                                );
                              })}
                            </li>
                            <li>
                              Check In at{" "}
                              {this.state.selectedHotel?.hotel_check_in} on{" "}
                              {new Date(
                                this.state.stayStartDate
                              ).toLocaleDateString("en-IN", {
                                day: "numeric",
                                month: "short",
                                year: "2-digit",
                              })}{" "}
                            </li>
                            <li>
                              Check Out at{" "}
                              {this.state.selectedHotel?.hotel_check_out} on{" "}
                              {new Date(
                                this.state.searchdata?.end_date
                              ).toLocaleDateString("en-IN", {
                                day: "numeric",
                                month: "short",
                                year: "2-digit",
                              })}{" "}
                            </li>
                          </ul>
                        </div>
                        {this.state.selectedHotel?.hotel_amenities && (
                          <div className="vacationSummary__row__col1__box__row2__col">
                            <div className="vacationSummary__row__col1__box__row2__col__title">
                              Amenities{" "}
                            </div>
                            <ul>
                              {this.state.selectedHotel?.hotel_amenities
                                ?.split("#@#")
                                .slice(0, 5)
                                .map((amenity, index) => (
                                  <li key={index}>{amenity}</li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  <div className="vacationSummary__row__col1__box__priceMain">
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.totalHotelPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                </div>
              )}
              {this.state.totalActvitiesPrice > 0 && (
                <div className="vacationSummary__row__col1__box">
                  <div className="vacationSummary__row__col1__box__row1">
                    <div className="vacationSummary__row__col1__box__row1__title">
                      Your vacation plan{" "}
                      <span>{this.state.activities_count} Things to do</span>
                    </div>
                    <div
                      className="vacationSummary__row__col1__box__row1__edit"
                      onClick={this.editPackage.bind(this)}
                    >
                      <img src={editImg} />
                      Edit
                    </div>
                  </div>
                  <div className="vacationSummary__row__col1__box__row2">
                    {this.state.activities.map((item, index) =>
                      item.activities.map((activity, timeindex) => {
                        if (activity !== "") {
                          var distance_ = activity?.distance ?? "";
                          // Adjust activity date dynamically for next-day flight
                          let adjustedDate = new Date(item.date);
                          let originalDate = new Date(
                            this.state.searchdata.start_date
                          );

                          if (flightPrice.onwayInfo) {
                            let totalOnway = flightPrice.onwayInfo.length;
                            let arrivalDateTime = new Date(flightPrice.onwayInfo[totalOnway - 1].ArrivalDateTime);

                            let flightArrivalDay = arrivalDateTime;
                            let tourStartDay = originalDate;
                            if (flightArrivalDay > tourStartDay) {
                              // Shift activity dates after flight arrival
                              adjustedDate = add(adjustedDate, { days: 1 });
                            }
                          }
                          return (
                            <div
                              className={`vacationSummary__row__col1__box__row2__col ${
                                timeindex !== item.activities?.length - 1 &&
                                "marginBtm"
                              }`}
                              key={timeindex}
                            >
                              <React.Fragment key={timeindex}>
                                <div className="vacationSummary__row__col1__box__row2__col__title">
                                  Day {item.day} -{" "}
                                  {new Date(adjustedDate).toLocaleDateString(
                                    "en-IN",
                                    {
                                      day: "numeric",
                                      month: "short",
                                      year: "2-digit",
                                    }
                                  )}{" "}
                                  -{timeindex === 0 && <span>Morning</span>}
                                  {timeindex === 1 && <span>Afternoon</span>}
                                  {timeindex === 2 && <span>Evening</span>}
                                </div>
                                <ul>
                                  {activity.package_name &&
                                    activity.products_name && (
                                      <li>
                                        {activity?.products_name}-
                                        {activity?.package_name}
                                      </li>
                                    )}
                                  {distance_ !== "" && distance_ !== null && (
                                    <li>
                                      Distance from stay is{" "}
                                      {parseFloat(distance_).toFixed("2")} km
                                    </li>
                                  )}
                                  {activity.guided && (
                                    <li>Guided : {activity?.guided}</li>
                                  )}
                                  {activity.ttd_duration && (
                                    <li>Duration : {activity?.ttd_duration}</li>
                                  )}
                                </ul>
                              </React.Fragment>
                            </div>
                          );
                        }
                      })
                    )}
                  </div>
                  <div className="vacationSummary__row__col1__box__priceMain">
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.totalActvitiesPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                </div>
              )}
              {this.state.addTransfer === "Yes" && (
                <div className="vacationSummary__row__col1__box">
                  <div className="vacationSummary__row__col1__box__row1">
                    <div className="vacationSummary__row__col1__box__row1__title">
                      Your airport transfer{" "}
                      <span>
                        {flightPrice?.onwayInfo?.length > 0 &&
                        this.state.addTransfer === "Yes"
                          ? "Airport transfer round trip"
                          : "One Way"}
                      </span>
                    </div>
                    <div
                      className="vacationSummary__row__col1__box__row1__edit"
                      onClick={this.editPackage.bind(this)}
                    >
                      <img src={editImg} />
                      Edit
                    </div>
                  </div>
                  <div className="vacationSummary__row__col1__box__row2">
                    <div className="vacationSummary__row__col1__box__row2__col">
                      <div className="vacationSummary__row__col1__box__row2__col__title">
                        Onward information
                      </div>
                      <ul>
                        <li>
                          Pick up at{" "}
                          {flightPrice?.onwayInfo[flightPrice?.onwayInfo?.length - 1]?.ArrivalAirportLocation}{" "}
                          at{" "}
                          {flightPrice?.onwayInfo[flightPrice?.onwayInfo?.length - 1]?.ArrivalTime}{" "}
                          on{" "}
                          {flightPrice?.onwayInfo[flightPrice?.onwayInfo?.length - 1]?.ArrivalDate}
                        </li>
                        <li>
                          Drop off at {this.state.selectedHotel?.hotel_name} at{" "}
                          {this.state.finalDropoffTime1} on{" "}
                          {this.state.transferdate1}
                        </li>
                        <li>
                          {
                            this.state.transferList[this.state.selectedTransfer]
                              ?.vehicle
                          }{" "}
                          x vehicle
                        </li>
                        <li>
                          Estimated journey{" "}
                          {
                            this.state.transferList[this.state.selectedTransfer]
                              ?.duration_min
                          }{" "}
                          min
                        </li>
                        <li>
                          Estimated distance{" "}
                          {
                            this.state.transferList[this.state.selectedTransfer]
                              ?.distance_km
                          }{" "}
                          km
                        </li>
                      </ul>
                    </div>
                    {this.state.addTransferReturn === "Yes" && (
                      <div className="vacationSummary__row__col1__box__row2__col">
                        <div className="vacationSummary__row__col1__box__row2__col__title">
                          Return information
                        </div>
                        <ul>
                          <li>
                            Pick up at {this.state.selectedHotel?.hotel_name} at{" "}
                            {this.state.reserveTransferReturn?.pickuptime_for_journey1?.slice(
                              0,
                              2
                            )}
                            :
                            {this.state.reserveTransferReturn?.pickuptime_for_journey1?.slice(
                              2
                            )}{" "}
                            on {this.state.transferdate2}
                          </li>
                          <li>
                            Drop off at{" "}
                            {flightPrice.onwayInfo[flightPrice.onwayInfo.length - 1]?.ArrivalAirportLocation}
                            at {this.state.finalDropoffTime2} on{" "}
                            {this.state.transferdate2}
                          </li>
                          <li>
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransferReturn
                              ]?.vehicle
                            }{" "}
                            vehicle
                          </li>
                          <li>
                            Estimated journey{" "}
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransferReturn
                              ]?.duration_min
                            }{" "}
                            min
                          </li>
                          <li>
                            Estimated distance{" "}
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransferReturn
                              ]?.distance_km
                            }{" "}
                            km
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="vacationSummary__row__col1__box__priceMain">
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          parseFloat(this.state.transferPrice) +
                            parseFloat(this.state.transferPriceReturn),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                </div>
              )}

              {/*  {this.state.addTransferReturn === "Yes" && (
                  <div className="vacationSummary__row__col1__box">
                    <div className="vacationSummary__row__col1__box__row1">
                      <div className="vacationSummary__row__col1__box__row1__title">
                        Your airport transfer{" "}
                        <span>
                          {this.state.addTransferReturn === "Yes"
                            ? "Airport transfer return trip"
                            : "One Way"}
                        </span>
                      </div>
                      <div
                        className="vacationSummary__row__col1__box__row1__edit"
                        onClick={this.editPackage.bind(this)}
                      >
                        <EditIcon /> Edit
                      </div>
                    </div>
                    <div className="vacationSummary__row__col1__box__row2">
                      <div className="vacationSummary__row__col1__box__row2__col">
                        <div className="vacationSummary__row__col1__box__row2__col__title">
                          Return information
                        </div>
                        <ul>
                          <li>
                            Pick up at{" "}
                            {
                              this.state.flightPrice.onwayInfo[
                                this.state.flightPrice.onwayInfo.length - 1
                              ]?.ArrivalAirportLocation
                            }{" "}
                            at{" "}
                            {
                              this.state.flightPrice?.onwayInfo[
                                this.state.flightPrice?.onwayInfo?.length - 1
                              ]?.ArrivalTime
                            }{" "}
                            on{" "}
                            {
                              this.state.flightPrice?.onwayInfo[
                                this.state.flightPrice?.onwayInfo?.length - 1
                              ]?.ArrivalDate
                            }
                          </li>
                          <li>
                            Drop off at {this.state.selectedHotel?.hotel_name}{" "}
                            at {this.state.finalDropoffTime1} on{" "}
                            {this.state.transferdate1}
                          </li>
                          <li>
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransfer
                              ]?.vehicle
                            }{" "}
                            vehicle
                          </li>
                          <li>
                            Estimated journey{" "}
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransfer
                              ]?.duration_min
                            }{" "}
                            min
                          </li>
                          <li>
                            Estimated distance{" "}
                            {
                              this.state.transferListReturn[
                                this.state.selectedTransfer
                              ]?.distance_km
                            }{" "}
                            km
                          </li>
                        </ul>
                      </div>
                      {this.state.flightPrice.returnInfo.length > 0 && (
                        <div className="vacationSummary__row__col1__box__row2__col">
                          <div className="vacationSummary__row__col1__box__row2__col__title">
                            Return information
                          </div>
                          <ul>
                            <li>
                              Pick up at {this.state.selectedHotel?.hotel_name}{" "}
                              at{" "}
                              {this.state.reserveTransfer?.pickuptime_for_journey2?.slice(
                                0,
                                2
                              )}
                              :
                              {this.state.reserveTransfer?.pickuptime_for_journey2?.slice(
                                2
                              )}{" "}
                              on {this.state.transferdate2}
                            </li>
                            <li>
                              Drop off at{" "}
                              {
                                this.state.flightPrice.onwayInfo[
                                  this.state.flightPrice.onwayInfo.length - 1
                                ]?.ArrivalAirportLocation
                              }{" "}
                              at {this.state.finalDropoffTime2} on{" "}
                              {this.state.transferdate2}
                            </li>
                            <li>
                              {
                                this.state.transferListReturn[
                                  this.state.selectedTransfer
                                ]?.vehicle
                              }{" "}
                              vehicle
                            </li>
                            <li>
                              Estimated journey{" "}
                              {
                                this.state.transferListReturn[
                                  this.state.selectedTransfer
                                ]?.duration_min
                              }{" "}
                              min
                            </li>
                            <li>
                              Estimated distance{" "}
                              {
                                this.state.transferListReturn[
                                  this.state.selectedTransfer
                                ]?.distance_km
                              }{" "}
                              km
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="vacationSummary__row__col1__box__price">
                      Total{" "}
                      <span>
                        {showPriceValue(
                          this.state.transferPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </span>
                    </div>
                    <div className="vacationSummary__row__col1__box__info">
                      <InfoIcon />
                      Please read the package information before booking.
                    </div>
                  </div>
                )} */}
            </div>
            {!this.state.isMobile && (
              <div className="vacationSummary__row__col2">
                <div className="vacationSummary__row__col2__box">
                  <div className="vacationSummary__row__col2__box__title">
                    Summary{" "}
                  </div>
                  <div className="vacationSummary__row__col2__box__content">
                    <div className="vacationSummary__row__col2__box__content__row">
                      <div className="vacationSummary__row__col2__box__content__row__col1">
                        <img src={ProfileIcon} alt="flight" />
                        <div className="vacationSummary__row__col2__box__content__row__col1__label">
                          Persons
                        </div>
                      </div>
                      <div className="vacationSummary__row__col2__box__content__row__col2">
                        <div className="vacationSummary__row__col2__box__content__row__col2__val">
                          <span>
                            {this.state.searchdata.adults} Adult{" "}
                            {this.state.searchdata.child > 0 && (
                              <>{this.state.searchdata.child} Child</>
                            )}
                            {this.state.searchdata.infant > 0 && (
                              <> {this.state.searchdata.infant} Infant </>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="vacationSummary__row__col2__box__content__row">
                      <div className="vacationSummary__row__col2__box__content__row__col1">
                        <img src={CalendarIcon} alt="flight" />
                        <div className="vacationSummary__row__col2__box__content__row__col1__label">
                          Dates
                        </div>
                      </div>
                      <div className="vacationSummary__row__col2__box__content__row__col2">
                        <div className="vacationSummary__row__col2__box__content__row__col2__val">
                          <span>
                            {" "}
                            {new Date(
                              this.state.searchdata.start_date
                            ).toLocaleDateString("en-IN", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}{" "}
                            to{" "}
                            {new Date(this.state.end_date).toLocaleDateString(
                              "en-IN",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="vacationSummary__row__col2__box__content__row">
                      <div className="vacationSummary__row__col2__box__content__row__col1">
                        <img src={MoonIcon} alt="flight" />
                        <div className="vacationSummary__row__col2__box__content__row__col1__label">
                          Days
                        </div>
                      </div>
                      <div className="vacationSummary__row__col2__box__content__row__col2">
                        <div className="vacationSummary__row__col2__box__content__row__col2__val">
                          <span>{this.state.activities.length} Day Trip</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vacationSummary__row__col2__box__divider"></div>
                  <div className="vacationSummary__row__col2__box__title">
                    Itineraries Total
                  </div>
                  <div className="vacationSummary__row__col2__box__content">
                    {this.state.totalFlightPrice > 0 &&
                      this.state.addFlight === "Yes" && (
                        <div className="vacationSummary__row__col2__box__content__row">
                          <div className="vacationSummary__row__col2__box__content__row__col1">
                            <img src={FlightIcon} alt="flight" />
                            <div className="vacationSummary__row__col2__box__content__row__col1__label">
                              Onward Flight fee
                            </div>
                          </div>
                          <div className="vacationSummary__row__col2__box__content__row__col2">
                            <div className="vacationSummary__row__col2__box__content__row__col2__val">
                              {showPriceValue(
                                this.state.totalFlightPrice,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.totalFlightPriceReturn > 0 &&
                      this.state.addFlightReturn === "Yes" && (
                        <div className="vacationSummary__row__col2__box__content__row">
                          <div className="vacationSummary__row__col2__box__content__row__col1">
                            <img src={FlightIcon} alt="flight" />
                            <div className="vacationSummary__row__col2__box__content__row__col1__label">
                              Return Flight fee
                            </div>
                          </div>
                          <div className="vacationSummary__row__col2__box__content__row__col2">
                            <div className="vacationSummary__row__col2__box__content__row__col2__val">
                              {showPriceValue(
                                this.state.totalFlightPriceReturn,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {this.state.totalHotelPrice > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={BedIcon} alt="flight" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label">
                            Hotel fee
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__val">
                            {showPriceValue(
                              this.state.totalHotelPrice,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.transferPrice > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={CarIcon} alt="car" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label">
                            Onwards Transfer fee
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__val">
                            {showPriceValue(
                              this.state.transferPrice,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.transferPriceReturn > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={CarIcon} alt="car" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label">
                            Return Transfer fee
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__val">
                            {showPriceValue(
                              this.state.transferPriceReturn,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.totalActvitiesPrice > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={TicketIcon} alt="ticket" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label">
                            Things to do fee
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__val">
                            {showPriceValue(
                              this.state.totalActvitiesPrice,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.packageDetails?.creator_package_source ===
                      "uop" &&
                      this.state.uopPricing !== "" && (
                        <>
                          {this.state.uopPricing?.ticketDetails &&
                            this.state?.uopPricing?.ticketDetails?.map(
                              (item, index) => {
                                if (item?.selected === true) {
                                  return (
                                    <div
                                      className="vacationSummary__row__col2__box__content__row"
                                      key={index}
                                    >
                                      <div className="vacationSummary__row__col2__box__content__row__col1">
                                        <div className="vacationSummary__row__col2__box__content__row__col1__label">
                                          {item.ticketType}{" "}
                                        </div>
                                      </div>
                                      <div className="vacationSummary__row__col2__box__content__row__col2">
                                        <div className="vacationSummary__row__col2__box__content__row__col2__val">
                                          {showPriceValue(
                                            item.totalPrice,
                                            this.state.currentCurrency,
                                            this.state.currencyRate
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )}

                          {this.state.uopPricing?.addOns &&
                            this.state.uopPricing?.addOns.map((item, index) => {
                              if (item?.selected === true) {
                                var price =
                                  this.state.selectedseason === "peak"
                                    ? item.peakPrice
                                    : item.nonPeakPrice;

                                return (
                                  <div
                                    className="vacationSummary__row__col2__box__content__row"
                                    key={index}
                                  >
                                    <div className="vacationSummary__row__col2__box__content__row__col1">
                                      <div className="vacationSummary__row__col2__box__content__row__col1__label">
                                        {item.title}
                                      </div>
                                    </div>
                                    <div className="vacationSummary__row__col2__box__content__row__col2">
                                      <div className="vacationSummary__row__col2__box__content__row__col2__val">
                                        {showPriceValue(
                                          price,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}

                          {this.state.uopPricing?.type === "standard" && (
                            <div className="vacationSummary__row__col2__box__content__row">
                              <div className="vacationSummary__row__col2__box__content__row__col1">
                                <div className="vacationSummary__row__col2__box__content__row__col1__label">
                                  Pacakge Fee
                                </div>
                              </div>
                              <div className="vacationSummary__row__col2__box__content__row__col2">
                                <div className="vacationSummary__row__col2__box__content__row__col2__val">
                                  {showPriceValue(
                                    this.state.packageFee,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    {parseFloat(this.state.platformFee) > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={GlobalIcon} alt="global" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label">
                            Platform fee
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__val">
                            {showPriceValue(
                              this.state.platformFee,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="vacationSummary__row__col2__box__divider"></div>
                  <div className="vacationSummary__row__col2__box__content">
                    {discountDetails !== "" && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <img src={DiscountIcon} alt="discount" />
                          <div className="vacationSummary__row__col2__box__content__row__col1__label2">
                            {discountDetails.title}
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__discount">
                            -
                            {showPriceValue(
                              discountDetails.amount,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {parseFloat(this.state.grandTotal) > 0 && (
                      <div className="vacationSummary__row__col2__box__content__row">
                        <div className="vacationSummary__row__col2__box__content__row__col1">
                          <div className="vacationSummary__row__col2__box__content__row__col1__label1">
                            Total
                          </div>
                        </div>
                        <div className="vacationSummary__row__col2__box__content__row__col2">
                          <div className="vacationSummary__row__col2__box__content__row__col2__price">
                            {showPriceValue(
                              this.state.grandTotal,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    className="vacationSummary__row__col2__box__content__checkout-btn"
                    onClick={() => this.continuebooknow()}
                  >
                    Checkout here
                  </Button>
                </div>
                <AssistanceCard
                  handleAssistancePopup={this.handleAssistancePopup.bind(this)}
                />
              </div>
            )}{" "}
            <ModalPopup
              open={this.state.showAssistanceCard}
              handleClose={() => this.setState({ showAssistanceCard: false })}
            >
              <AssistanceCard
                handleAssistancePopup={this.handleAssistancePopup.bind(this)}
              />
            </ModalPopup>
            <AssistancePopup
              showAssistancePopup={this.state.showAssistancePopup}
              handleClose={() => this.setState({ showAssistancePopup: "" })}
              isMobile={this.state.isMobile}
              searchdata={this.state.searchdata}
              packageSlug={this.state.packageSlug}
              creatorPackageID={this.state.packageDetails.creator_package_id}
            />
          </div>
        </div>
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row asst-main">
                {this.state.packageDetails?.creator_package_source !==
                  "uop" && (
                  <div
                    className="asst-cntnr"
                    onClick={() => this.setState({ showAssistanceCard: true })}
                  >
                    <div className="asst-row">
                      <AvatarGroup>
                        <Avatar
                          alt="Gowtham"
                          src="https://images.fizota.com/Gowtham.webp"
                        />
                        <Avatar
                          alt="Bose"
                          src="https://images.fizota.com/Bose.webp"
                        />
                      </AvatarGroup>
                      <span>Chat, Callback and Free! 1 on 1 Support</span>
                      <img src={AssetChevronRight} alt="AssetChevronRight" />
                    </div>
                  </div>
                )}
                <div className="col-1">
                  <div className="title">
                    Total package <span>fare</span>
                  </div>
                  {parseFloat(this.state.grandTotal) > 0 && (
                    <div className="price">
                      {" "}
                      {showPriceValue(
                        this.state.grandTotal,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </div>
                  )}
                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>{" "}
                </div>
                <div className="col-2">
                  <Button
                    className={"action"}
                    onClick={this.continuebooknow.bind(this)}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              {this.state.totalFlightPrice > 0 &&
                this.state.addFlight === "Yes" && (
                  <li>
                    <span className="service-content">
                      <strong>Onward Flight fee</strong>
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          this.state.totalFlightPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </li>
                )}
              {this.state.totalFlightPriceReturn > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Return Flight fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalFlightPriceReturn,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.totalHotelPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Hotel fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.totalHotelPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.transferPrice > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Onwards Transfer fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.transferPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.transferPriceReturn > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Return Transfer fee</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.transferPriceReturn,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </strong>
                  </span>
                </li>
              )}
              {this.state.totalActivity > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Things to do fee</strong>
                  </span>
                  {this.state.totalActvitiesPrice > 0 && (
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          this.state.totalActvitiesPrice,
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  )}
                </li>
              )}
              {parseFloat(this.state.platformFee) > 0 && (
                <ul className="sub-taxes">
                  <li>
                    <span className="service-content">Platform fee</span>
                    <span className="service-amount">
                      {showPriceValue(
                        this.state.platformFee,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </span>
                  </li>
                </ul>
              )}
              {/* {parseFloat(this.state.grandTotal) > 0 && (
                          <li>
                            <span className="service-content">Total</span>
                            <span className="service-amount">
                              {showPriceValue(
                                this.state.grandTotal,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </span>
                          </li>
                        )} */}
            </ul>
            <Divider />
            {parseFloat(this.state.grandTotal) > 0 && (
              <div className="details">
                <div className="title">
                  Total <span>fare</span>
                </div>
                <div className="col-2">
                  <div className="price">
                    {showPriceValue(
                      this.state?.grandTotal,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="action">
              <Button
                className={"btn"}
                onClick={() => {
                  this.setState({ showFareBreakdown: false });
                  this.continuebooknow();
                }}
              >
                Checkout{" "}
              </Button>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

export default VacationSummary;
