/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "react-light-accordion/demo/css/index.css";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import EditIcon from "@mui/icons-material/Edit";

import { Calendar } from "react-date-range";
import "react-calendar/dist/Calendar.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { apiUrl, mediaUrl, apiglobaltixMediaUrl } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  jsUcfirstFun,
  calculateAircoins,
  showPriceValue,
} from "../Helpers/SettingHelper";
import {
  dollar,
  mpin,
  loader,
  placeholder,
  closeicon,
  instant_confirmation,
  calday,
} from "../Helpers/Images";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_PRODUCTDETAILS } from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");
import { format } from "date-fns";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import moment from "moment";
import { Button } from "@mui/material";
import ActivityDetail from "../Customerpackage/CustomerPackageDetails/ActivityDetail/ActivityDetail";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AlertPopup from "../../common/components/AlertPopup/AlertPopup";
import PlusIcon from "../../common/images/Plus_Active.svg";
import MinusIcon from "../../common/images/Minus_Active.svg";

var settingsgallery = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
};
var mobilesteps = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  autoplay: true,
};
class Productdetail extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";
    var existpackage = [];
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      existpackage =
        this.props.location.state.selectedpackage !== "" &&
        typeof this.props.location.state.selectedpackage !== undefined &&
        typeof this.props.location.state.selectedpackage !== "undefined"
          ? this.props.location.state.selectedpackage
          : [];
    }

    var dayno = "";
    if (
      this.props.location.state.day !== "" &&
      this.props.location.state.day !== undefined &&
      this.props.location.state.day !== "undefined"
    ) {
      dayno = this.props.location.state.day;
    }
    var ttdOnly = false;
    if (
      this.props.location.state?.searchdata?.ttdOnly !== "" &&
      this.props.location.state?.searchdata?.ttdOnly !== undefined &&
      this.props.location.state?.searchdata?.ttdOnly !== "undefined"
    ) {
      ttdOnly = this.props.location.state.searchdata.ttdOnly;
    }
    var ttdCity = "";
    var product_name = "";
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== undefined &&
      this.props.location.state !== "undefined"
    ) {
      ttdCity = this.props.location.state.ttdCity;
      product_name = this.props.location.state.product_name;
    }

    var activitiyIndex = "";
    if (
      this.props.location.state.activitiyIndex !== "" &&
      this.props.location.state.activitiyIndex !== undefined &&
      this.props.location.state.activitiyIndex !== "undefined"
    ) {
      activitiyIndex = this.props.location.state.activitiyIndex;
    }

    var dayIndex = 1;
    if (
      this.props.location.state.dayIndex !== "" &&
      this.props.location.state.dayIndex !== undefined &&
      this.props.location.state.dayIndex !== "undefined"
    ) {
      dayIndex = this.props.location.state.dayIndex;
    }

    var searchdata = "";

    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
    } else {
      this.props.history.push("/");
    }

    let adults = this.props.location.state.searchdata.ttdOnly ? 0 : 1;
    let child = 0;
    let infant = 0;
    if (this.props?.location?.state?.searchdata?.adults !== undefined) {
      adults = this.props?.location?.state?.searchdata?.adults;
      child = this.props?.location?.state?.searchdata?.child;
      infant = this.props?.location?.state?.searchdata?.infant;
    }

    var packageSlug = "";
    if (
      this.props.match.params.packageSlug !== "" &&
      typeof this.props.match.params.packageSlug !== undefined &&
      typeof this.props.match.params.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.match.params.packageSlug;
    } else {
      this.props.history.push("/");
    }

    var activities = "";
    if (
      this.props.location.state.activities !== "" &&
      typeof this.props.location.state.activities !== undefined &&
      typeof this.props.location.state.activities !== "undefined"
    ) {
      activities = this.props.location.state.activities;
    }

    var selectedDate = "";
    if (
      this.props.location.state.selectedDay !== "" &&
      typeof this.props.location.state.selectedDay !== undefined &&
      typeof this.props.location.state.selectedDay !== "undefined"
    ) {
      selectedDate = this.props.location.state.selectedDay;
    } else {
      var date = new Date();
      date.setDate(date.getDate());
      var formattedDate = date.toISOString().split("T")[0];
      selectedDate = formattedDate;
    }
    this.state = {
      userID: userID,
      existpackage: existpackage,

      activitiyIndex: activitiyIndex,
      dayIndex: dayIndex,
      selectedDate: selectedDate,

      searchdata: searchdata,

      packageSlug: packageSlug,
      activities: activities,
      booking_date: new Date(),
      productdetail: "",
      totalPrice: 0,
      selectedTicketType: [],
      selectedpackage: "",
      // selectedpackageId: "",
      showspersion: "",
      displaypersion: "",
      mindate: new Date(),
      includeDates: [],
      maxdate: "",
      triggerLogin: false,
      returnloginresult: false,
      errormsg: "",
      timeList: "",
      displyaTimeSlot: [],
      eventTime: "",
      loadTimeSlot: false,
      showTimeError: false,
      rating_categories: [],
      writeReview: false,
      ratingCategory: "",
      reatingMessage: "",
      error_rating: "",
      error_ratingCategory: "",
      error_reatingMessage: "",
      allimages: "",
      allgalleryimages: "",
      slidergalleryimages: "",
      enableDateWaring: false,
      durationdate: 0,
      product_slug: this.props.match.params.productslug,
      pouppackage: "",
      poupproduct: "",
      showdateError: "",
      isMobile: window.innerWidth <= 480,
      priceQuantity: [],
      packActivityList: [],
      timeID: "",
      activityItem: [],
      activityIndex: null,
      showSelectInfo: false,
      maxVisibleSlots: 5,
      total_activities: searchdata.total_activities,
      ttdOnly: ttdOnly,
      continuestep: false,
      ttdCity: ttdCity,
      product_name: product_name,
      adults: adults,
      child: child,
      infant: infant,
      totalAdults: adults,
      totalChild: child,
      childAgeList: [],
      availabilityDates: [],
      selectedItem: [],
      passengerOptions: [],
      selectedIndex: 0,
      isMobile: window.innerWidth <= 480,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      selectedPackageId: "",
      isDateSelected: false,
      isTimeSelected: false,
      showSlotList: false,
      globalSetting: [],
      onlyGuest: false,
    };

    this.lightboxCatRef = React.createRef();
    const originalDate = selectedDate;
    const parsedDate = new Date(originalDate);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");

    const mobileDate = `${year}-${month}-${day}`;
    // console.log(mobileDate);
    var params = {
      product_slug: this.props.match.params.productslug,
      userID: userID,
      booking_date: this.state.isMobile ? mobileDate : selectedDate,
    };
    if (
      this.props.location.state.selectedHotel !== "" &&
      typeof this.props.location.state.selectedHotel !== undefined &&
      typeof this.props.location.state.selectedHotel !== "undefined"
    ) {
      var selectedHotel = this.props.location.state.selectedHotel;
      params["latitude"] = selectedHotel.latitude;
      params["longitude"] = selectedHotel.longitude;
    }
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.props.getProductDetails(params);
    this.loadReviewCategories();
    this.calculatePrice();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.productdetail !== this.state.productdetail) {
      this.setState(
        (prevState) => {
          const selectedItem =
            prevState.selectedPackageId && prevState.selectedPackageId !== ""
              ? PropsData.productdetail?.product_package?.find(
                  (item) => item.package_id === prevState.selectedPackageId
                )
              : this.state.selectedItem;

          return {
            productdetail: PropsData.productdetail,
            selectedItem: selectedItem,
          };
        },
        function () {
          this.loadAllImages();
        }
      );
    }
    if (
      this.state.product_slug !== "" &&
      this.state.product_slug !== PropsData.match.params.productslug
    ) {
      this.setState(
        { product_slug: PropsData.match.params.productslug },
        function () {
          var params = {
            product_slug: this.props.match.params.productslug,
            userID: this.state.userID,
            booking_date: selectedDate,
          };
          if (
            this.props.location.state.selectedHotel !== "" &&
            typeof this.props.location.state.selectedHotel !== undefined &&
            typeof this.props.location.state.selectedHotel !== "undefined"
          ) {
            var selectedHotel = this.props.location.state.selectedHotel;
            params["latitude"] = selectedHotel.latitude;
            params["longitude"] = selectedHotel.longitude;
          }
          this.handleChangeDate = this.handleChangeDate.bind(this);
          this.props.getProductDetails(params);
        }
      );
    }
    if (this.state.globalSetting !== PropsData.globalSetting) {
      var globalSetting = PropsData.globalSetting;
      this.setState({
        globalSetting: PropsData.globalSetting,
        currencyRate: globalSetting?.setting_currency || {},
      });
    }
  }
  loadReviewCategories() {
    axios
      .get(apiUrl + "products/getReviewCategories", {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var rating_categories = [];
          if (res.data.result_set.length > 0) {
            res.data.result_set.map((item) => {
              rating_categories.push({
                value: item.review_categor_id,
                label: item.review_category_title,
              });
            });
          }
          this.setState({ rating_categories: rating_categories });
        }
      });
  }

  calculatePrice() {
    var totalPrice = 0;
    if (Object.keys(this.state.selectedTicketType).length > 0) {
      this.state.selectedTicketType.map((item) => {
        totalPrice += parseFloat(item.package_pricenettPrice) * item.quantity;
      });
    }

    var grandTotal = parseFloat(totalPrice);
    this.setState({ totalPrice: totalPrice, grandTotal: grandTotal });
    var adults =
      this.state.searchdata.adults !== "" &&
      typeof this.state.searchdata.adults !== undefined &&
      typeof this.state.searchdata.adults !== "undefined"
        ? this.state.searchdata.adults
        : "";
    var child =
      this.state.searchdata.child !== "" &&
      typeof this.state.searchdata.child !== undefined &&
      typeof this.state.searchdata.child !== "undefined"
        ? this.state.searchdata.child
        : 0;

    this.setState({
      adults: adults,
      child: child,
    });
  }
  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  selectPackage(poupproduct, selectitem, field, value) {
    try {
      const { ttdOnly, selectedDate, ttdCity, adults } = this.state;
      if (this.state.isMobile) {
        this.setState({ showSelectInfo: false });
      }
      if (selectitem.dateAvailability.availability === "Yes") {
        var totalPasanger = 0;
        if (selectitem.package_price.length > 0) {
          selectitem.package_price.map((item) => {
            if (
              item.quantity !== "" &&
              typeof item.quantity !== undefined &&
              typeof item.quantity !== "undefined"
            ) {
              if (parseInt(item.quantity) > 0) {
                totalPasanger++;
              }
            }
          });
        }
        if (totalPasanger === 0) {
          // showAlert("Error", "Please Select atleast one pasanger");
          // $.magnificPopup.open({
          //   items: {
          //     src: ".alert_popup",
          //   },
          //   type: "inline",
          // });
          this.setState({
            openAlertPopup: true,
            alertPopupMsg: "Please Select atleast one pasanger",
            popupTitle: "Alert",
          });
          return false;
        }
        var activity_slot_list = selectitem.dateAvailability.slotList;
        var selectedTime =
          selectitem.selected_slot !== "" &&
          typeof selectitem.selected_slot !== undefined &&
          typeof selectitem.selected_slot !== "undefined"
            ? selectitem.selected_slot
            : [];
        if (activity_slot_list.length > 0 && selectedTime.length === 0) {
          // showAlert("Error", "Please Select Time Slot");
          // $.magnificPopup.open({
          //   items: {
          //     src: ".alert_popup",
          //   },
          //   type: "inline",
          // });
          this.setState({
            openAlertPopup: true,
            alertPopupMsg: "Please Select Time Slot",
            popupTitle: "Alert",
          });
          return false;
        }

        var newactivities = [];
        var dummyActivity = [
          {
            day: 1,
            date: selectedDate,
            emptyactivity: "Yes",
            activities: [],
          },
        ];
        var activityDetails =
          // ttdOnly ? dummyActivity :
          this.state.activities;
        activityDetails?.map((item) => {
          if (this.state.dayIndex === item.day) {
            var activitiyList = [];
            if (item.activities.length > 0) {
              item.activities.map((actItem, actIndex) => {
                if (actIndex === this.state.activitiyIndex) {
                  var ticketType = [];
                  if (selectitem.package_price.length > 0) {
                    selectitem.package_price.map((packagePrice) => {
                      ticketType.push({
                        package_commision:
                          packagePrice.package_price_commission,
                        package_price_globaltix_id:
                          packagePrice.package_price_globaltix_id,
                        package_price_id: packagePrice.package_price_id,
                        package_price_name: packagePrice.package_price_name,
                        package_price_sku: packagePrice.package_price_sku,
                        package_pricenettPrice:
                          packagePrice.package_pricenettPrice,
                        package_price_minPurchaseQty:
                          packagePrice.package_price_minPurchaseQty,
                        package_price_maxPurchaseQty:
                          packagePrice.package_price_maxPurchaseQty,
                        quantity:
                          packagePrice.quantity !== "" &&
                          typeof packagePrice.quantity !== undefined &&
                          typeof packagePrice.quantity !== "undefined"
                            ? packagePrice.quantity
                            : "",
                      });
                    });
                  }

                  var updateActivity = {
                    activity_activity_package_id: selectitem.package_id,
                    activity_activity_product: poupproduct.products_id,
                    creator_activity_day: this.state.dayIndex,
                    merchant_id: null,
                    product_category_name: poupproduct.product_category_name,
                    product_commission: poupproduct.product_commission,
                    product_payable_amount: poupproduct.product_payable_amount,
                    product_rating: poupproduct.rating,
                    product_slug: poupproduct.product_slug,
                    package_name: selectitem.package_name,
                    product_starting_price: poupproduct.product_starting_price,
                    globaltix_categories: poupproduct.globaltix_categories,
                    guided: poupproduct.guided,
                    ttd_duration: poupproduct.ttd_duration,
                    latitude: poupproduct.latitude,
                    longitude: poupproduct.longitude,
                    product_tag: null,
                    product_thumbnail: poupproduct.product_thumbnail,
                    product_total_package: poupproduct.product_total_package,
                    product_total_review: poupproduct.total_reviews,
                    products_description: poupproduct.products_description,
                    products_globaltix_id: poupproduct.products_globaltix_id,
                    products_id: poupproduct.products_id,
                    distance: poupproduct.distance,
                    package_description: selectitem.package_description,
                    package_inclusions: selectitem.package_inclusions,
                    package_howtouse: selectitem.package_howtouse,
                    package_cancellationNotes:
                      selectitem.package_cancellationNotes,
                    package_termsAndConditions:
                      selectitem.package_termsAndConditions,
                    products_addressLine: poupproduct.products_addressLine,
                    products_postalCode: poupproduct.products_postalCode,
                    product_operatingHours: poupproduct.product_operatingHours,
                    products_keywords: poupproduct.products_keywords,
                    products_name: poupproduct.products_name,
                    ticket_type: ticketType,
                    customer_activity: "Yes",
                    activity_availablity: "Yes",
                    activity_slot_list: activity_slot_list,
                    selected_slot: selectedTime,
                    show_error: "No",
                  };
                  activitiyList.push(updateActivity);
                } else {
                  activitiyList.push(actItem);
                }
              });
            } else {
              var ticketType = [];
              if (selectitem.package_price.length > 0) {
                selectitem.package_price.map((packagePrice) => {
                  ticketType.push({
                    package_commision: packagePrice.package_price_commission,
                    package_price_globaltix_id:
                      packagePrice.package_price_globaltix_id,
                    package_price_id: packagePrice.package_price_id,
                    package_price_name: packagePrice.package_price_name,
                    package_price_sku: packagePrice.package_price_sku,
                    package_pricenettPrice: packagePrice.package_pricenettPrice,
                    package_price_minPurchaseQty:
                      packagePrice.package_price_minPurchaseQty,
                    package_price_maxPurchaseQty:
                      packagePrice.package_price_maxPurchaseQty,
                    quantity:
                      packagePrice.quantity !== "" &&
                      typeof packagePrice.quantity !== undefined &&
                      typeof packagePrice.quantity !== "undefined"
                        ? packagePrice.quantity
                        : "",
                  });
                });
              }

              var updateActivity = {
                activity_activity_package_id: selectitem.package_id,
                activity_activity_product: poupproduct.products_id,
                creator_activity_day: this.state.dayIndex,
                merchant_id: null,
                product_category_name: poupproduct.product_category_name,
                product_commission: poupproduct.product_commission,
                product_payable_amount: poupproduct.product_payable_amount,
                product_rating: poupproduct.rating,
                product_slug: poupproduct.product_slug,
                package_name: selectitem.package_name,
                product_starting_price: poupproduct.product_starting_price,
                globaltix_categories: poupproduct.globaltix_categories,
                guided: poupproduct.guided,
                ttd_duration: poupproduct.ttd_duration,
                latitude: poupproduct.latitude,
                longitude: poupproduct.longitude,
                product_tag: null,
                product_thumbnail: poupproduct.product_thumbnail,
                product_total_package: poupproduct.product_total_package,
                product_total_review: poupproduct.total_reviews,
                products_description: poupproduct.products_description,
                products_globaltix_id: poupproduct.products_globaltix_id,
                products_id: poupproduct.products_id,
                distance: poupproduct.distance,
                products_keywords: poupproduct.products_keywords,
                products_name: poupproduct.products_name,
                package_description: selectitem.package_description,
                package_inclusions: selectitem.package_inclusions,
                package_howtouse: selectitem.package_howtouse,
                package_cancellationNotes: selectitem.package_cancellationNotes,
                package_termsAndConditions:
                  selectitem.package_termsAndConditions,
                products_addressLine: poupproduct.products_addressLine,
                products_postalCode: poupproduct.products_postalCode,
                product_operatingHours: poupproduct.product_operatingHours,
                ticket_type: ticketType,
                customer_activity: "Yes",
                activity_availablity: "Yes",
                activity_slot_list: activity_slot_list,
                selected_slot: selectedTime,
                show_error: "No",
              };
              activitiyList.push(updateActivity);
            }

            newactivities.push({
              day: item.day,
              date: ttdOnly ? selectedDate : item.date,
              activities: activitiyList,
              emptyactivity: "No",
            });
          } else {
            newactivities.push(item);
          }
        });

        if (ttdOnly) {
          var bookingdetails = this.props.location.state;
          bookingdetails["activities"] = newactivities;
          bookingdetails["packageSlug"] = "create-own-package";
          bookingdetails["packagedetails"] = "";
          bookingdetails["searchdata"].total_activities =
            this.state.total_activities;
          bookingdetails.ttdOnly = true;
          bookingdetails["searchdata"].start_date = selectedDate;
          bookingdetails["searchdata"].adults = this.state.adults;
          bookingdetails["searchdata"].child = this.state.child;
          bookingdetails["addFlight"] = "No";
          bookingdetails["addTransfer"] = "No";
          bookingdetails["emptyActivity"] = "No";
          bookingdetails["emptyFlight"] = "Yes";
          bookingdetails["emptyhotel"] = "Yes";
          bookingdetails["emptytransfer"] = "Yes";
          bookingdetails["selectedDay"] = this.state.selectedDate;
          bookingdetails["product_country"] = this.state.ttdCity;
          bookingdetails["Product_name"] = "";
          bookingdetails[
            "searchdata"
          ].images = `https://fizotametaimages.s3.ap-southeast-1.amazonaws.com/destination/${ttdCity}.jpg`;
          bookingdetails["onlyGuest"] = field === "guest" ? "guest" : "";
        } else {
          var bookingdetails = this.props.location.state;
          bookingdetails["activities"] = newactivities;
          bookingdetails["searchdata"].total_activities =
            this.state.total_activities;
        }

        var pathName = !ttdOnly
          ? `/search-package/${this.props.match.params.packageSlug}`
          : `/booking`;
        var total_activities = this.state.total_activities;
        this.setState({ total_activities: total_activities + 1 }, () => {
          // $.magnificPopup.close();

          if (ttdOnly === true) {
            if (field === "guest" && this.state.userID === "") {
              if (this.props.drawerOpened) {
                this.props.history.push({
                  state: bookingdetails,
                });
              } else {
                this.props.history.push({
                  pathname: pathName,
                  state: bookingdetails,
                });
              }
            } else if (this.state.userID === "") {
              this.setState({
                triggerLogin: true,
                returnloginresult: true,
                onlyGuest: true,
              });
              return false;
            }
          }
          if (this.props.drawerOpened) {
            this.props.history.push({
              state: bookingdetails,
            });
            this.props.handleCloseSheet(false);
          } else {
            this.props.history.push({
              pathname: pathName,
              state: bookingdetails,
            });
          }
        });
      } else {
        // showAlert("Alert", "Unfortunately, not available on the selected date");
        // $.magnificPopup.open({
        //   items: {
        //     src: ".alert_popup",
        //   },
        //   type: "inline",
        // });
        this.setState({
          openAlertPopup: true,
          alertPopupMsg: "Unfortunately, not available on the selected date",
          popupTitle: "Alert",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  closeDate() {
    this.setState(
      {
        selectedpackage: "",
        // selectedpackageId: "",
        displaypersion: "",
      },
      function () {
        // $.magnificPopup.close();
      }
    );
  }

  handleChangeDate(datevalue) {
    let selectedDate = this.formatDate(datevalue);
    this.setState({
      selectedDate,
      displyaTimeSlot: [],
      showdateError: "",
      loadTimeSlot: false,
      timeList: [],
    });
  }
  confirmDate = async () => {
    try {
      var selectedpackage = this.state.selectedpackage;
      if (this.state.timeList.length > 0 && this.state.eventTime === "") {
        this.setState({ showTimeError: true });
        return false;
      } else {
        this.setState({ showTimeError: false });
      }
      this.setState({ showdateError: "" });
      showLoader("choose_date");
      if (this.state.loadTimeSlot === false) {
        var selectedDate = format(this.state.selectedDate, "yyyy-MM-dd");
        var ticketTypeID = "";
        if (
          this.state.productdetail.merchant_id !== "" &&
          this.state.productdetail.merchant_id !== null
        ) {
          ticketTypeID = selectedpackage.package_price[0].package_price_id;
        } else {
          if (selectedpackage.package_price.length > 0) {
            ticketTypeID =
              selectedpackage.package_price[0].package_price_globaltix_id;
          }
        }

        await axios
          .get(
            apiUrl +
              "booking/checkAvailabilityDates?package_id=" +
              selectedpackage.package_id +
              "&ticket_type_id=" +
              ticketTypeID +
              "&booking_date=" +
              selectedDate,
            {
              headers: {
                Authorization: cookie.load("acccesstoken"),
              },
            }
          )
          .then((res) => {
            hideLoader("choose_date");
            if (res.data.status === "ok") {
              if (res.data.display_time === "Yes") {
                this.setState(
                  { timeList: res.data.time_list, loadTimeSlot: true },
                  function () {
                    if (res.data.time_list.length > 1) {
                      this.loadTimeList();
                    } else {
                      this.setState(
                        {
                          eventTime: res.data.time_list[0],
                          showspersion: true,
                          loadTimeSlot: true,
                        },
                        function () {
                          // $.magnificPopup.close();
                        }
                      );
                    }
                  }
                );
              } else {
                this.setState(
                  { showspersion: true, loadTimeSlot: true },
                  function () {
                    // $.magnificPopup.close();
                  }
                );
              }
            } else {
              this.setState({ showdateError: res.data.message });
            }
          });
      } else {
        this.setState({ showspersion: true, loadTimeSlot: true }, function () {
          hideLoader("choose_date");
          // $.magnificPopup.close();
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  loadTimeList() {
    try {
      var displyaTimeSlot = [];
      this.state.timeList.map((item) => {
        var splitItem = item.split("~");
        displyaTimeSlot.push({ value: item, label: splitItem[0] });
      });
      this.setState({ displyaTimeSlot: displyaTimeSlot });
    } catch (err) {
      console.log(err);
    }
  }
  booknow = () => {
    try {
      var errormsg = "";
      var error = 0;
      if (this.state.selectedTicketType.length > 0) {
        this.state.selectedTicketType.map((item) => {
          if (item.quantity < item.package_price_minPurchaseQty) {
            errormsg +=
              "Please Select Minumum " +
              item.package_price_minPurchaseQty +
              " " +
              jsUcfirstFun(item.package_price_name) +
              "<br/>";
          }
        });
        if (errormsg !== "") {
          var errormsgs = <span className="error">{Parser(errormsg)}</span>;
          this.setState({ errormsg: errormsgs });
          error++;
        }
      } else {
        errormsg = "Please select any one";
        this.setState({ errormsg: errormsg });
      }
      const { ttdOnly } = this.state;
      if (ttdOnly === true) {
        if (this.state.userID === "") {
          this.setState({ triggerLogin: true, returnloginresult: true });
          return false;
        }
      }

      if (error === 0) {
        var applicablecoin = 0;
        if (
          parseFloat(this.state.grandTotal) > 0 &&
          parseFloat(this.state.productdetail.points) > 0
        ) {
          applicablecoin = this.state.productdetail.points;
        }

        var bookingdetails = {
          selectedpackage: this.state.selectedpackage,
          selectedTicketType: this.state.selectedTicketType,
          totalPrice: this.state.totalPrice,
          selectedDate: this.state.selectedDate,
          eventTime: this.state.eventTime,
          applicablecoin: applicablecoin,
          dbspromo: this.state.productdetail.dbspromo,
          product_not_applicable_points:
            this.state.productdetail.product_not_applicable_points,
          package_not_applicable_points:
            this.state.selectedpackage.package_not_applicable_points,
          merchant_id: this.state.productdetail.merchant_id,
        };
        this.props.history.push({
          pathname: "/checkout",
          state: bookingdetails,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  sateValChange = (field, value) => {
    if (field === "proceedtocontinue" && value === "Yes") {
      var newuserID =
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") != "undefined"
          ? cookie.load("UserId")
          : "";
      this.setState({ userID: newuserID }, function () {
        // $.magnificPopup.close();
        this.selectPackage(this.state.productdetail, this.state.selectedItem);
      });
    } else if (field === "guest" && value === true) {
      // console.log("sep2 flash");
      // $.magnificPopup.close();
      this.selectPackage(
        this.state.productdetail,
        this.state.selectedItem,
        field,
        value
      );
    }
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  handleChange(event) {
    this.setState({ eventTime: event.value, showTimeError: false });
  }

  handleTextChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      ["error_" + event.target.name]: "",
    });
  }
  handleSelectChange(field, event) {
    this.setState({
      [field]: event.value,
      ["error_" + field]: "",
    });
  }

  showWriteReview() {
    this.setState({ writeReview: !this.state.writeReview });
  }

  // createRating() {
  //   var error = 0;
  //   if ($("#product_rating").val() === "") {
  //     $("#error_rating").show();
  //     error++;
  //   } else {
  //     $("#error_rating").hide();
  //   }
  //   if (this.state.ratingCategory === "") {
  //     this.setState({ error_ratingCategory: "Yes" });
  //     error++;
  //   } else {
  //     this.setState({ error_ratingCategory: "" });
  //   }
  //   if (this.state.reatingMessage === "") {
  //     this.setState({ error_reatingMessage: "Yes" });
  //     error++;
  //   } else {
  //     this.setState({ error_reatingMessage: "" });
  //   }
  //   if (error === 0) {
  //     showLoader("create_rating");
  //     var postObject = {
  //       customer_id: this.state.userID,
  //       rating: $("#product_rating").val(),
  //       review_category: this.state.ratingCategory,
  //       review_comment: this.state.reatingMessage,
  //       product_id: this.state.productdetail.products_id,
  //     };

  //     axios
  //       .post(apiUrl + "products/createReview", qs.stringify(postObject), {
  //         headers: {
  //           Authorization: cookie.load("acccesstoken"),
  //         },
  //       })
  //       .then((res) => {
  //         hideLoader("create_rating");
  //         if (res.data.status === "ok") {
  //           showAlert("Success", res.data.message);
  //           this.setState({
  //             review_category: "",
  //             review_comment: "",
  //             writeReview: !this.state.writeReview,
  //           });
  //         } else {
  //          // showAlert("Error", res.data.message);
  //         }
  //         // $.magnificPopup.open({
  //         //   items: {
  //         //     src: ".alert_popup",
  //         //   },
  //         //   type: "inline",
  //         // });
  //       });
  //   }
  // }
  loadAllImages() {
    var products = this.state.productdetail;
    if (Object.keys(products).length > 0) {
      var allimages = "";
      var allgalleryimages = "";
      var slidergalleryimages = "";
      if (Object.keys(products.additional_image).length > 0) {
        var additional_image = products.additional_image;
        allimages = Object.keys(products.additional_image).map(
          (item, index) => {
            return (
              <a
                key={index}
                href={mediaUrl + "products/gallery/" + additional_image[item]}
                className="lightbox-cats"
                ref={this.lightboxCatRef}
              >
                <img
                  src={mediaUrl + "products/gallery/" + additional_image[item]}
                  alt=""
                />
              </a>
            );
          }
        );
      }

      if (products.product_gallery.length > 0) {
        allgalleryimages = products.product_gallery.map((item, index) => {
          return (
            <a
              key={index}
              href={apiglobaltixMediaUrl + item}
              className="lightbox-cats"
              ref={this.lightboxCatRef}
            >
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </a>
          );
        });
      }
      if (products.product_gallery.length > 0) {
        slidergalleryimages = products.product_gallery.map((item, index) => {
          return (
            <div key={index}>
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </div>
          );
        });
      }
      this.setState(
        {
          allimages: allimages,
          allgalleryimages: allgalleryimages,
          slidergalleryimages: slidergalleryimages,
        },
        function () {
          setTimeout(function () {
            $(".lightbox-cats").magnificPopup({
              type: "image",
              gallery: { enabled: true },
              // other options
            });
          }, 2000);
        }
      );
    }
    this.activityList();
  }
  loadImage(products) {
    var firstImg = "";
    var secondImg = "";
    var thirdImg = "";
    var fourthImg = "";

    if (Object.keys(products.additional_image).length > 0) {
      if (
        products.additional_image[1] !== "" &&
        typeof products.additional_image[1] !== undefined &&
        typeof products.additional_image[1] !== "undefined"
      ) {
        firstImg =
          mediaUrl + "products/gallery/" + products.additional_image[1];
      }
      if (
        products.additional_image[2] !== "" &&
        typeof products.additional_image[2] !== undefined &&
        typeof products.additional_image[2] !== "undefined"
      ) {
        secondImg =
          mediaUrl + "products/gallery/" + products.additional_image[2];
      }
      if (
        products.additional_image[3] !== "" &&
        typeof products.additional_image[3] !== undefined &&
        typeof products.additional_image[3] !== "undefined"
      ) {
        thirdImg =
          mediaUrl + "products/gallery/" + products.additional_image[3];
      }
      if (
        products.additional_image[4] !== "" &&
        typeof products.additional_image[4] !== undefined &&
        typeof products.additional_image[4] !== "undefined"
      ) {
        fourthImg =
          mediaUrl + "products/gallery/" + products.additional_image[4];
      }
    }
    if (products.product_gallery.length > 0) {
      if (firstImg === "") {
        firstImg =
          products.merchant_id !== null && products.merchant_id !== "0"
            ? mediaUrl + "products/gallery/" + products.product_gallery[0]
            : apiglobaltixMediaUrl + products.product_gallery[0];
      }
      if (secondImg === "") {
        secondImg =
          products.product_gallery[1] !== "" &&
          typeof products.product_gallery[1] !== undefined &&
          typeof products.product_gallery[1] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[1]
              : apiglobaltixMediaUrl + products.product_gallery[1]
            : "";
      }
      if (thirdImg === "") {
        thirdImg =
          products.product_gallery[2] !== "" &&
          typeof products.product_gallery[2] !== undefined &&
          typeof products.product_gallery[2] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[2]
              : apiglobaltixMediaUrl + products.product_gallery[2]
            : "";
      }
      if (fourthImg === "") {
        fourthImg =
          products.product_gallery[3] !== "" &&
          typeof products.product_gallery[3] !== undefined &&
          typeof products.product_gallery[3] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[3]
              : apiglobaltixMediaUrl + products.product_gallery[3]
            : "";
      }

      if (products.product_gallery.length >= 4) {
        return !this.state.isMobile ? (
          <section className="det-banner four-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="" />
                </div>
                <div
                  className="det-banner-rhs-bottom-rhs product-image-more"
                  onClick={this.openGallery}
                >
                  <span>show all photos</span>
                  <img src={fourthImg} alt="" />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={thirdImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={fourthImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 3) {
        return !this.state.isMobile ? (
          <section className="det-banner three-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="" />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={thirdImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 2) {
        return !this.state.isMobile ? (
          <section className="det-banner two-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 1) {
        return (
          <section className="det-banner single-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
          </section>
        );
      }
    } else {
      return (
        <section className="det-banner single-gallery">
          <div className="det-banner-lhs">
            <img src={placeholder} alt="airvayslogo" />
          </div>
        </section>
      );
    }
  }
  // gotoPackage() {
  //   if ($(".detail-bottom-package").length > 0) {
  //     $("html, body").animate(
  //       {
  //         scrollTop: $(".detail-bottom-package").offset().top - 100,
  //       },
  //       1000
  //     );
  //   }
  // }
  componentDidMount() {
    $(document).ready(function () {
      $(".lightbox-cats").magnificPopup({
        type: "image",
        gallery: { enabled: true },
        // other options
      });
    });
  }

  openGallery() {
    $(".lightbox-cats:first-child").trigger("click");
    // if (lightboxCatRef.current) {
    //   lightboxCatRef.current.click();
    // }
  }

  openPackageInfo(poupproduct, pouppackage) {
    var booking_date = this.state.selectedDate;
    axios
      .get(
        apiUrl +
          "booking/checkDateAvailability?package_id=" +
          pouppackage.package_id +
          "&booking_date=" +
          booking_date,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            { poupproduct: poupproduct, pouppackage: pouppackage },
            function () {
              if (res.data.checkTime === "Y") {
                this.checkingSlot(pouppackage, booking_date);
              } else {
                // $.magnificPopup.open({
                //   items: {
                //     src: "#pacakge_popup",
                //   },
                //   type: "inline",
                //   mainClass: "package-info",
                // });
                this.setState({ showPackagePopup: true });
              }
            }
          );
        }
      });
  }
  checkingSlot(pouppackage, selectedDate) {
    var ticketTypeID = "";
    if (pouppackage.package_price.length > 0) {
      ticketTypeID = pouppackage.package_price[0].package_price_globaltix_id;
    }

    axios
      .get(
        apiUrl +
          "booking/checkAvailabilityDates?package_id=" +
          pouppackage.package_id +
          "&ticket_type_id=" +
          ticketTypeID +
          "&booking_date=" +
          selectedDate,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
      )
      .then((res) => {
        hideLoader("choose_date");
        if (res.data.status === "ok") {
          if (res.data.display_time === "Yes") {
            this.setState(
              { timeList: res.data.time_list, loadTimeSlot: true },
              function () {
                this.loadTimeList();
              }
            );
          } else {
            this.setState(
              { showspersion: true, loadTimeSlot: true },
              function () {
                // $.magnificPopup.close();
              }
            );
          }
        } else {
          this.setState({ showdateError: res.data.message });
        }
      });
  }
  loadTimeList() {
    var displyaTimeSlot = [];
    this.state.timeList.map((item) => {
      var splitItem = item.split("~");
      displyaTimeSlot.push({ value: item, label: splitItem[0] });
    });
    this.setState(
      { displyaTimeSlot: displyaTimeSlot, showPackagePopup: true },
      function () {
        // $.magnificPopup.open({
        //   items: {
        //     src: "#pacakge_popup",
        //   },
        //   type: "inline",
        // });
      }
    );
  }
  handleSelectInfo(activityItem, activityIndex) {
    this.checkAvailability(
      activityIndex,
      activityItem,
      this.state.productdetail
    );
  }
  goBack = () => {
    var bookingdetails = this.props.location.state;
    this.props.history.push({
      pathname:
        "/search-package/" +
        this.props.match.params.packageSlug +
        "/all-activities/" +
        this.state.searchdata.destinationCountry.toLowerCase(),
      state: bookingdetails,
    });
  };
  closepopup = () => {
    // $.magnificPopup.close();
  };
  incdec(activityIndex, priceID, passengerType, type, activityItem) {
    const { ttdOnly, selectedItem } = this.state;
    var passenger = "adults";
    var productdetail = this.state.productdetail;
    var updatePackage = [];
    let newPackage = selectedItem;
    let selectedPackageId = activityItem.package_id;
    if (productdetail.product_package.length > 0) {
      productdetail.product_package.map((item, index) => {
        if (index === activityIndex) {
          newPackage = item;
          if (item.package_price.length > 0) {
            var ticketType = [];
            item.package_price.map((priceItem) => {
              if (priceID == priceItem.package_price_id) {
                var newTicketType = priceItem;

                var selectQty = "";
                var currentQty =
                  priceItem.quantity !== "" &&
                  typeof priceItem.quantity !== undefined &&
                  typeof priceItem.quantity !== "undefined"
                    ? parseInt(priceItem.quantity)
                    : 0;
                var totalPasanger = 0;
                if (priceItem.package_price_name === "ADULT") {
                  totalPasanger = this.state.totalAdults;
                } else if (priceItem.package_price_name === "CHILD") {
                  totalPasanger = this.state.totalChild;
                } else {
                  totalPasanger =
                    parseInt(this.state.totalAdults) +
                    parseInt(this.state.totalChild);
                }
                var allowQty = ttdOnly ? "Yes" : "No";
                if (totalPasanger > currentQty) {
                  allowQty = "Yes";
                }
                if (type === "dec") {
                  allowQty = "Yes";
                }
                if (allowQty === "Yes") {
                  if (type === "inc") {
                    selectQty =
                      priceItem.quantity !== "" &&
                      typeof priceItem.quantity !== undefined &&
                      typeof priceItem.quantity !== "undefined"
                        ? priceItem.quantity + 1
                        : 1;
                    // Initialize if not already set, then increment
                    let newTotalAdults = this.state.adults ?? 0;
                    let newTotalChild = this.state.child ?? 0;

                    if (passengerType === "ADULT") {
                      newTotalAdults += 1;
                    } else if (passengerType === "CHILD") {
                      newTotalChild += 1;
                    }

                    this.setState({
                      adults: newTotalAdults,
                      child: newTotalChild,
                    });
                  } else {
                    if (
                      typeof priceItem.quantity === "number" &&
                      !isNaN(priceItem.quantity)
                    ) {
                      selectQty = priceItem.quantity - 1;
                      if (selectQty < 1) {
                        selectQty = "";
                      }
                    }
                    // Initialize if not already set, then decrement
                    let newTotalAdults = this.state.adults ?? 0;
                    let newTotalChild = this.state.child ?? 0;

                    if (passengerType === "ADULT" && newTotalAdults > 0) {
                      newTotalAdults -= 1;
                    } else if (passengerType === "CHILD" && newTotalChild > 0) {
                      newTotalChild -= 1;
                    }

                    this.setState({
                      adults: newTotalAdults,
                      child: newTotalChild,
                    });
                  }
                } else {
                  selectQty = parseInt(priceItem.quantity);
                  this.setState({
                    openAlertPopup: true,
                    alertPopupMsg: `You can select only ${totalPasanger} ${
                      priceItem.package_price_name === "ADULT"
                        ? "adult"
                        : priceItem.package_price_name === "CHILD"
                        ? "child"
                        : "pax"
                    } for the package. To add more please edit the passengers`,
                    popupTitle: "Alert",
                  });
                }
                passenger =
                  priceItem.package_price_name === "CHILD" ? "child" : "adults";
                newTicketType.quantity = selectQty;
                ticketType.push(newTicketType);
                if (selectQty > 1) {
                  this.setState({ [passenger]: selectQty, selectedPackageId });
                }
              } else {
                ticketType.push(priceItem);
              }
            });
            newPackage.package_price = ticketType;
          }
          updatePackage.push(newPackage);
        } else {
          updatePackage.push(item);
        }
      });
    }
    productdetail.product_package = updatePackage;
    this.setState({ productdetail: productdetail, selectedItem: newPackage });
  }
  activityList() {
    var activities = this.state.activities;
    var activityList = [];
    if (activities.length > 0) {
      for (var i = 0; activities.length > i; i++) {
        activities[i].activities.map((item) => {
          if (item !== "") {
            activityList.push(item.package_id);
          }
        });
      }
    }
    this.setState({ packActivityList: activityList });
  }

  selectTimeSlot(productdetail, activityIndex, timeDetails) {
    var updatePacakge = [];
    let newPackagecopy;
    let selectedPackageId;
    if (productdetail.product_package.length > 0) {
      productdetail.product_package.map((item, index) => {
        if (index === activityIndex) {
          var newPackage = item;
          selectedPackageId = item.package_id;
          newPackagecopy = [
            {
              slot_globaltix_id: timeDetails.slot_globaltix_id,
              slot_date: timeDetails.slot_date,
              slot_time: timeDetails.slot_time,
              slot_time_format: timeDetails.slot_time_format,
            },
          ];
          newPackage.selected_slot = newPackagecopy;
          updatePacakge.push(item);
        } else {
          updatePacakge.push(item);
        }
      });
    }

    productdetail.product_package = updatePacakge;
    let selectedItem = this.state.selectedItem;
    if (newPackagecopy && newPackagecopy !== "") {
      selectedItem.selected_slot = newPackagecopy;
    }

    this.setState({
      productdetail: productdetail,
      selectedItem,
      selectedIndex: activityIndex,
      selectedPackageId,
      isTimeSelected: true,
    });
  }
  product_Details(productdetail, product) {
    this.setState(
      {
        pouppackage: product,
        poupproduct: productdetail,
        showPackagePopup: true,
      },
      function () {
        // $.magnificPopup.open({
        //   items: {
        //     src: "#pacakge_popup",
        //   },
        //   type: "inline",
        //   mainClass: "package-info",
        // });
      }
    );
  }

  toggleMaxVisibleSlots = () => {
    this.setState((prevState) => ({
      maxVisibleSlots: prevState.maxVisibleSlots === 5 ? Infinity : 5,
    }));
  };

  formatDate(inputDate) {
    try {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    } catch (err) {
      console.log(err);
    }
  }

  handleSelectDate = (item) => {
    try {
      var startDate = this.formatDate(item);
      let isDateSelected = true;
      this.setState({ selectedDate: startDate, isDateSelected }, async () => {
        var params = {
          product_slug: this.props.match.params.productslug,
          userID: this.state.userID,
          booking_date: this.state.selectedDate,
        };

        this.handleChangeDate = this.handleChangeDate.bind(this);
        await this.props.getProductDetails(params);
      });
    } catch (err) {
      console.log(err);
    }
  };

  checkAvailability = async (selectedIndex, selectedItem, product) => {
    try {
      var package_id = selectedItem.package_id;
      var ticket_type_id =
        selectedItem.package_price[0].package_price_globaltix_id;

      const packagePriceNames = selectedItem.package_price.map(
        (obj) => obj.package_price_name
      );

      await axios
        .get(
          apiUrl +
            `booking/checkAvailableDateList?ticket_type_id=${ticket_type_id}`,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          }
        )
        .then((res) => {
          // console.log(res);
          if (res.data.status === "ok") {
            this.setState(
              {
                availabilityDates: res.data.result_set,
                selectedItem: selectedItem,
                selectedIndex: selectedIndex,
                selectedPackageId: package_id,
              },
              () => {
                if (this.state.isMobile) {
                  this.setState({ showSelectInfo: true });
                } else {
                  this.setState({
                    showCalendarPopup: true,
                    selectedDate: new Date(),
                    isDateSelected: false,
                    showSlotList: false,
                    selectedPackageId: "",
                  });
                  // $.magnificPopup.open({
                  //   items: {
                  //     src: `#calendar_popup`,
                  //   },
                  //   type: "inline",
                  //   mainClass: "package-info",
                  //   closeOnBgClick: false,
                  //   callbacks: {
                  //     beforeClose: () => {
                  //       this.setState({
                  //         selectedDate: new Date(),
                  //         isDateSelected: false,
                  //         showSlotList: false,
                  //         selectedPackageId: "",
                  //       });
                  //     },
                  //   },
                  // });
                }
              }
            );
          } else {
            // showAlert("Alert", "No available events");
            // $.magnificPopup.open({
            //   items: {
            //     src: ".alert_popup",
            //   },
            //   type: "inline",
            // });
            this.setState({
              openAlertPopup: true,
              alertPopupMsg: "No available events",
              popupTitle: "Alert",
            });
            return null;
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  calendarTileContent = (date) => {
    const { availabilityDates } = this.state;
    const modifiedArray = availabilityDates.map((dateString) =>
      dateString.slice(0, 10)
    );
    const dateString = date.toISOString().split("T")[0]; // Convert date to string in "YYYY-MM-DD" format
    return !modifiedArray.includes(dateString);
  };

  passengerSelection = (
    activityItem,
    activityIndex,
    allowPassenger,
    allowButton,
    showPassenger = true,
    collapsedItems,
    totalPrice,
    startingPrice
  ) => {
    const { selectedItem, selectedIndex } = this.state;
    let packageItem = !allowPassenger ? selectedItem : activityItem;
    if (isNaN(startingPrice) && packageItem.package_price?.length > 0) {
      let checkingPassenger = ["ADULT"];
      let child = this.props?.location?.state?.searchdata?.child;
      if (parseInt(child) > 0) {
        checkingPassenger.push("CHILD");
      }
      let filteredData =
        checkingPassenger.length > 0
          ? packageItem.package_price.filter(
              (item) => checkingPassenger.indexOf(item.package_price_name) >= 0
            )
          : packageItem.package_price;
      let startingPrice = Math.min(
        ...filteredData.map((item) => item.package_pricenettPrice)
      );
    }
    return (
      <div
        className={`ttd-p-rhs ttd-p-rhs-select ${
          this.props.drawerOpened ? "" : "ttd-slot-option"
        } ${
          this.state.selectedItem?.dateAvailability?.slotList.length &&
          !this.state.selectedItem?.selected_slot?.length
            ? "disabled"
            : ""
        }`}
      >
        {packageItem?.package_price?.length > 0 &&
          allowPassenger &&
          showPassenger && (
            <ul>
              {packageItem.package_price.map((ticketItem, ticketIndex) => {
                var checkingTicketType = "No";
                let totalPQty = 0;
                if (ticketItem.package_price_name === "ADULT") {
                  if (this.state.adults > 0 || this.state.ttdOnly) {
                    checkingTicketType = "Yes";
                  }
                } else if (ticketItem.package_price_name === "CHILD") {
                  if (this.state.child > 0 || this.state.ttdOnly) {
                    checkingTicketType = "Yes";
                  }
                } else {
                  totalPQty =
                    parseInt(this.state.adults) + parseInt(this.state.child);
                  if (
                    totalPQty > 0 &&
                    activityItem.package_price.length === 1
                  ) {
                    checkingTicketType = "Yes";
                  }
                }
                if (checkingTicketType === "Yes") {
                  return (
                    <li key={ticketIndex}>
                      <div className="ad-ingo">
                        <p>
                          {ticketItem.package_price_name}{" "}
                          {((ticketItem.package_price_maxPurchaseQty !== "" &&
                            ticketItem.package_price_maxPurchaseQty !== null) ||
                            (ticketItem.package_price_minPurchaseQty !== "" &&
                              ticketItem.package_price_maxPurchaseQty !==
                                null) ||
                            (ticketItem.package_price_minPurchaseQty !== "" &&
                              ticketItem.package_price_minPurchaseQty !==
                                null)) && (
                            <em>
                              {ticketItem.package_price_minPurchaseQty !== "" &&
                                ticketItem.package_price_minPurchaseQty !==
                                  null &&
                                "Min " +
                                  ticketItem.package_price_minPurchaseQty}{" "}
                              -{" "}
                              {ticketItem.package_price_maxPurchaseQty !== "" &&
                                ticketItem.package_price_maxPurchaseQty !==
                                  null &&
                                "Max " +
                                  ticketItem.package_price_maxPurchaseQty}
                            </em>
                          )}
                        </p>
                      </div>
                      <div className="ad-ingo-rhs">
                        <div className="qty-bx ttd-qty-bx">
                          <span
                            className="qty-minus"
                            onClick={this.incdec.bind(
                              this,
                              activityIndex,
                              ticketItem.package_price_id,
                              ticketItem.package_price_name,
                              "dec",
                              activityItem
                            )}
                          >
                            <img src={MinusIcon} alt="MinusIcon" />
                          </span>
                          <input
                            type="text"
                            className="qty-input"
                            readOnly={true}
                            value={
                              ticketItem.quantity &&
                              ticketItem.quantity !== "undefined"
                                ? ticketItem.quantity
                                : 0
                            }
                          />
                          <span
                            className="qty-plus"
                            onClick={this.incdec.bind(
                              this,
                              activityIndex,
                              ticketItem.package_price_id,
                              ticketItem.package_price_name,
                              "inc",
                              activityItem
                            )}
                          >
                            <img src={PlusIcon} alt="PlusIcon" />
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          )}
        {this.props.drawerOpened &&
          !collapsedItems?.includes(activityIndex) && (
            <div className="ttd-total-pack">
              {totalPrice <= 0 &&
              this.state.selectedPackageId !== activityItem.package_id ? (
                <>
                  <strong>
                    Starting Price{" "}
                    <span>
                      {showPriceValue(
                        startingPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                    </span>
                  </strong>{" "}
                </>
              ) : (
                <strong>
                  Total{" "}
                  <span>
                    {showPriceValue(
                      totalPrice,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </span>
                </strong>
              )}
              <p>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                Please read the package information before booking.
              </p>
            </div>
          )}
        {allowButton && (
          <div
            className={`nxt-row-btn ${allowPassenger ? "align-center" : ""}`}
          >
            <a
              href={void 0}
              id={"packagebtnpop_" + activityItem.package_id}
              onClick={
                !allowPassenger
                  ? () =>
                      this.checkAvailability(
                        activityIndex,
                        activityItem,
                        this.state.productdetail
                      )
                  : this.selectPackage.bind(
                      this,
                      this.state.productdetail,
                      activityItem
                    )
              }
              className="button"
            >
              {!allowPassenger ? "Check Availability" : "Continue"}
            </a>
          </div>
        )}
      </div>
    );
  };

  render() {
    if (
      parseFloat(this.state.grandTotal) > 0 &&
      parseFloat(this.state.productdetail.points) > 0
    ) {
      totalEarnPotins = calculateAircoins(
        this.state.grandTotal,
        this.state.productdetail.points
      );
    }
    const { selectedItem } = this.state;
    const { maxVisibleSlots } = this.state;
    var childAgeOptions = [];
    for (let index = 2; index <= 12; index++) {
      childAgeOptions.push({ label: index, value: index });
    }

    const cardBackGroundImage = {
      backgroundImage: `url(${apiglobaltixMediaUrl}${this.props.productdetail.product_thumbnail})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    };
    return (
      <>
        {!this.props.drawerOpened && (
          <Header
            {...this.props}
            triggerLogin={this.state.triggerLogin}
            pagestate={this.state}
            returnloginresult={this.state.returnloginresult}
            sateValChange={this.sateValChange}
          />
        )}
        {this.props.drawerOpened ? (
          <ActivityDetail
            state={this.state}
            props={this.props}
            toggleMaxVisibleSlots={this.toggleMaxVisibleSlots}
            selectTimeSlot={this.selectTimeSlot.bind(this)}
            passengerSelection={this.passengerSelection}
            product_Details={this.product_Details.bind(this)}
          />
        ) : (
          <>
            <div className="product-more-image">
              {this.state.allimages}
              {this.state.allgalleryimages}
            </div>
            <div className="product-mobile-gallery">
              {this.state.slidergalleryimages !== "" && (
                <OwlCarousel options={settingsgallery}>
                  {this.state.slidergalleryimages}
                </OwlCarousel>
              )}
            </div>
            {this.state.productdetail !== "" &&
            Object.keys(this.state.productdetail).length > 0 ? (
              <>
                {this.loadImage(this.state.productdetail)}
                {!this.state.isMobile && (
                  <div className="bread-crumbs">
                    <div className="container">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <a href={void 0} onClick={this.goBack}>
                            {" "}
                            {this.state.searchdata.destinationCountry}
                          </a>
                        </li>
                        <li>{this.state.productdetail.products_name}</li>
                      </ul>
                    </div>
                  </div>
                )}
                <section className="innersection">
                  <div className="container">
                    <div className="detail-bottom">
                      <div className="detail-bottom-inner">
                        <div className="detail-bottom-lhs">
                          <div className="detail-top-lhs">
                            <div className="detail-top-header">
                              <h1>{this.state.productdetail.products_name}</h1>
                              {this.state.productdetail
                                .product_category_name !== "" &&
                                this.state.productdetail
                                  .product_category_name !== null && (
                                  <div className="product-description-tag">
                                    <span>
                                      {
                                        this.state.productdetail
                                          .product_category_name
                                      }
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="detail-top-detail">
                              <div className="detail-top-detail-parent">
                                <ul className="detail-top-detail-lhs">
                                  {this.state.productdetail.product_package
                                    .length > 0 && (
                                    <li>
                                      <img src={loader} alt="loader" />{" "}
                                      Duration:{" "}
                                      {this.state.productdetail
                                        .product_isopendated === "1"
                                        ? "Open Dated"
                                        : "Fixed Date "}
                                    </li>
                                  )}

                                  {this.state.productdetail
                                    .products_addressLine !== "" &&
                                    this.state.productdetail
                                      .products_addressLine !== null && (
                                      <li>
                                        <img src={mpin} alt="map" />
                                        <strong>Location:</strong>{" "}
                                        {
                                          this.state.productdetail
                                            .products_addressLine
                                        }
                                        -{" "}
                                        {
                                          this.state.productdetail
                                            .products_postalCode
                                        }
                                      </li>
                                    )}
                                </ul>
                                <ul className="detail-top-detail-rhs">
                                  <li>
                                    <img src={dollar} alt="dollar" />
                                    {this.state.productdetail.product_package
                                      .length > 0 &&
                                    this.state.productdetail.product_package[0]
                                      .package_ticketFormat === "QRCODE"
                                      ? "Cancellable"
                                      : "Non Cancellable"}
                                  </li>
                                  {this.state.productdetail
                                    .product_instantconfirmation === "1" && (
                                    <li>
                                      <img
                                        src={instant_confirmation}
                                        alt="confirmation"
                                      />
                                      Instant confirmation
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div className="detail-top-detail-parent-rating">
                                {this.state.productdetail.rating !== "" &&
                                this.state.productdetail.rating !== null ? (
                                  <span>
                                    <strong>
                                      {this.state.productdetail.product_rating}
                                    </strong>{" "}
                                    ({this.state.productdetail.total_reviews})
                                    Reviews
                                  </span>
                                ) : (
                                  <span>No Reviews</span>
                                )}
                                <div className="star-rating">
                                  {this.loadRatingstar(
                                    this.state.productdetail.rating
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.productdetail.product_package.length >
                            0 && (
                            <div className="detail-bottom-package">
                              <div className="detail-bottom-package-title">
                                <h4 className="bold-with-blue">
                                  Packages for <span>you</span>{" "}
                                </h4>
                              </div>

                              <div className="ttd-package-card">
                                {this.state.isMobile ? (
                                  <ul>
                                    {this.state.productdetail.product_package.map(
                                      (activityItem, activityIndex) => {
                                        var totalPrice = 0;
                                        var startingPrice_ = [];
                                        if (
                                          activityItem.package_price.length > 0
                                        ) {
                                          activityItem.package_price.map(
                                            (ticketItem) => {
                                              if (
                                                ticketItem.quantity !== "" &&
                                                typeof ticketItem.quantity !==
                                                  undefined &&
                                                typeof ticketItem.quantity !==
                                                  "undefined"
                                              ) {
                                                totalPrice +=
                                                  parseFloat(
                                                    ticketItem.package_pricenettPrice
                                                  ) *
                                                  parseInt(ticketItem.quantity);
                                              }
                                              if (
                                                parseFloat(
                                                  ticketItem.package_pricenettPrice
                                                ) > 0
                                              ) {
                                                startingPrice_.push(
                                                  parseFloat(
                                                    ticketItem.package_pricenettPrice
                                                  )
                                                );
                                              }
                                              return "";
                                            }
                                          );
                                        }
                                        var startingPrice =
                                          startingPrice_.length > 0
                                            ? Math.min(startingPrice_)
                                            : this.state.productdetail
                                                .statring_price;
                                        return (
                                          <li
                                            key={activityIndex}
                                            className={
                                              this.state.packActivityList.includes(
                                                activityItem.package_id
                                              )
                                                ? "disabled-link"
                                                : "things-to-do-error"
                                            }
                                          >
                                            <div className="ttd-p-lhs">
                                              <div className="row">
                                                <div className="col1">
                                                  <h4>
                                                    {activityItem.package_name}
                                                  </h4>
                                                </div>
                                                <div className="col2">
                                                  <a
                                                    href={void 0}
                                                    onClick={this.product_Details.bind(
                                                      this,
                                                      this.state.productdetail,
                                                      activityItem
                                                    )}
                                                  >
                                                    <span>Details</span>
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="vist-info">
                                                <ul>
                                                  {this.state.isDateSelected &&
                                                    this.state
                                                      .selectedPackageId ===
                                                      activityItem.product_id && (
                                                      <li className="vist-date">
                                                        Visit:{" "}
                                                        {
                                                          this.state
                                                            .selectedDate
                                                        }
                                                      </li>
                                                    )}
                                                  {activityItem.package_confirmation ===
                                                    "1" && (
                                                    <li className="aftr-req">
                                                      After confirmation
                                                      required
                                                    </li>
                                                  )}
                                                </ul>
                                              </div>
                                              {!this.state.ttdOnly && (
                                                <div className="tit-info">
                                                  {activityItem.dateAvailability
                                                    .slotList.length > 0 && (
                                                    <>
                                                      <span>
                                                        Select time slot
                                                      </span>
                                                      <div className="vi-cover">
                                                        {activityItem.dateAvailability.slotList
                                                          .slice(
                                                            0,
                                                            maxVisibleSlots
                                                          )
                                                          .map(
                                                            (
                                                              slotItem,
                                                              slotIndex
                                                            ) => {
                                                              var selectedSlot = false;
                                                              if (
                                                                activityItem.selected_slot !==
                                                                  "" &&
                                                                typeof activityItem.selected_slot !==
                                                                  undefined &&
                                                                typeof activityItem.selected_slot !==
                                                                  "undefined"
                                                              ) {
                                                                selectedSlot =
                                                                  activityItem
                                                                    .selected_slot[0]
                                                                    .slot_globaltix_id ===
                                                                  slotItem.slot_globaltix_id
                                                                    ? true
                                                                    : false;
                                                              }
                                                              return (
                                                                <a
                                                                  key={
                                                                    slotIndex
                                                                  }
                                                                  href={void 0}
                                                                  className={
                                                                    selectedSlot ===
                                                                    true
                                                                      ? "active"
                                                                      : ""
                                                                  }
                                                                  onClick={this.selectTimeSlot.bind(
                                                                    this,
                                                                    this.state
                                                                      .productdetail,
                                                                    activityIndex,
                                                                    slotItem
                                                                  )}
                                                                >
                                                                  {
                                                                    slotItem.slot_time_format
                                                                  }
                                                                </a>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                      {activityItem
                                                        .dateAvailability
                                                        .slotList.length >
                                                        5 && (
                                                        <a
                                                          href={void 0}
                                                          onClick={
                                                            this
                                                              .toggleMaxVisibleSlots
                                                          }
                                                        >
                                                          {maxVisibleSlots === 5
                                                            ? "View More"
                                                            : "View Less"}
                                                        </a>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                              <div className="ttd-total-pack">
                                                {totalPrice <= 0 &&
                                                this.state.selectedPackageId !==
                                                  activityItem.package_id ? (
                                                  <>
                                                    <strong>
                                                      Starting Price
                                                    </strong>{" "}
                                                    <span>
                                                      {showPriceValue(
                                                        startingPrice,
                                                        this.state
                                                          .currentCurrency,
                                                        this.state.currencyRate
                                                      )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <strong>
                                                    Total{" "}
                                                    <span>
                                                      {showPriceValue(
                                                        totalPrice,
                                                        this.state
                                                          .currentCurrency,
                                                        this.state.currencyRate
                                                      )}
                                                    </span>
                                                  </strong>
                                                )}
                                                <p>
                                                  <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                  Please read the package
                                                  information before booking.
                                                </p>
                                              </div>
                                            </div>
                                            {this.passengerSelection(
                                              activityItem,
                                              activityIndex,
                                              !this.state.ttdOnly,
                                              true
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  <ul>
                                    {this.state.productdetail.product_package.map(
                                      (activityItem, activityIndex) => {
                                        var totalPrice = 0;
                                        var startingPrice_ = [];
                                        if (
                                          activityItem.package_price.length > 0
                                        ) {
                                          activityItem.package_price.map(
                                            (ticketItem) => {
                                              if (
                                                ticketItem.quantity !== "" &&
                                                typeof ticketItem.quantity !==
                                                  undefined &&
                                                typeof ticketItem.quantity !==
                                                  "undefined"
                                              ) {
                                                totalPrice +=
                                                  parseFloat(
                                                    ticketItem.package_pricenettPrice
                                                  ) *
                                                  parseInt(ticketItem.quantity);
                                              }
                                              if (
                                                parseFloat(
                                                  ticketItem.package_pricenettPrice
                                                ) > 0
                                              ) {
                                                startingPrice_.push(
                                                  parseFloat(
                                                    ticketItem.package_pricenettPrice
                                                  )
                                                );
                                              }
                                              return "";
                                            }
                                          );
                                        }
                                        var startingPrice =
                                          startingPrice_.length > 0
                                            ? Math.min(startingPrice_)
                                            : this.state.productdetail
                                                .statring_price;
                                        return (
                                          <li
                                            key={activityIndex}
                                            className={
                                              this.state.packActivityList.includes(
                                                activityItem.package_id
                                              )
                                                ? "disabled-link"
                                                : "things-to-do-error"
                                            }
                                          >
                                            <div className="ttd-p-lhs">
                                              <h4>
                                                {activityItem.package_name}
                                              </h4>

                                              <div className="vist-info">
                                                <ul>
                                                  {this.state.isDateSelected &&
                                                    this.state
                                                      .selectedPackageId ===
                                                      activityItem.package_id && (
                                                      <li className="vist-date">
                                                        Visit:{" "}
                                                        {
                                                          this.state
                                                            .selectedDate
                                                        }
                                                      </li>
                                                    )}
                                                  {activityItem.package_confirmation ===
                                                    "1" && (
                                                    <li className="aftr-req">
                                                      After confirmation
                                                      required
                                                    </li>
                                                  )}
                                                  <li className="vw-more">
                                                    <a
                                                      href={void 0}
                                                      onClick={this.product_Details.bind(
                                                        this,
                                                        this.state
                                                          .productdetail,
                                                        activityItem
                                                      )}
                                                    >
                                                      <span>
                                                        View more info..
                                                      </span>
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>

                                              {
                                                <div className="tit-info">
                                                  {!this.state.ttdOnly && (
                                                    <>
                                                      {activityItem
                                                        .dateAvailability
                                                        .slotList.length >
                                                        0 && (
                                                        <>
                                                          <span>
                                                            Select time slot
                                                          </span>
                                                          <div className="vi-cover">
                                                            {activityItem.dateAvailability.slotList
                                                              .slice(
                                                                0,
                                                                maxVisibleSlots
                                                              )
                                                              .map(
                                                                (
                                                                  slotItem,
                                                                  slotIndex
                                                                ) => {
                                                                  var selectedSlot = false;
                                                                  if (
                                                                    activityItem.selected_slot !==
                                                                      "" &&
                                                                    typeof activityItem.selected_slot !==
                                                                      undefined &&
                                                                    typeof activityItem.selected_slot !==
                                                                      "undefined"
                                                                  ) {
                                                                    selectedSlot =
                                                                      activityItem
                                                                        .selected_slot[0]
                                                                        .slot_globaltix_id ===
                                                                      slotItem.slot_globaltix_id
                                                                        ? true
                                                                        : false;
                                                                  }
                                                                  return (
                                                                    <a
                                                                      key={
                                                                        slotIndex
                                                                      }
                                                                      href={
                                                                        void 0
                                                                      }
                                                                      className={
                                                                        selectedSlot ===
                                                                        true
                                                                          ? "active"
                                                                          : ""
                                                                      }
                                                                      onClick={this.selectTimeSlot.bind(
                                                                        this,
                                                                        this
                                                                          .state
                                                                          .productdetail,
                                                                        activityIndex,
                                                                        slotItem
                                                                      )}
                                                                    >
                                                                      {
                                                                        slotItem.slot_time_format
                                                                      }
                                                                    </a>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                          {activityItem
                                                            .dateAvailability
                                                            .slotList.length >
                                                            5 && (
                                                            <a
                                                              href={void 0}
                                                              onClick={
                                                                this
                                                                  .toggleMaxVisibleSlots
                                                              }
                                                            >
                                                              {maxVisibleSlots ===
                                                              5
                                                                ? "View More"
                                                                : "View Less"}
                                                            </a>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              }

                                              <div className="ttd-total-pack">
                                                {totalPrice <= 0 &&
                                                this.state.selectedPackageId !==
                                                  activityItem.package_id ? (
                                                  <>
                                                    <strong>
                                                      Starting Price{" "}
                                                      <span>
                                                        {showPriceValue(
                                                          startingPrice,
                                                          this.state
                                                            .currentCurrency,
                                                          this.state
                                                            .currencyRate
                                                        )}
                                                      </span>
                                                    </strong>{" "}
                                                  </>
                                                ) : (
                                                  <strong>
                                                    Total{" "}
                                                    <span>
                                                      {showPriceValue(
                                                        totalPrice,
                                                        this.state
                                                          .currentCurrency,
                                                        this.state.currencyRate
                                                      )}
                                                    </span>
                                                  </strong>
                                                )}
                                                <p>
                                                  <i
                                                    className="fa fa-info-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                  Please read the package
                                                  information before booking.
                                                </p>
                                              </div>
                                            </div>
                                            {this.passengerSelection(
                                              activityItem,
                                              activityIndex,
                                              !this.state.ttdOnly,
                                              true
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="policy-details">
                            <h4 className="bold-with-blue">
                              Essential <span>details</span>
                            </h4>

                            {this.state.selectedpackage !== "" &&
                            Object.keys(this.state.selectedpackage).length >
                              0 ? (
                              <>
                                {this.state.selectedpackage
                                  .package_inclusions !== "" &&
                                  this.state.selectedpackage
                                    .package_inclusions !== null && (
                                    <div className="include-box">
                                      <h5>What's included with the ticket:</h5>
                                      <ul>
                                        {this.state.selectedpackage.package_inclusions
                                          .split("#$")
                                          .map((item, index3) => {
                                            return <li key={index3}>{item}</li>;
                                          })}
                                      </ul>
                                    </div>
                                  )}

                                {this.state.selectedpackage
                                  .package_termsAndConditions !== "" &&
                                  this.state.selectedpackage
                                    .package_termsAndConditions !== null && (
                                    <>
                                      <h4>Terms and conditions:</h4>
                                      <p>
                                        {Parser(
                                          this.state.selectedpackage
                                            .package_termsAndConditions
                                        )}
                                      </p>
                                    </>
                                  )}
                                {this.state.selectedpackage.package_howtouse !==
                                  "" &&
                                  this.state.selectedpackage
                                    .package_howtouse !== null && (
                                    <>
                                      <h4>How to use the ticket:</h4>
                                      <p>
                                        {Parser(
                                          this.state.selectedpackage
                                            .package_howtouse
                                        )}
                                      </p>
                                    </>
                                  )}

                                {this.state.selectedpackage
                                  .package_cancellationNotes !== "" &&
                                  this.state.selectedpackage
                                    .package_cancellationNotes !== null && (
                                    <>
                                      <h4> Cancellation policy:</h4>
                                      {this.state.selectedpackage.package_cancellationNotes
                                        .split("#$")
                                        .map((item, index2) => {
                                          if (item !== "" && item !== null) {
                                            return (
                                              <p key={index2}>{Parser(item)}</p>
                                            );
                                          }
                                        })}
                                    </>
                                  )}
                              </>
                            ) : (
                              this.state.productdetail.products_description !==
                                "" &&
                              this.state.productdetail.products_description !==
                                null && (
                                <>
                                  <h4>Description</h4>
                                  <p>
                                    {Parser(
                                      this.state.productdetail
                                        .products_description
                                    )}
                                  </p>
                                </>
                              )
                            )}
                            {this.state.productdetail.product_operatingHours !==
                              "" &&
                              this.state.productdetail
                                .product_operatingHours !== null && (
                                <>
                                  <h4>Operating Hours</h4>
                                  <p>
                                    {Parser(
                                      this.state.productdetail
                                        .product_operatingHours
                                    )}
                                  </p>
                                </>
                              )}
                          </div>
                        </div>

                        <div className="detail-bottom-rhs">
                          {this.state.productdetail.product_latitude !== "" &&
                            this.state.productdetail.product_latitude !==
                              null &&
                            this.state.productdetail.product_longitude !== "" &&
                            this.state.productdetail.product_longitude !==
                              null && (
                              <div className="detail-bottom-rhs-map">
                                <iframe
                                  width="350"
                                  height="400"
                                  frameBorder="0"
                                  scrolling="no"
                                  marginHeight="0"
                                  marginWidth="0"
                                  src={
                                    "https://maps.google.com/maps?q=" +
                                    this.state.productdetail.product_latitude +
                                    "," +
                                    this.state.productdetail.product_longitude +
                                    "&hl=en&z=14&output=embed"
                                  }
                                ></iframe>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <div id="dvLoading">
                <div className="loader triangle">
                  <svg viewBox="0 0 100 100">
                    <circle
                      cx="50"
                      cy="50"
                      r="40"
                      stroke="black"
                      strokeWidth="3"
                      fill="transparent"
                    />
                  </svg>
                </div>
              </div>
            )}
          </>
        )}

        {this.state.poupproduct !== "" && this.state.pouppackage !== "" && (
          <ModalPopup
            open={this.state.showPackagePopup}
            handleClose={() => this.setState({ showPackagePopup: false })}
            classNames="common-popup pacakge_popup"
          >
            <div className="cp-header textcenter">
              <h3>
                Package <span>information</span>
              </h3>
            </div>

            <div className="pp-info">
              <div className="pp-info-blue">
                <ul>
                  <li>
                    <img src={calday} alt="calday" />
                    {this.state.pouppackage.package_ticketValidity ===
                    "Duration"
                      ? " Open dated valid upto " +
                        this.state.pouppackage.package_definedDuration +
                        " days"
                      : "Fixed Date"}
                  </li>
                  <li>
                    <img src={dollar} alt="dollar" />

                    {this.state.pouppackage.package_ticketFormat === "QRCODE"
                      ? "Cancellable and refundable"
                      : "Non Cancellable and non refundable"}
                  </li>
                  {this.state.poupproduct.product_instantconfirmation ===
                    "1" && (
                    <li>
                      <img src={instant_confirmation} alt="confirmation" />
                      Instant confirmation
                    </li>
                  )}
                </ul>
              </div>
              <div className="pp-info-text">
                <h5>
                  Package <span>title</span>
                </h5>
                <p>{this.state.pouppackage.package_name}</p>
                {this.state.pouppackage.package_description !== "" &&
                  this.state.pouppackage.package_description !== null && (
                    <>
                      <h5>
                        Package <span>Description</span>
                      </h5>
                      {Parser(this.state.pouppackage.package_description)}
                    </>
                  )}

                {this.state.pouppackage.package_inclusions !== "" &&
                  this.state.pouppackage.package_inclusions !== null && (
                    <>
                      <h5>
                        What's <span>Included</span>
                      </h5>

                      <ul>
                        {this.state.pouppackage.package_inclusions
                          .split("#$")
                          .map((item, index3) => {
                            return <li key={index3}>{item}</li>;
                          })}
                      </ul>
                    </>
                  )}
                {this.state.pouppackage.package_howtouse !== "" &&
                  this.state.pouppackage.package_howtouse !== null && (
                    <>
                      <h5>
                        How to <span>Use</span>
                      </h5>
                      <ul>
                        {this.state.pouppackage.package_howtouse
                          .split("#$")
                          .map((item, index3) => {
                            return <li key={index3}>{item}</li>;
                          })}
                      </ul>
                    </>
                  )}

                {this.state.pouppackage.package_cancellationNotes !== "" &&
                  this.state.pouppackage.package_cancellationNotes !== null && (
                    <>
                      <h5>
                        Cancellation <span>Details</span>
                      </h5>
                      <ul>
                        {this.state.pouppackage.package_cancellationNotes
                          .split("#$")
                          .map((item, index2) => {
                            if (item !== "" && item !== null) {
                              return <li key={index2}>{Parser(item)}</li>;
                            }
                          })}
                      </ul>
                    </>
                  )}

                {this.state.pouppackage.package_termsAndConditions !== "" &&
                  this.state.pouppackage.package_termsAndConditions !==
                    null && (
                    <>
                      <h5>
                        Terms & <span>Conditions</span>
                      </h5>

                      {Parser(
                        this.state.pouppackage.package_termsAndConditions
                      )}
                    </>
                  )}

                <h5>Location</h5>
                <ul>
                  <li>
                    {this.state.poupproduct.products_addressLine}-{" "}
                    {this.state.poupproduct.products_postalCode}
                  </li>
                </ul>
                {this.state.poupproduct.product_operatingHours !== "" &&
                  this.state.poupproduct.product_operatingHours !== null && (
                    <>
                      <h5>
                        Opening <span>hours</span>
                      </h5>
                      <ul>
                        <li>
                          {Parser(
                            this.state.poupproduct.product_operatingHours
                          )}
                        </li>
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </ModalPopup>
        )}
        <ModalPopup
          open={this.state.showCalendarPopup}
          handleClose={() => this.setState({ showCalendarPopup: false })}
          classNames="common-popup edit-date-popup ttd-edit-popup"
        >
          <h2 className="ttd-calender-title">Select your visit date</h2>
          <div
            className={`ttd-banner-card${this.state.showSlotList ? "2" : "1"}`}
            style={this.state.showSlotList ? {} : cardBackGroundImage}
          >
            <div className="ttd-banner-col1">
              <div className="ttd-banner-title">
                {this.state.productdetail.products_name}
              </div>
              <div className="ttd-banner-sub">{selectedItem?.package_name}</div>
            </div>
            {this.state.showSlotList && (
              <div className="ttd-banner-col2">
                <Button
                  startIcon={<EditIcon />}
                  variant="text"
                  className="edit-visitdate"
                  onClick={() => this.setState({ showSlotList: false })}
                >
                  Edit visit date
                </Button>
              </div>
            )}
          </div>
          {/* {this.state.showSlotList && (
                <div className="p-date">
                  {moment(this.state.selectedDate).format("DD MMMM YYYY")}
                </div>
              )} */}
          {!this.state.showSlotList && (
            <>
              <div className="step-content-search">
                <div className="step-content-calender textcenter">
                  <Calendar
                    onChange={this.handleSelectDate}
                    minDate={new Date()}
                    date={new Date(this.state.selectedDate)}
                    disabledDay={this.calendarTileContent}
                    maxDate={
                      new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000)
                    }
                    months={this.state.isMobile ? 1 : 2}
                    direction="horizontal"
                  />
                </div>
              </div>
              <div className="ttd-calendar-action">
                <button
                  onClick={() => {
                    this.state.isDateSelected &&
                      this.setState({ showSlotList: true });
                  }}
                >
                  Continue
                </button>
              </div>
            </>
          )}
          {this.state.showSlotList && (
            <div className="step-2">
              {selectedItem?.dateAvailability?.slotList.length > 0 && (
                <>
                  <div className="title">Select your preferred time slot</div>
                  <div className="slot-list">
                    {selectedItem?.dateAvailability?.slotList.length > 0 &&
                      selectedItem.dateAvailability.slotList.map(
                        (slotItem, slotIndex) => (
                          <div
                            className={`slot-col ${
                              this.state.selectedItem?.selected_slot?.some(
                                ({ slot_globaltix_id }) =>
                                  slot_globaltix_id ===
                                  slotItem.slot_globaltix_id
                              )
                                ? "selected"
                                : ""
                            }`}
                            key={slotIndex}
                            value={slotItem.slot_globaltix_id}
                            onClick={() => {
                              this.selectTimeSlot(
                                this.state.productdetail,
                                this.state.selectedIndex,
                                slotItem
                              );
                            }}
                          >
                            {slotItem.slot_time_format}
                          </div>
                        )
                      )}
                  </div>
                </>
              )}
              <div className="title">Select your tickets</div>
              <div className="ttd-slot-selection-card">
                {this.passengerSelection(
                  this.state.selectedItem,
                  this.state.selectedIndex,
                  true,
                  false
                )}
              </div>
              <div className="actions ttd-popup-action-footer">
                <div className="ttd-footer-total">
                  {this.state.productdetail.product_package.map(
                    (activityItem, activityIndex) => {
                      let totalPrice = 0;
                      let startingPrice = [];
                      // Calculate total price
                      if (activityItem.package_price.length > 0) {
                        activityItem.package_price.forEach((ticketItem) => {
                          if (
                            ticketItem.quantity !== "" &&
                            ticketItem.quantity !== undefined
                          ) {
                            totalPrice +=
                              parseFloat(ticketItem.package_pricenettPrice) *
                              parseInt(ticketItem.quantity);
                          }
                        });
                      }

                      // Return JSX when totalPrice <= 0 and package_id doesn't match
                      return totalPrice <= 0 &&
                        this.state.selectedPackageId !==
                          activityItem.package_id ? null : (
                        <strong>
                          Total{" "}
                          <span>
                            {showPriceValue(
                              totalPrice,
                              this.state.currentCurrency,
                              this.state.currencyRate
                            )}
                          </span>
                        </strong>
                      );
                    }
                  )}
                  <p>Total Excludes Taxes and Fees</p>
                </div>
                <div className="selectbtn">
                  <a
                    href={void 0}
                    id={"packagebtnpop_" + this.state.selectedItem.package_id}
                    onClick={this.selectPackage.bind(
                      this,
                      this.state.productdetail,
                      this.state.selectedItem
                    )}
                    className="button"
                  >
                    Continue
                  </a>
                </div>
              </div>
              {/* <div className="step-content-search">
                {this.state.productdetail.product_package.map(
                  (activityItem, activityIndex) => {
                    let totalPrice = 0;

                    // Calculate total price
                    if (activityItem.package_price.length > 0) {
                      activityItem.package_price.forEach((ticketItem) => {
                        if (
                          ticketItem.quantity !== "" &&
                          ticketItem.quantity !== undefined
                        ) {
                          totalPrice +=
                            parseFloat(ticketItem.package_pricenettPrice) *
                            parseInt(ticketItem.quantity);
                        }
                      });
                    }

                    // Conditional rendering
                    if (
                      totalPrice < 0 ||
                      this.state.selectedPackageId !== activityItem.package_id
                    ) {
                      return null;
                    }

                    // Return JSX when totalPrice <= 0 and package_id doesn't match
                    return (
                      <strong key={activityIndex}>
                        Total{" "}
                        <span>
                          {showPriceValue(
                            totalPrice,
                            this.state.currentCurrency,
                            this.state.currencyRate
                          )}
                        </span>
                      </strong>
                    );
                  }
                )}
              </div> */}
            </div>
          )}
        </ModalPopup>
        <AlertPopup
          open={this.state.openAlertPopup}
          handleClose={() => this.setState({ openAlertPopup: false })}
          title={this.state.popupTitle}
          message={this.state.alertPopupMsg}
        />

        <ModalPopup
          open={this.state.showSelectInfo}
          handleClose={() => this.setState({ showSelectInfo: false })}
          className="searchContainer__bottomSheetMain"
        >
          <div className="searchContainer__bottomSheetMain__container ttd-pk-select">
            <div className="s-title">Select your visit date</div>
            <div
              className={`ttd-banner-card${
                this.state.showSlotList ? "2" : "1"
              }`}
              style={this.state.showSlotList ? {} : cardBackGroundImage}
            >
              {this.state.showSlotList && (
                <div className="ttd-banner-col2">
                  <Button
                    startIcon={<EditIcon />}
                    variant="text"
                    className="edit-visitdate"
                    onClick={() => this.setState({ showSlotList: false })}
                  >
                    Change date
                  </Button>
                </div>
              )}
              <div className="ttd-banner-col1">
                <div className="ttd-banner-title">
                  {this.state.productdetail.products_name}
                </div>
                <div className="ttd-banner-sub">
                  {selectedItem?.package_name}
                </div>
              </div>
            </div>
            {/* <div className="pp-info">
              <div className="pp-info-blue">
                <ul>
                  <li>
                    <img src={calday} alt="calday" />
                    {this.state.pouppackage.package_ticketValidity ===
                    "Duration"
                      ? " Open dated valid upto " +
                        this.state.pouppackage.package_definedDuration +
                        " days"
                      : "Fixed Date"}
                  </li>
                  <li>
                    <img src={dollar} alt="dollar" />

                    {this.state.pouppackage.package_ticketFormat === "QRCODE"
                      ? "Cancellable and refundable"
                      : "Non Cancellable and non refundable"}
                  </li>
                  {this.state.poupproduct.product_instantconfirmation ===
                    "1" && (
                    <li>
                      <img src={instant_confirmation} alt="confirmation" />
                      Instant confirmation
                    </li>
                  )}
                </ul>
              </div>
            </div> */}
            {/* <div className="ttd-header">
              <div>
                <div className="ttd-title">{this.state.productdetail.products_name}</div>
                <div className="p-title">{this.state.selectedItem?.package_name}</div>
                {this.state.showSlotList && <div className="p-date">{moment(this.state.selectedDate).format('DD MMMM YYYY')}</div>}
              </div>
              {this.state.showSlotList && 
                <Button startIcon={<EditIcon />} variant="text" className="edit-visitdate" onClick={() => this.setState({showSlotList: false})}>Edit visit date</Button>
              }
            </div> */}

            {!this.state.showSlotList && (
              <div className="searchContainer__bottomSheetMain__container__date ttd-date-card">
                <Calendar
                  onChange={this.handleSelectDate}
                  minDate={new Date()}
                  date={new Date(this.state.selectedDate)}
                  disabledDay={this.calendarTileContent}
                  maxDate={new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000)}
                  months={this.state.isMobile ? 1 : 2}
                  direction="horizontal"
                />
                <button
                  onClick={() => {
                    this.state.isDateSelected &&
                      this.setState({ showSlotList: true });
                  }}
                >
                  Continue
                </button>
              </div>
            )}
            {this.state.showSlotList && (
              <div className="step-2">
                {this.state.selectedItem?.dateAvailability?.slotList.length >
                  0 && (
                  <>
                    <div className="s-title">
                      Select your preferred time slot
                    </div>
                    <div className="slot-list ttd-time-slot-list">
                      {this.state.selectedItem?.dateAvailability?.slotList
                        .length > 0 &&
                        this.state.selectedItem.dateAvailability.slotList.map(
                          (slotItem, slotIndex) => (
                            <div
                              className={`slot-col ${
                                this.state.selectedItem?.selected_slot?.some(
                                  ({ slot_globaltix_id }) =>
                                    slot_globaltix_id ===
                                    slotItem.slot_globaltix_id
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              key={slotIndex}
                              value={slotItem.slot_globaltix_id}
                              onClick={() => {
                                this.selectTimeSlot(
                                  this.state.productdetail,
                                  this.state.selectedIndex,
                                  slotItem
                                );
                              }}
                            >
                              {slotItem.slot_time_format}
                            </div>
                          )
                        )}
                    </div>
                  </>
                )}
                <div className="s-title">Select your tickets</div>
                <div className="step-content-search">
                  {this.passengerSelection(
                    this.state.selectedItem,
                    this.state.selectedIndex,
                    true,
                    false
                  )}
                </div>
                <div className="ttd-popup-action-footer">
                  <div className="step-content-search">
                    {this.state.productdetail.product_package.map(
                      (activityItem, activityIndex) => {
                        let totalPrice = 0;

                        // Calculate total price
                        if (activityItem.package_price.length > 0) {
                          activityItem.package_price.forEach((ticketItem) => {
                            if (
                              ticketItem.quantity !== "" &&
                              ticketItem.quantity !== undefined
                            ) {
                              totalPrice +=
                                parseFloat(ticketItem.package_pricenettPrice) *
                                parseInt(ticketItem.quantity);
                            }
                          });
                        }

                        // Conditional rendering
                        if (
                          totalPrice < 0 ||
                          this.state.selectedPackageId !==
                            activityItem.package_id
                        ) {
                          return null;
                        }

                        // Return JSX when totalPrice <= 0 and package_id doesn't match
                        return (
                          <strong key={activityIndex}>
                            Total{" "}
                            <span>
                              {showPriceValue(
                                totalPrice,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </span>
                          </strong>
                        );
                      }
                    )}
                    <p>Total Excludes Taxes and Fees</p>
                  </div>
                  <div className="selectbtn">
                    <a
                      href={void 0}
                      id={"packagebtnpop_" + this.state.selectedItem.package_id}
                      onClick={this.selectPackage.bind(
                        this,
                        this.state.productdetail,
                        this.state.selectedItem
                      )}
                      className="button"
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="ttd-p-rhs">
              {this.state.selectedItem?.package_price?.length > 0 && (
                <ul>
                  {this.state.selectedItem.package_price.map(
                    (ticketItem, ticketIndex) => {
                      var checkingTicketType = "No";
                      if (ticketItem.package_price_name === "ADULT") {
                        if (this.state.adults > 0) {
                          checkingTicketType = "Yes";
                        }
                      } else if (ticketItem.package_price_name === "CHILD") {
                        if (this.state.child > 0) {
                          checkingTicketType = "Yes";
                        }
                      } else {
                        var totalPQty =
                          parseInt(this.state.adults) +
                          parseInt(this.state.child);
                        if (
                          totalPQty > 0 &&
                          this.state.selectedItem.package_price.length === 1
                        ) {
                          checkingTicketType = "Yes";
                        }
                      }
                      if (checkingTicketType === "Yes") {
                        return (
                          <li key={ticketIndex}>
                            <div className="ad-ingo">
                              <p>
                                {ticketItem.package_price_name}{" "}
                                {((ticketItem.package_price_maxPurchaseQty !==
                                  "" &&
                                  ticketItem.package_price_maxPurchaseQty !==
                                    null) ||
                                  (ticketItem.package_price_minPurchaseQty !==
                                    "" &&
                                    ticketItem.package_price_minPurchaseQty !==
                                      null)) && (
                                  <em>
                                    {ticketItem.package_price_minPurchaseQty !==
                                      "" &&
                                      ticketItem.package_price_minPurchaseQty !==
                                        null &&
                                      "Min " +
                                        ticketItem.package_price_minPurchaseQty}{" "}
                                    -{" "}
                                    {ticketItem.package_price_maxPurchaseQty !==
                                      "" &&
                                      ticketItem.package_price_maxPurchaseQty !==
                                        null &&
                                      "Max " +
                                        ticketItem.package_price_maxPurchaseQty}
                                  </em>
                                )}
                              </p>
                            </div>
                            <div className="ad-ingo-rhs">
                              <div className="qty-bx">
                                <span
                                  className="qty-minus"
                                  onClick={this.incdec.bind(
                                    this,
                                    this.state.selectedIndex,
                                    ticketItem.package_price_id,
                                    "dec"
                                  )}
                                >
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <input
                                  type="text"
                                  className="qty-input"
                                  readOnly={true}
                                  value={
                                    ticketItem.quantity !== "" &&
                                    typeof ticketItem.quantity !== undefined &&
                                    typeof ticketItem.quantity !== "undefined"
                                      ? ticketItem.quantity
                                      : ""
                                  }
                                />
                                <span
                                  className="qty-plus"
                                  onClick={this.incdec.bind(
                                    this,
                                    this.state.selectedIndex,
                                    ticketItem.package_price_id,
                                    "inc"
                                  )}
                                >
                                  {" "}
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              )}
            </div>

            <div className="selectBtn">
              <a
                href={void 0}
                id={"packagebtnpop_" + this.state.activityItem.package_id}
                onClick={
                  this.state.ttdOnly
                    ? () =>
                        this.checkAvailability(
                          this.state.activityIndex,
                          this.state.activityItem,
                          this.state.productdetail
                        )
                    : this.selectPackage.bind(
                        this,
                        this.state.productdetail,
                        this.state.activityItem
                      )
                }
                className="button"
              >
                Select
              </a>
            </div> */}
          </div>
        </ModalPopup>
        {!this.props.drawerOpened && <Footer {...this.props} />}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var productdetailArr = Array();
  var common = Array();
  if (Object.keys(state.productdetails).length > 0) {
    if (state.productdetails[0].status === "ok") {
      productdetailArr = state.productdetails[0].result_set;
      common = state.productdetails[0].common;
    }
  }

  var settingArr = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      settingArr = state.settings[0].result_set;
    }
  }

  return {
    productdetail: productdetailArr,
    common: common,
    globalSetting: settingArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetails: (params) => {
      dispatch({ type: GET_PRODUCTDETAILS, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Productdetail);
