import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
// import store from "../src/redux/store";
import "./common/css/font-awesome.css";
import "./common/css/style.css";
import "./common/css/responsive-style.css";
import "./common/css/airline.css";
import "./common/css/owl.carousel.css";
import Countries from "./components/Home/Countries";
import Customerattractions from "./components/Customerattractions/Index";
import Mybooking from "./components/Myaccount/Mybooking";
import Profile from "./components/Myaccount/Profile";
import Wishlist from "./components/Myaccount/Wishlist";
import PublicProfile from "./components/Myaccount/PublicProfile";
import Cancel from "./components/Myaccount/Cancel";
import Thankyou from "./components/Checkout/Thankyou";
import Account from "./components/Account/Account";
import CreatorAccount from "./components/Account/CreatorAccount";
import Promo from "./components/Promotions/Promo";
import FunThings from "./components/Pages/FunThings";
import Logout from "./components/Myaccount/Logout";
import Cancelrequest from "./components/Pages/Cancelrequest";
/* Customer */
import Customerpackage from "./components/Customerpackage/Customerpackage";
import Activitiesdetail from "./components/Customerpackage/Activitiesdetail";
import Customerstaydetail from "./components/Customerpackage/Customerstaydetail";
import Userflightdetail from "./components/Customerpackage/Userflightdetail";
import UsertranferSelection from "./components/Customerpackage/UsertranferSelection";
import Staylist from "./components/Customerpackage/Staylist";
import Customerpackageactivities from "./components/Customerpackage/Customerpackageactivities";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import Termsandconditions from "./components/Policies/Termsandconditions";
import Cancellation from "./components/Policies/Cancellation";
import Page404 from "./Page404";
/* About Us */
import Aboutus from "./components/Pages/Aboutus";
import Helpcenter from "./components/Pages/Helpcenter";
import Refpage from "./components/Layout/Refpage";
import VacationSummary from "./components/VacationSummary/VacationSummary";
const Home = lazy(() => import("./components/Home/Home"));
const Customerpackagedetail = lazy(() =>
  import("./components/Customerpackage/Customerpackagedetail")
);
const Checkout = lazy(() => import("./components/Checkout/Index"));
const Process = lazy(() => import("./components/Checkout/Process"));
const Customerflightselection = lazy(() =>
  import("./components/Customerpackage/Customerflightselection")
);
const Customerattractiondetails = lazy(() =>
  import("./components/Customerattractions/Productdetail")
);

const Fallback = () => (
  <div id="dvLoading">
    <div className="loader triangle">
      <svg viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="black"
          strokeWidth="3"
          fill="transparent"
        />
      </svg>
    </div>
  </div>
);

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        {/* Customer Section Start */}

        <Suspense fallback={<Fallback />}>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/countries_profile/:countryName"
            component={Countries}
          />
          <Route
            path="/search-package/:packageSlug/flight"
            component={Customerflightselection}
          />
          <Route
            path="/search-package/:packageSlug/new-flight"
            component={Customerflightselection}
          />

          <Route
            path="/search-package/:packageSlug/flight-details"
            component={Userflightdetail}
          />
          <Route
            path="/search-package/:packageSlug/transfer"
            component={UsertranferSelection}
          />

          <Route
            exact
            path="/search-package/:packageSlug/all-activities/:country"
            component={Customerattractions}
          />
          <Route
            exact
            path="/search-package/:packageSlug/all-activities/:country/:productslug"
            component={Customerattractiondetails}
          />

          <Route
            exact
            path="/search-package/:packageSlug/activities/:activitiesSlug"
            component={Activitiesdetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/stay/:country/:city"
            component={Staylist}
          />
          <Route
            exact
            path="/search-package/:packageSlug/stay/:hotelID"
            component={Customerstaydetail}
          />

          <Route
            exact
            path="/search-package/:packageSlug"
            component={Customerpackagedetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/:packageType/share"
            component={Customerpackagedetail}
          />
          <Route
            exact
            path="/search-package/:packageSlug/creatorshare"
            component={Customerpackagedetail}
          />
          <Route exact path="/summary" component={VacationSummary} />
          <Route exact path="/search-package" component={Customerpackage} />
          <Route
            exact
            path="/packages/:countryName/:cityName"
            component={Customerpackage}
          />
          <Route exact path="/packages/:cityName" component={Customerpackage} />
          <Route
            exact
            path="/:packageSlug/package-activities"
            component={Customerpackageactivities}
          />

          {/* Customer Section End */}

          <Route exact path="/booking" component={Checkout} />
          {/* Creator Section End */}

          {/*  Extra */}

          <Route exact path="/process_order" component={Process} />
          <Route exact path="/thankyou/:bookingID" component={Thankyou} />
          {/* <Route exact path="/help" component={Help} /> */}
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/public_profile" component={PublicProfile} />
          <Route exact path="/mybooking" component={Mybooking} />
          <Route exact path="/mybooking/:bookingID" component={Mybooking} />
          <Route exact path="/cancellation" component={Cancel} />
          <Route
            path="/account/activation/:activationKey"
            component={Account}
          />
          <Route path="/account/resetpassword/:resetKey" component={Home} />
          <Route
            path="/creatoraccount/activation/:activationKey"
            component={CreatorAccount}
          />
          <Route
            path="/creatoraccount/resetpassword/:resetKey"
            component={Home}
          />
          <Route path="/promotions/:promoslug" component={Promo} />
          <Route path="/fun-things/:funthingslug" component={FunThings} />
          <Route path="/cancell-request/:requestID" component={Cancelrequest} />
          <Route path="/cancell-request" component={Cancelrequest} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/helpcenter" component={Helpcenter} />

          <Route exact path="/logout" component={Logout} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={Termsandconditions} />
          <Route path="/cancellation-and-refund" component={Cancellation} />
          <Route path="/refpage/:slugtext" component={Refpage} />
          <Route path="/refpage/" component={Refpage} />
          <Route path="/404" component={Page404} />
        </Suspense>
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
