import { Box, IconButton, Modal, Slide, Fade } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./ModalPopup.scss";
import CloseIcon from "@mui/icons-material/Close";
import "./ModalPopup.scss";
var isMobile = window.innerWidth <= 480;
const Transition = React.forwardRef(function Transition(props, ref) {
  return isMobile ? (
    <Slide direction="up" ref={ref} {...props} />
  ) : (
    <Fade ref={ref} {...props} />
  );
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "100%",
  overflowY: "scroll",
};

const ModalPopup = (props) => {
  const { open, handleClose, children, classNames, modalWidth, preventClose } =
    props;
  const modalRef = useRef(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        modalRef.current?.focus();
      }, 100); // Wait for modal animation to complete
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={!preventClose ? handleClose : null}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Transition in={open} timeout={300}>
        <Box
          sx={{ ...style, minWidth: modalWidth }}
          className={`modal-popup-main`}
          ref={modalRef}
          tabIndex={-1}
        >
          <div className={`${classNames}`}>
            {!preventClose && (
              <IconButton onClick={handleClose} className="close-btn">
                <CloseIcon htmlColor="#4258BF" />
              </IconButton>
            )}
            {children}
          </div>
        </Box>
      </Transition>
    </Modal>
  );
};

export default ModalPopup;
