/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "react-light-accordion/demo/css/index.css";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import { mediaUrl, apiglobaltixMediaUrl } from "../Helpers/Config";
import { showPriceValue, stripslashes } from "../Helpers/SettingHelper";
import {
  dollar,
  mpin,
  loader,
  placeholder,
  closeicon,
  instant_confirmation,
  calday,
} from "../Helpers/Images";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_PRODUCTDETAILS } from "../../actions";
var Parser = require("html-react-parser");
var settingsgallery = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
};
var mobilesteps = {
  items: 1,
  loop: true,
  dots: true,
  nav: false,
  autoplay: true,
};
class Activitiesdetail extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";

    var selectedactivity = "";
    if (
      this.props.location.state.selectedactivity !== "" &&
      typeof this.props.location.state.selectedactivity !== undefined &&
      typeof this.props.location.state.selectedactivity !== "undefined"
    ) {
      selectedactivity = this.props.location.state.selectedactivity;
    }
    this.state = {
      userID: userID,
      selectedactivity: selectedactivity,
      productdetail: "",
      totalPrice: 0,
      selectedTicketType: [],
      selectedpackage: "",
      selectedpackageId: "",
      showspersion: "",
      displaypersion: "",
      selectedDate: new Date(),
      mindate: new Date(),
      includeDates: [],
      maxdate: "",
      triggerLogin: false,
      returnloginresult: false,
      errormsg: "",
      timeList: "",
      displyaTimeSlot: [],
      eventTime: "",
      loadTimeSlot: false,
      showTimeError: false,
      rating_categories: [],
      writeReview: false,
      ratingCategory: "",
      reatingMessage: "",
      error_rating: "",
      error_ratingCategory: "",
      error_reatingMessage: "",
      allimages: "",
      allgalleryimages: "",
      slidergalleryimages: "",
      enableDateWaring: false,
      durationdate: 0,
      product_slug: this.props.match.params.activitiesSlug,
      pouppackage: "",
      poupproduct: "",
      showdateError: "",
      isMobile: window.innerWidth <= 480,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
    };
    var params = {
      product_slug: this.props.match.params.activitiesSlug,
      userID: userID,
    };

    this.props.getProductDetails(params);
  }
  componentDidMount() {
    $(document).ready(function () {
      $(".lightbox-cats").magnificPopup({
        type: "image",
        gallery: { enabled: true },
        // other options
      });
      $("body").on("click", ".loadmore", function () {
        if ($(this).parents(".package_description").length > 0) {
          if ($(this).parents(".package_description.active").length > 0) {
            $(this).parents(".package_description").removeClass("active");
            var remorethis = this;
            setTimeout(function () {
              $(remorethis)
                .parents(".package_description")
                .find(".loadmore")
                .html("Read More");
            }, 500);
          } else {
            $(this).parents(".package_description").addClass("active");
            $(this)
              .parents(".package_description")
              .find(".loadmore")
              .html("Read Less");
          }
        }
      });
    });
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.productdetail !== this.state.productdetail) {
      this.setState({ productdetail: PropsData.productdetail }, function () {
        this.loadAllImages();
      });
    }
    if (
      this.state.product_slug !== "" &&
      this.state.product_slug !== PropsData.match.params.activitiesSlug
    ) {
      this.setState(
        { product_slug: PropsData.match.params.activitiesSlug },
        function () {
          var params = {
            product_slug: this.props.match.params.activitiesSlug,
            userID: this.state.userID,
          };
          this.handleChangeDate = this.handleChangeDate.bind(this);
          this.props.getProductDetails(params);
        }
      );
    }
  }

  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }

  loadAllImages() {
    var products = this.state.productdetail;
    if (Object.keys(products).length > 0) {
      var allimages = "";
      var allgalleryimages = "";
      var slidergalleryimages = "";
      if (Object.keys(products.additional_image).length > 0) {
        var additional_image = products.additional_image;
        allimages = Object.keys(products.additional_image).map(
          (item, index) => {
            return (
              <a
                key={index}
                href={mediaUrl + "products/gallery/" + additional_image[item]}
                className="lightbox-cats"
              >
                <img
                  src={mediaUrl + "products/gallery/" + additional_image[item]}
                  alt=""
                />
              </a>
            );
          }
        );
      }

      if (products.product_gallery.length > 0) {
        allgalleryimages = products.product_gallery.map((item, index) => {
          return (
            <a
              key={index}
              href={apiglobaltixMediaUrl + item}
              className="lightbox-cats"
            >
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </a>
          );
        });
      }
      if (products.product_gallery.length > 0) {
        slidergalleryimages = products.product_gallery.map((item, index) => {
          return (
            <div key={index}>
              <img src={apiglobaltixMediaUrl + item} alt="" />
            </div>
          );
        });
      }
      this.setState(
        {
          allimages: allimages,
          allgalleryimages: allgalleryimages,
          slidergalleryimages: slidergalleryimages,
        },
        function () {
          setTimeout(function () {
            $(".lightbox-cats").magnificPopup({
              type: "image",
              gallery: { enabled: true },
            });
          }, 2000);
        }
      );
    }
  }
  loadImage(products) {
    var firstImg = "";
    var secondImg = "";
    var thirdImg = "";
    var fourthImg = "";

    if (Object.keys(products.additional_image).length > 0) {
      if (
        products.additional_image[1] !== "" &&
        typeof products.additional_image[1] !== undefined &&
        typeof products.additional_image[1] !== "undefined"
      ) {
        firstImg =
          mediaUrl + "products/gallery/" + products.additional_image[1];
      }
      if (
        products.additional_image[2] !== "" &&
        typeof products.additional_image[2] !== undefined &&
        typeof products.additional_image[2] !== "undefined"
      ) {
        secondImg =
          mediaUrl + "products/gallery/" + products.additional_image[2];
      }
      if (
        products.additional_image[3] !== "" &&
        typeof products.additional_image[3] !== undefined &&
        typeof products.additional_image[3] !== "undefined"
      ) {
        thirdImg =
          mediaUrl + "products/gallery/" + products.additional_image[3];
      }
      if (
        products.additional_image[4] !== "" &&
        typeof products.additional_image[4] !== undefined &&
        typeof products.additional_image[4] !== "undefined"
      ) {
        fourthImg =
          mediaUrl + "products/gallery/" + products.additional_image[4];
      }
    }
    if (products.product_gallery.length > 0) {
      if (firstImg === "") {
        firstImg =
          products.merchant_id !== null && products.merchant_id !== "0"
            ? mediaUrl + "products/gallery/" + products.product_gallery[0]
            : apiglobaltixMediaUrl + products.product_gallery[0];
      }
      if (secondImg === "") {
        secondImg =
          products.product_gallery[1] !== "" &&
          typeof products.product_gallery[1] !== undefined &&
          typeof products.product_gallery[1] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[1]
              : apiglobaltixMediaUrl + products.product_gallery[1]
            : "";
      }
      if (thirdImg === "") {
        thirdImg =
          products.product_gallery[2] !== "" &&
          typeof products.product_gallery[2] !== undefined &&
          typeof products.product_gallery[2] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[2]
              : apiglobaltixMediaUrl + products.product_gallery[2]
            : "";
      }
      if (fourthImg === "") {
        fourthImg =
          products.product_gallery[3] !== "" &&
          typeof products.product_gallery[3] !== undefined &&
          typeof products.product_gallery[3] !== "undefined"
            ? products.merchant_id !== null && products.merchant_id !== "0"
              ? mediaUrl + "products/gallery/" + products.product_gallery[3]
              : apiglobaltixMediaUrl + products.product_gallery[3]
            : "";
      }

      if (products.product_gallery.length >= 4) {
        return !this.state.isMobile ? (
          <section className="det-banner four-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="" />
                </div>
                <div
                  className="det-banner-rhs-bottom-rhs product-image-more"
                  onClick={this.openGallery.bind(this)}
                >
                  <span>show all photos</span>
                  <img src={fourthImg} alt="" />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={thirdImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={fourthImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 3) {
        return !this.state.isMobile ? (
          <section className="det-banner three-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
              <div className="det-banner-rhs-bottom">
                <div className="det-banner-rhs-bottom-lhs">
                  <img src={thirdImg} alt="" />
                </div>
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={thirdImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 2) {
        return !this.state.isMobile ? (
          <section className="det-banner two-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-rhs">
              <div className="det-banner-rhs-top">
                <img src={secondImg} alt="" />
              </div>
            </div>
          </section>
        ) : (
          <OwlCarousel options={mobilesteps}>
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
            <div className="det-banner-lhs">
              <img src={secondImg} alt="" />
            </div>
          </OwlCarousel>
        );
      } else if (products.product_gallery.length === 1) {
        return (
          <section className="det-banner single-gallery">
            <div className="det-banner-lhs">
              <img src={firstImg} alt="" />
            </div>
          </section>
        );
      }
    } else {
      return (
        <section className="det-banner single-gallery">
          <div className="det-banner-lhs">
            <img src={placeholder} alt="airvayslogo" />
          </div>
        </section>
      );
    }
  }

  openGallery() {
    $(".lightbox-cats:first-child").trigger("click");
  }
  openPackageInfo(poupproduct, pouppackage) {
    this.setState(
      { poupproduct: poupproduct, pouppackage: pouppackage },
      function () {
        $.magnificPopup.open({
          items: {
            src: "#pacakge_popup",
          },
          type: "inline",
        });
      }
    );
  }
  closeDate() {
    $.magnificPopup.close();
  }
  goback() {
    var packagedetails = this.props.location.state;
    this.props.history.push({
      pathname: "/search-package/" + this.props.match.params.packageSlug,
      state: packagedetails,
    });
  }
  sateValChange = (field, value) => {
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  render() {
    return (
      <>
        <Header
          {...this.props}
          triggerLogin={this.state.triggerLogin}
          returnloginresult={this.state.returnloginresult}
          sateValChange={this.sateValChange}
        />
        <div className="product-more-image">
          {this.state.allimages}
          {this.state.allgalleryimages}
        </div>
        <div className="product-mobile-gallery">
          {this.state.slidergalleryimages !== "" && (
            <OwlCarousel options={settingsgallery}>
              {this.state.slidergalleryimages}
            </OwlCarousel>
          )}
        </div>
        {this.state.productdetail !== "" &&
        Object.keys(this.state.productdetail).length > 0 ? (
          <>
            {this.loadImage(this.state.productdetail)}
            <section className="innersection">
              <div className="container">
                <div className="detail-bottom">
                  <div className="detail-bottom-inner">
                    <div className="detail-bottom-lhs">
                      <div className="detail-top-lhs">
                        <div className="detail-top-header">
                          <h1>
                            {stripslashes(
                              this.state.productdetail.products_name
                            )}
                          </h1>
                          {this.state.productdetail.product_category_name !==
                            "" &&
                            this.state.productdetail.product_category_name !==
                              null && (
                              <div className="product-description-tag">
                                <span>
                                  {
                                    this.state.productdetail
                                      .product_category_name
                                  }
                                </span>
                              </div>
                            )}
                        </div>
                        <div className="detail-top-detail">
                          <div className="detail-top-detail-parent">
                            <ul className="detail-top-detail-lhs">
                              {this.state.productdetail.product_package.length >
                                0 && (
                                <li>
                                  <img src={loader} alt="loader" /> Duration:{" "}
                                  {this.state.productdetail
                                    .product_isopendated === "1"
                                    ? "Open Dated"
                                    : "Fixed Date "}
                                </li>
                              )}

                              {this.state.productdetail.products_addressLine !==
                                "" &&
                                this.state.productdetail
                                  .products_addressLine !== null && (
                                  <li>
                                    <img src={mpin} alt="map" />
                                    <strong>Location:</strong>{" "}
                                    {
                                      this.state.productdetail
                                        .products_addressLine
                                    }
                                    -{" "}
                                    {
                                      this.state.productdetail
                                        .products_postalCode
                                    }
                                  </li>
                                )}
                            </ul>
                            <ul className="detail-top-detail-rhs">
                              <li>
                                <img src={dollar} alt="dollar" />
                                {this.state.productdetail.product_package
                                  .length > 0 &&
                                this.state.productdetail.product_package[0]
                                  .package_ticketFormat === "QRCODE"
                                  ? "Cancellable"
                                  : "Non Cancellable"}
                              </li>
                              {this.state.productdetail
                                .product_instantconfirmation === "1" && (
                                <li>
                                  <img
                                    src={instant_confirmation}
                                    alt="confirmation"
                                  />
                                  Instant confirmation
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="detail-top-detail-parent-rating">
                            {this.state.productdetail.rating !== "" &&
                            this.state.productdetail.rating !== null ? (
                              <span>
                                <strong>
                                  {this.state.productdetail.product_rating}
                                </strong>{" "}
                                ({this.state.productdetail.total_reviews})
                                Reviews
                              </span>
                            ) : (
                              <span>No Reviews</span>
                            )}
                            <div className="star-rating">
                              {this.loadRatingstar(
                                this.state.productdetail.rating
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.productdetail.product_package.length > 0 && (
                        <div className="detail-bottom-package">
                          <div className="detail-bottom-package-title">
                            <h4 className="bold-with-blue">
                              Packages for <span>you</span>{" "}
                            </h4>
                          </div>
                          <ul>
                            {this.state.productdetail.product_package.map(
                              (item, index) => {
                                if (
                                  item.package_id ===
                                  this.state.selectedactivity
                                    .activity_activity_package_id
                                ) {
                                  return (
                                    <li
                                      key={index}
                                      className={
                                        this.state.selectedpackageId ===
                                          item.package_id ||
                                        this.state.selectedpackageId.indexOf(
                                          item.package_id
                                        ) >= 0
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <div className="package-lhs package_description">
                                        <h6>{item.package_name}</h6>
                                        <div className="package-description-inner">
                                          {/* <p>
                                          {item.package_description !== "" &&
                                          item.package_description !== null
                                            ? Parser(
                                                stripslashes(
                                                  item.package_description
                                                )
                                              )
                                            : ""}
                                        </p> */}
                                        </div>
                                      </div>
                                      <div className="package-rhs">
                                        <div className="package-rhs-inner">
                                          <span>
                                            Package starts <em>from</em>
                                          </span>
                                          <strong>
                                            {showPriceValue(
                                              item.package_main_price,
                                              this.props.location.state
                                                ?.currentCurrency,
                                              this.props.location.state
                                                ?.currencyRate
                                            )}
                                          </strong>
                                          <p>
                                            <i
                                              className="fa fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                            Please read the package information
                                            before booking.
                                          </p>
                                        </div>
                                        <div className="package-rhs-inner-btn">
                                          <a
                                            href={void 0}
                                            onClick={this.openPackageInfo.bind(
                                              this,
                                              this.state.productdetail,
                                              item
                                            )}
                                            className="button packageinfo"
                                          >
                                            View Info
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              }
                            )}
                          </ul>
                        </div>
                      )}

                      <div className="policy-details">
                        <h4 className="bold-with-blue">
                          Essential <span>details</span>
                        </h4>

                        {this.state.selectedpackage !== "" &&
                        Object.keys(this.state.selectedpackage).length > 0 ? (
                          <>
                            {this.state.selectedpackage.package_inclusions !==
                              "" &&
                              this.state.selectedpackage.package_inclusions !==
                                null && (
                                <div className="include-box">
                                  <h5>What's included with the ticket:</h5>
                                  <ul>
                                    {this.state.selectedpackage.package_inclusions
                                      .split("#$")
                                      .map((item, index3) => {
                                        return <li key={index3}>{item}</li>;
                                      })}
                                  </ul>
                                </div>
                              )}

                            {this.state.selectedpackage
                              .package_termsAndConditions !== "" &&
                              this.state.selectedpackage
                                .package_termsAndConditions !== null && (
                                <>
                                  <h4>Terms and conditions:</h4>
                                  <p>
                                    {Parser(
                                      this.state.selectedpackage
                                        .package_termsAndConditions
                                    )}
                                  </p>
                                </>
                              )}
                            {this.state.selectedpackage.package_howtouse !==
                              "" &&
                              this.state.selectedpackage.package_howtouse !==
                                null && (
                                <>
                                  <h4>How to use the ticket:</h4>
                                  <p>
                                    {Parser(
                                      this.state.selectedpackage
                                        .package_howtouse
                                    )}
                                  </p>
                                </>
                              )}

                            {this.state.selectedpackage
                              .package_cancellationNotes !== "" &&
                              this.state.selectedpackage
                                .package_cancellationNotes !== null && (
                                <>
                                  <h4> Cancellation policy:</h4>
                                  {this.state.selectedpackage.package_cancellationNotes
                                    .split("#$")
                                    .map((item, index2) => {
                                      if (item !== "" && item !== null) {
                                        return (
                                          <p key={index2}>{Parser(item)}</p>
                                        );
                                      }
                                    })}
                                </>
                              )}
                          </>
                        ) : (
                          this.state.productdetail.products_description !==
                            "" &&
                          this.state.productdetail.products_description !==
                            null && (
                            <>
                              <h4>Description</h4>
                              <p>
                                {Parser(
                                  this.state.productdetail.products_description
                                )}
                              </p>
                            </>
                          )
                        )}
                        {this.state.productdetail.product_operatingHours !==
                          "" &&
                          this.state.productdetail.product_operatingHours !==
                            null && (
                            <>
                              <h4>Operating Hours</h4>
                              <p>
                                {Parser(
                                  this.state.productdetail
                                    .product_operatingHours
                                )}
                              </p>
                            </>
                          )}
                      </div>
                    </div>

                    <div className="detail-bottom-rhs">
                      {!this.state.isMobile && (
                        <div className="booknow-btm">
                          <a
                            href={void 0}
                            className="button"
                            onClick={this.goback.bind(this)}
                          >
                            Go Back
                          </a>
                        </div>
                      )}
                      {this.state.productdetail.product_latitude !== "" &&
                        this.state.productdetail.product_latitude !== null &&
                        this.state.productdetail.product_longitude !== "" &&
                        this.state.productdetail.product_longitude !== null && (
                          <div className="detail-bottom-rhs-map">
                            <iframe
                              width="350"
                              height="400"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                              src={
                                "https://maps.google.com/maps?q=" +
                                this.state.productdetail.product_latitude +
                                "," +
                                this.state.productdetail.product_longitude +
                                "&hl=en&z=14&output=embed"
                              }
                            ></iframe>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.poupproduct !== "" &&
                this.state.pouppackage !== "" && (
                  <div
                    id="pacakge_popup"
                    className="mfp-hide common-popup pacakge_popup"
                  >
                    <div className="cp-header textcenter">
                      <h3>
                        Package <span>information</span>
                      </h3>
                      {/* <a
                        className="date-package-popup"
                        href={void 0}
                        onClick={this.closeDate.bind(this)}
                      >
                        <img src={closeicon} />
                      </a> */}
                    </div>

                    <div className="pp-info">
                      <div className="pp-info-blue">
                        <ul>
                          <li>
                            <img src={calday} alt="calday" />
                            {this.state.pouppackage.package_ticketValidity ===
                            "Duration"
                              ? " Open dated valid upto " +
                                this.state.pouppackage.package_definedDuration +
                                " days"
                              : "Fixed Date"}
                          </li>
                          <li>
                            <img src={dollar} alt="dollar" />

                            {this.state.pouppackage.package_ticketFormat ===
                            "QRCODE"
                              ? "Cancellable and refundable"
                              : "Non Cancellable and non refundable"}
                          </li>
                          {this.state.poupproduct
                            .product_instantconfirmation === "1" && (
                            <li>
                              <img
                                src={instant_confirmation}
                                alt="confirmation"
                              />
                              Instant confirmation
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="pp-info-text">
                        <h5>
                          Package <span>title</span>
                        </h5>
                        <p>{this.state.pouppackage.package_name}</p>
                        {this.state.pouppackage.package_description !== "" &&
                          this.state.pouppackage.package_description !==
                            null && (
                            <>
                              <h5>
                                Package <span>Description</span>
                              </h5>
                              {Parser(
                                this.state.pouppackage.package_description
                              )}
                            </>
                          )}

                        {this.state.pouppackage.package_inclusions !== "" &&
                          this.state.pouppackage.package_inclusions !==
                            null && (
                            <>
                              <h5>
                                What's <span>Included</span>
                              </h5>

                              <ul>
                                {this.state.pouppackage.package_inclusions
                                  .split("#$")
                                  .map((item, index3) => {
                                    return <li key={index3}>{item}</li>;
                                  })}
                              </ul>
                            </>
                          )}
                        {this.state.pouppackage.package_howtouse !== "" &&
                          this.state.pouppackage.package_howtouse !== null && (
                            <>
                              <h5>
                                How to <span>Use</span>
                              </h5>
                              <ul>
                                {this.state.pouppackage.package_howtouse
                                  .split("#$")
                                  .map((item, index3) => {
                                    return <li key={index3}>{item}</li>;
                                  })}
                              </ul>
                            </>
                          )}

                        {this.state.pouppackage.package_cancellationNotes !==
                          "" &&
                          this.state.pouppackage.package_cancellationNotes !==
                            null && (
                            <>
                              <h5>
                                Cancellation <span>Details</span>
                              </h5>
                              <ul>
                                {this.state.pouppackage.package_cancellationNotes
                                  .split("#$")
                                  .map((item, index2) => {
                                    if (item !== "" && item !== null) {
                                      return (
                                        <li key={index2}>{Parser(item)}</li>
                                      );
                                    }
                                  })}
                              </ul>
                            </>
                          )}

                        {this.state.pouppackage.package_termsAndConditions !==
                          "" &&
                          this.state.pouppackage.package_termsAndConditions !==
                            null && (
                            <>
                              <h5>
                                Terms & <span>Conditions</span>
                              </h5>

                              {Parser(
                                this.state.pouppackage
                                  .package_termsAndConditions
                              )}
                            </>
                          )}

                        <h5>Location</h5>
                        <ul>
                          <li>
                            {this.state.poupproduct.products_addressLine}-{" "}
                            {this.state.poupproduct.products_postalCode}
                          </li>
                        </ul>
                        {this.state.poupproduct.product_operatingHours !== "" &&
                          this.state.poupproduct.product_operatingHours !==
                            null && (
                            <>
                              <h5>
                                Opening <span>hours</span>
                              </h5>
                              <ul>
                                <li>
                                  {Parser(
                                    this.state.poupproduct
                                      .product_operatingHours
                                  )}
                                </li>
                              </ul>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                )}
            </section>
          </>
        ) : (
          <div id="dvLoading">
            <div className="loader triangle">
              <svg viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  stroke="black"
                  stroke-width="3"
                  fill="transparent"
                />
              </svg>
            </div>
          </div>
        )}

        <Footer {...this.props} />
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row">
                <a
                  href={void 0}
                  className="button"
                  onClick={this.goback.bind(this)}
                >
                  Go Back
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var productdetailArr = Array();
  var common = Array();
  if (Object.keys(state.productdetails).length > 0) {
    if (state.productdetails[0].status === "ok") {
      productdetailArr = state.productdetails[0].result_set;
      common = state.productdetails[0].common;
    }
  }

  var settingArr = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      settingArr = state.settings[0].result_set;
    }
  }

  return {
    productdetail: productdetailArr,
    common: common,
    globalsettings: settingArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetails: (params) => {
      dispatch({ type: GET_PRODUCTDETAILS, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Activitiesdetail);
