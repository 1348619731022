/* eslint-disable */
import epen_ from "../../common/images/edit-pen.svg";
export const epen = epen_;

import actinfo1_ from "../../common/images/act-info1.jpg";
export const actinfo1 = actinfo1_;

import flightRight_ from "../../common/images/material-flight.svg";
export const flightRight = flightRight_;

import roundtick_ from "../../common/images/rounded-tick-icon.svg";
export const roundtick = roundtick_;

import search_ from "../../common/images/search.svg";
export const search = search_;

import wifiicon_ from "../../common/images/wifi-icon.svg";
export const wifiicon = wifiicon_;

import tvicon_ from "../../common/images/tv-icon.svg";
export const tvicon = tvicon_;

import baricon_ from "../../common/images/bar-icon.svg";
export const baricon = baricon_;

import car_ from "../../common/images/car-icon.svg";
export const car = car_;

import ticketact_ from "../../common/images/ticket-activity.svg";
export const ticketact = ticketact_;

import hotel_ from "../../common/images/hotel-icon.svg";
export const hotel = hotel_;

import flight_ from "../../common/images/flight-takeoff.svg";
export const flight = flight_;

import pplaceholder_ from "../../common/images/product-noimage.jpg";
export const pplaceholder = pplaceholder_;

import mpin_ from "../../common/images/map-pin.svg";
export const mpin = mpin_;

import bcal_ from "../../common/images/calender-banner.svg";
export const bcal = bcal_;

import bpro_ from "../../common/images/banner-profile.svg";
export const bpro = bpro_;

import plusb_ from "../../common/images/plus-b.svg";
export const plusb = plusb_;

import minusb_ from "../../common/images/minus-b.svg";
export const minusb = minusb_;

import calday_ from "../../common/images/calendar-day.svg";
export const calday = calday_;

import dollar_ from "../../common/images/dollar.svg";
export const dollar = dollar_;

import instant_confirmation_ from "../../common/images/instant_confirmation.png";
export const instant_confirmation = instant_confirmation_;

import close_ from "../../common/images/close.svg";
export const close = close_;

import barrow_ from "../../common/images/bluearrow.svg";
export const barrow = barrow_;

import sort_ from "../../common/images/sorting.svg";
export const sort = sort_;

import filter_ from "../../common/images/filter.svg";
export const filter = filter_;

import heart_ from "../../common/images/heart.svg";
export const heart = heart_;

import heartfill_ from "../../common/images/heart-fill.svg";
export const heartfill = heartfill_;

import searchlight_ from "../../common/images/search-light.svg";
export const searchlight = searchlight_;

import stario_ from "../../common/images/ios-star.svg";
export const stario = stario_;

import ticketblue_ from "../../common/images/ticket.svg";
export const ticketblue = ticketblue_;

import dollarblue_ from "../../common/images/dollar1.svg";
export const dollarblue = dollarblue_;

import stickclose_ from "../../common/images/stick-close.svg";
export const stickclose = stickclose_;

import loader_ from "../../common/images/loader-count.svg";
export const loader = loader_;

import placeholder_ from "../../common/images/detail-slider-noimg.jpg";
export const placeholder = placeholder_;

import closeicon_ from "../../common/images/close-icon.svg";
export const closeicon = closeicon_;

import process_ from "../../common/images/process-loader.gif";
export const process = process_;

import avholder_ from "../../common/images/placeholder.jpg";
export const avholder = avholder_;

import mappin_ from "../../common/images/map-pin-loader.gif";
export const mappin = mappin_;

import dots_ from "../../common/images/three-dots.svg";
export const dots = dots_;

import clockb_ from "../../common/images/clock-red.svg";
export const clockb = clockb_;

import dotloader_ from "../../common/images/dot-loader.gif";
export const dotloader = dotloader_;

import flighttop_ from "../../common/images/flight-path.svg";
export const flighttop = flighttop_;

import thingsblue_ from "../../common/images/things-blue.svg";
export const thingsblue = thingsblue_;

import staybed_ from "../../common/images/stay-bed.svg";
export const staybed = staybed_;

import greensmall_ from "../../common/images/green-tick-small.svg";
export const greensmall = greensmall_;

import information_ from "../../common/images/info-black.svg";
export const information = information_;

import successtick_ from "../../common/images/success-tick.gif";
export const successtick = successtick_;

import failedtick_ from "../../common/images/failed-animation.gif";
export const failedtick = failedtick_;

import packageIcon_ from "../../common/images/package.svg";
export const packageIcon = packageIcon_;

import failedIcon_ from "../../common/images/failed-cross-icon.svg";
export const failedIcon = failedIcon_;

import star_ from "../../common/images/star.svg";
export const star = star_;

import step2_ from "../../common/images/step2-icon.svg";
export const step2 = step2_;

import landingpath_ from "../../common/images/landing-path.svg";
export const landingpath = landingpath_;

import mobsearch_ from "../../common/images/search-blue.svg";
export const mobsearch = mobsearch_;

import hs1_ from "../../common/images/home-step.svg";
export const hs1 = hs1_;

import hs3_ from "../../common/images/home-step3.svg";
export const hs3 = hs3_;

import rgs_ from "../../common/images/rightend.png";
export const rgs = rgs_;

import crbox_ from "../../common/images/top-rated-cr.svg";
export const crbox = crbox_;

import crtool_ from "../../common/images/cr-tool.png";
export const crtool = crtool_;

import viewtool_ from "../../common/images/tdo1.png";
export const viewtool = viewtool_;

import viewtool1_ from "../../common/images/tdo2.png";
export const viewtool1 = viewtool1_;

import plus_ from "../../common/images/plus.svg";
export const plus = plus_;

import uparrow_ from "../../common/images/arrow-up.svg";
export const uparrow = uparrow_;

import swap_ from "../../common/images/swap-icon.svg";
export const swap = swap_;

import dtime_ from "../../common/images/depature-time.svg";
export const dtime = dtime_;

import chair_ from "../../common/images/class-cat.svg";
export const chair = chair_;

import stopicon_ from "../../common/images/stop-icon.svg";
export const stopicon = stopicon_;

import cardbg_ from "../../common/images/card-image.jpg";
export const cardbg = cardbg_;

import medal_ from "../../common/images/Creatorbadge.svg";
export const medal = medal_;

import radult_ from "../../common/images/room-adult.svg";
export const radult = radult_;

import parkicon_ from "../../common/images/park-icon.svg";
export const parkicon = parkicon_;

import discounticon_ from "../../common/images/discount-icon.svg";
export const discounticon = discounticon_;

import amenities_ from "../../common/images/amenities.svg";
export const amenities = amenities_;

import staricon_ from "../../common/images/star-icon.svg";
export const staricon = staricon_;

import halfstar_ from "../../common/images/starhalf-icon.png";
export const halfstar = halfstar_;

import food_ from "../../common/images/Food.png";
export const food = food_;

import guide_ from "../../common/images/Guide.png";
export const guide = guide_;

import priceChange_ from "../../common/images/pricechange.svg";
export const priceChange = priceChange_;

import priceChangeMap_ from "../../common/images/pricechangemap.svg";
export const priceChangeMap = priceChangeMap_;