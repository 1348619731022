/* eslint-disable */
import React, { Component } from "react";
import NewSignup from "../Myaccount/NewSignup";
import GoogleLogin from "react-google-login";
import { fbAppId, googleAppId, siteUrl } from "../Helpers/Config";
import FacebookLogin from "react-facebook-login";
import { Link } from "react-router-dom";
import { showLoader } from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import {
  GET_FBLOGINDATA,
  GET_CUSTOMER_LOGINDATA,
  GET_CUSTOMER_REGISTRATION,
  GET_GOOGLELOGINDATA,
} from "../../actions";
import GoogleLoginComponent from "./GoogleloginComponent/GoogleloginComponent";
var base64 = require("base-64");
var qs = require("qs");

class SignupComponent extends Component {
  handleFormSubmit = (formData) => {
    var postObject = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_password: base64.encode(formData.confirmPassword),
      customer_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
    };
    showLoader("signup_submits", "class");
    this.props.getCustomerRegistration(qs.stringify(postObject));
  };

  loginGoogle(response) {
    if (response.name && response.email) {
      var nameParts = response.name.split(" ");
      var postObject = {
        login_firstname: nameParts[0],
        login_lastname: nameParts[1] || "",
        login_username: response.email,
        customer_google_id: response.id,
      };
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  }

  responseFacebook = (response) => {
    if (response.name && response.email) {
      var postGender =
        response.gender === "male"
          ? "M"
          : response.gender === "female"
          ? "F"
          : "";

      var postObject = {
        login_firstname:
          response.first_name +
          (response.last_name ? ` ${response.last_name}` : ""),
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
      };
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  render() {
    return (
      <>
        <div className="cp-header">
          <h3>Sign up</h3>
          <p>Enter your details to signup</p>
        </div>
        <div className="cp-form">
          {/* <div className="cp-social"> */}
          {/* <div className="google-btn">
              <div id="signInDiv2">
                <GoogleLoginComponent
                  divid="signInDiv2"
                  loginGoogle={this.loginGoogle}
                />
              </div>
              <span>Sign in with Google</span>
            </div> */}

          {/* <div className="fb-btn">
              <FacebookLogin
                appId={fbAppId}
                fields="name,email,picture"
                callback={this.responseFacebook}
                scope="public_profile,email"
                cssClassName="fa fa-facebook"
                redirectUri={siteUrl}
                icon="fa-facebook"
                textButton="Sign up with Facebook"
              />
            </div>  */}
          {/* </div> */}

          {/* <div className="or-break">Or sign up with</div> */}

          <NewSignup onFormSubmit={this.handleFormSubmit} />
        </div>

        <div className="cp-form-footer">
          <p>
            By creating an account, you agree to our{" "}
            <Link to="/terms-and-conditions" target="_blank" className="link">
              Terms & Conditions
            </Link>
            ,{" "}
            <Link
              to="/cancellation-and-refund"
              target="_blank"
              className="link"
            >
              Cancellation & Refund
            </Link>
            , and{" "}
            <Link to="/privacy-policy" target="_blank" className="link">
              Privacy Policy
            </Link>
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fblogin: state.fblogin,
  googlelogin: state.googlelogin,
  customerlogindata: state.customerlogin,
  customerregistration: state.customerregistration,
});

const mapDispatchToProps = (dispatch) => ({
  getFbLoginData: (formPayload) =>
    dispatch({ type: GET_FBLOGINDATA, formPayload }),
  getGoogleLoginData: (formPayload) =>
    dispatch({ type: GET_GOOGLELOGINDATA, formPayload }),
  getCustomerLoginData: (formPayload) =>
    dispatch({ type: GET_CUSTOMER_LOGINDATA, formPayload }),
  getCustomerRegistration: (formPayload) =>
    dispatch({ type: GET_CUSTOMER_REGISTRATION, formPayload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupComponent);
