import axios from "axios";
import cookie from "react-cookies";
import {
  apiUrl,
  landingPageapiUrl,
  mediaUrl,
  PDFApiUrl,
  UopUrl,
} from "./Config";

/**
 * Generic function to handle API calls with different base URLs.
 * @param {string} action - The HTTP method (get, post, put, delete, etc.).
 * @param {string} baseUrl - The base URL for the API.
 * @param {string} endPoint - The specific API endpoint.
 * @param {object} payLoad - The request payload (optional).
 * @param {object} params - The request params (optional).
 * @returns {Promise<object>} - The API response or an error object.
 */
const handleApiRequest = async (
  action,
  baseUrl,
  endPoint,
  payLoad = {},
  params = {}
) => {
  try {
    // Validate HTTP method
    if (
      !["get", "post", "put", "delete", "patch"].includes(action.toLowerCase())
    ) {
      throw new Error(`Invalid HTTP method: ${action}`);
    }

    // Construct full API URL
    const url = `${baseUrl}${endPoint}`;

    // Get authorization token from cookies
    const token = cookie.load("acccesstoken");

    // Set the request options
    const options = {
      headers: { Authorization: token },
    };

    // For GET requests, append params to the URL
    if (action.toLowerCase() === "get") {
      options.params = params;
    }

    // For other methods (POST, PUT, PATCH, DELETE), include the payload in the request body
    let response;
    if (action.toLowerCase() === "get") {
      response = await axios[action](url, options); // GET request with params in options
    } else {
      response = await axios[action](url, payLoad, options); // Other methods with payload in the body
    }

    return response;
  } catch (err) {
    console.error(
      `API Call Error [${action.toUpperCase()} ${baseUrl}${endPoint}]:`,
      err
    );
    return { error: true, message: err.message };
  }
};

/**
 * API request to the marketplace.
 */
export const handleApiCall = (action, endPoint, payLoad) =>
  handleApiRequest(action, apiUrl, endPoint, payLoad);

/**
 * API request to UOP.
 */
export const handleUOPApiCall = (action, endPoint, payLoad) =>
  handleApiRequest(action, UopUrl, endPoint, payLoad);

/**
 * API request for handling PDFs.
 */
export const handlePDFApiCall = (action, endPoint, payLoad) =>
  handleApiRequest(action, PDFApiUrl, endPoint, payLoad);

/**
 * API request for handling landing page-related requests.
 */
export const handleLandingApiCall = (action, endPoint, payLoad) =>
  handleApiRequest(action, landingPageapiUrl, endPoint, payLoad);

/**
 * API request for handling media related requests.
 */
export const handleMediaApiCall = (action, endPoint, payLoad) =>
  handleApiRequest(action, mediaUrl, endPoint, payLoad);
