/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { apiUrl } from "../Helpers/Config";
import ContentLoader from "react-content-loader";
import { format } from "date-fns";
import cookie from "react-cookies";
import {
  showLoader,
  hideLoader,
  showPriceValue,
} from "../Helpers/SettingHelper";
import {
  dollarblue,
  stario,
  searchlight,
  flightRight,
  chair,
  roundtick,
} from "../Helpers/Images";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Divider } from "@mui/material";
import TransferListSheet from "./CustomerPackageDetails/TransferListSheet/TransferListSheet";
import { TransferSelection } from "./CustomerPackageDetails/TransferSelection/TransferSelection";
var Parser = require("html-react-parser");
var qs = require("qs");
class Listpromo extends Component {
  constructor(props) {
    super(props);
    var packageSlug,
      searchdata,
      transferList,
      selectedTransfer,
      transfer_trip_type = "";
    if (
      this.props.match.params.packageSlug !== "" &&
      typeof this.props.match.params.packageSlug !== undefined &&
      typeof this.props.match.params.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.match.params.packageSlug;
    } else {
      this.props.history.push("/");
    }
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      transfer_trip_type =
        searchdata.transfer_trip_type !== "" &&
        typeof searchdata.transfer_trip_type !== undefined &&
        typeof searchdata.transfer_trip_type !== "undefined"
          ? searchdata.transfer_trip_type
          : "";
    }
    var transferType = this.props.location.state?.transferType || "";
    var transferList = this.props.location.state?.transferList || "";
    if (transferType === "return") {
      transferList = this.props.location.state.transferListReturn || "";
    }

    var selectedTransfer = this.props.location.state?.selectedTransfer ?? "";
    if (transferType === "return") {
      selectedTransfer =
        this.props.location.state?.selectedTransferReturn ?? "";
    }

    var emptytransfer = this.props.location.state?.emptytransfer || "No";
    if (transferType === "return") {
      emptytransfer = this.props.location.state?.emptytransferReturn || "No";
    }

    var removetransfer = this.props.location.state?.removetransfer || "No";
    if (transferType === "return") {
      var removetransfer =
        this.props.location.state?.removetransferReturn || "No";
    }
    var addTransfer = this.props.location.state?.addTransfer || "No";
    if (transferType === "return") {
      var addTransfer = this.props.location.state?.addTransferReturn || "No";
    }
    var minPrice = 0;
    var maxPrice = 0;

    /* if (transferList.length > 0) {
      minPrice = Math.min(
        ...this.props.location.state.transferList.map((item) =>
          Math.ceil(item.amount)
        )
      );
      maxPrice = Math.max(
        ...this.props.location.state.transferList.map((item) =>
          Math.ceil(item.amount)
        )
      );
    } */
    this.state = {
      packageSlug: packageSlug,
      searchdata: searchdata,
      transferType: transferType,
      transferList: transferList,
      newtransferList: transferList,
      selectedTransfer: selectedTransfer,
      newselectedTransfer: selectedTransfer,
      transfer_trip_type: transfer_trip_type,
      totalPrice: 0,
      reserveTransfer: [],
      emptytransfer: emptytransfer,
      removetransfer: removetransfer,
      addTransfer: addTransfer,
      loadingTransfer: false,
      displayFilter: "",
      carTypes: [],
      startPrice: minPrice,
      endPrice: maxPrice,
      suppliersList: [],
      carTypeList: [],
      selectedrating: [],
      showFilter: false,
      selectedHotel: this.props.location.state.selectedHotel,
      searchdata: this.props.location.state.searchdata,
      disclaimertitle: "",
      disclaimerdescription: "",
      transferFilter: [],
      Suppliers: [],
      isMobile: window.innerWidth <= 480,
      showFareBreakdown: false,
      isLoading: true,
      errorpop: false,
      popupmsg: "",
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      sortBy: null,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.loadFilters();
    this.calculatePrice();
    if (
      this.state.emptytransfer === "Yes" ||
      this.state.removetransfer === "Yes"
    ) {
      this.loadTransfer();
    } else {
      var currentThis = this;
      $(() => {
        $("#price_filter_dsiplay").html(
          "SGD $" + this.state.startPrice + " to SGD $" + this.state.endPrice
        );
    
        $("#rang").ionRangeSlider({
          min: currentThis.state.startPrice,
          max: currentThis.state.endPrice,
          from: currentThis.state.startPrice,
          to: currentThis.state.endPrice,
          type: "double",
          grid_num: 10,
          onFinish: function (data) {
            currentThis.setState({ loadingTransfer: true });
            currentThis.setState({ startPrice: data.from, endPrice: data.to });
            $("#price_filter_dsiplay").html(
              "SGD $" + data.from + " to SGD $" + data.max
            );
            setTimeout(() => {
              currentThis.setState({ loadingTransfer: false });
            }, 2000);
          },
        });
        /* $("#rangmobile").ionRangeSlider({
          min: 0,
          max: 0,
          from: currentThis.state.startPrice,
          to: currentThis.state.endPrice,
          type: "double",
          grid_num: 10,
          onFinish: function (data) {
            currentThis.setState({ loadingTransfer: true });
            currentThis.setState(
              { startPrice: data.from, endPrice: data.to },
              function () {
                // currentThis.applyFilter();
              }
            );
            setTimeout(() => {
              currentThis.setState({ loadingTransfer: false });
            }, 2000);
          },
        }); */
      });
    }
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  loadTransfer() {
    var flight_Price = this.props.location.state.flightPrice;
    var totalOnway = flight_Price.onwayInfo.length;
    var arivalDateTime = flight_Price.onwayInfo[totalOnway - 1].ArrivalDateTime;
    var arrival_date = format(new Date(arivalDateTime), "yyyy-MM-dd");
    var arrival_time = format(new Date(arivalDateTime), "hh:mm aaa");
    this.setState({ loadingtransfer: true });

    var postObject = {
      latitude_start_point: this.state.searchdata.destination_airport_lat,
      longitude_start_point: this.state.searchdata.destination_airport_lon,
      latitude_end_point: this.props.location.state.selectedHotel.latitude,
      longitude_end_point: this.props.location.state.selectedHotel.longitude,
      place_from: this.state.searchdata.destination_airport_address,

      country_code_from: this.state.searchdata.sourceCountry,
      country: this.state.searchdata.destinationCountry,

      arrival_date: arrival_date,
      arrival_time: arrival_time,
      adults:
        this.props.location.state.searchdata.adults !== ""
          ? this.props.location.state.searchdata.adults
          : 0,
      children:
        this.props.location.state.searchdata.child !== "" &&
        typeof this.props.location.state.searchdata.child !== undefined &&
        typeof this.props.location.state.searchdata.child !== "undefined"
          ? this.props.location.state.searchdata.child
          : 0,
      infants:
        this.props.location.state.searchdata.infant !== "" &&
        typeof this.props.location.state.searchdata.infant !== undefined &&
        typeof this.props.location.state.searchdata.infant !== "undefined"
          ? this.props.location.state.searchdata.infant
          : 0,
      response_type: "0",
      transferType:'onward'
    };

    if (
      this.props.location.state.returnInfo !== "" &&
      typeof this.props.location.state.returnInfo !== undefined &&
      typeof this.props.location.state.returnInfo !== "" &&
      Object.keys(flight_Price.returnInfo).length
    ) {
      var totalOnway = flight_Price.returnInfo.length;
      var departureDateTime = flight_Price.returnInfo[0].DepartureDateTime;
      var departur_date = format(new Date(departureDateTime), "yyyy-MM-dd");
      var departur_time = format(new Date(departureDateTime), "hh:mm aaa");

      postObject["departure_date"] = departur_date;
      postObject["departure_time"] = departur_time;
      postObject["is_return"] = "1";
      postObject["transferType"] = 'return';
    } else {
      postObject["is_return"] = "0";
    }

    if (this.state.transfer_trip_type === "Return") {
      postObject["place_to"] = this.props.location.state.selectedHotel.address;
      postObject["is_return"] = "1";
    } else {
      postObject["place_from"] =
        this.props.location.state.selectedHotel.address;
      postObject["place_to"] =
        this.state.searchdata.destination_airport_address;

      postObject["is_return"] = "0";
      postObject["latitude_start_point"] =
        this.props.location.state.selectedHotel.latitude;
      postObject["longitude_start_point"] =
        this.props.location.state.selectedHotel.longitude;
      postObject["latitude_end_point"] =
        this.state.searchdata.destination_airport_lat;
      postObject["longitude_end_point"] =
        this.state.searchdata.destination_airport_lon;

      if (
        this.props.location.state.returnInfo !== "" &&
        typeof this.props.location.state.returnInfo !== undefined &&
        typeof this.props.location.state.returnInfo !== "" &&
        Object.keys(flight_Price.returnInfo).length
      ) {
        var totalOnway = flight_Price.returnInfo.length;
        var departureDateTime = flight_Price.returnInfo[0].DepartureDateTime;
        var departur_date = format(new Date(departureDateTime), "yyyy-MM-dd");
        var departur_time = format(new Date(departureDateTime), "hh:mm aaa");

        postObject["arrival_date"] = departur_date;
        postObject["arrival_time"] = departur_time;
        postObject["is_return"] = "0";
      }
      postObject["departure_date"] = arrival_date;
      postObject["departure_time"] = arrival_time;
    }

    showLoader("search_transfer", "class");
    this.setState({ loadingTransfer: true }, function () {
      axios
        .post(apiUrl + "transfer/availability", qs.stringify(postObject), {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const min_Price = Math.min(
              ...res.data.result.transfer_info.map((item) =>
                Math.ceil(item.amount)
              )
            );
            const max_Price = Math.max(
              ...res.data.result.transfer_info.map((item) =>
                Math.ceil(item.amount)
              )
            );

            this.setState(
              {
                transferList: res.data.result.transfer_info,
                newtransferList: res.data.result.transfer_info,
                newselectedTransfer: res.data.result.least_transfer_key,
                selectedTransfer: res.data.result.least_transfer_key,
                /* loadingTransfer: false, */
                startPrice: min_Price,
                endPrice: max_Price,
              },
              function () {
                var currentThis = this;

                $(() => {
              
                  const sliderElement = $("#rang");
                  $("#price_filter_dsiplay").html(
                    "SGD $" +
                      this.state.startPrice +
                      " to SGD $" +
                      this.state.endPrice
                  );
                  currentThis.setState({ loadingTransfer: false });
                  /*  sliderElement.data("ionRangeSlider").update({
                    min: min_Price,
                    max: max_Price,
                    from: min_Price,
                    to: max_Price,
                    type: "double",
                    grid_num: 10,
                    onFinish: function (data) {
                      console.log(
                        min_Price,
                        max_Price,
                        "basemax_Pricemax_Price"
                      );
                      currentThis.setState({ loadingTransfer: true });
                      currentThis.setState({
                        startPrice: data.from,
                        endPrice: data.max,
                      });
                      $("#price_filter_dsiplay").html(
                        "SGD $" + data.from + " to SGD $" + data.max
                      );
                      setTimeout(() => {
                        console.log(
                          min_Price,
                          max_Price,
                          "mmmmmax_Pricemax_Price"
                        );
                        currentThis.setState({ loadingTransfer: false });
                      }, 2000);
                    },
                   
                  }); */
                }, 500);
                if (
                  this.props.match.params.packageSlug === "create-own-package"
                ) {
                  hideLoader("search_transfer", "class");
                } else {
                  this.reserveTransfer();
                }
              }
            );
          } else if (res.data.status === "error") {
            this.setState(
              {
                errorpop: true,
                popupmsg: res.data.message,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#earning-details",
                  },
                  type: "inline",
                  showCloseBtn: false,
                  midClick: true,
                  closeOnBgClick: false,
                });
              }
            );
          } else {
            hideLoader("search_transfer", "class");
            this.setState({
              transferList: [],
              newtransferList: [],
              selectedTransfer: "",
              loadingTransfer: false,
            });
          }
        });
    });
  }

  reserveTransfer() {
    var selecetedTrans =
      this.state.newtransferList[this.state.selectedTransfer];
    var postObject = {
      session_id: selecetedTrans.session_id,
      booking_id: selecetedTrans.booking_id,
    };
    axios
      .post(apiUrl + "transfer/reserveTransfer", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        hideLoader("search_transfer", "class");
        if (res.data.status === "success") {
          this.setState(
            {
              reserveTransfer: res.data.result.reserve_info,
              loadingtransfer: false,
              emptytransfer: "No",
              removetransfer: "No",
              addTransfer: "Yes",
            },
            function () {
              this.calculatePrice();
              this.loadFilters();
            }
          );
        } else {
          this.setState({ loadingtransfer: false });
        }
      });
  }

  loadRatingstar(points) {
    if (points === "" || points === null) {
      points = "0.0";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) > 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  calculatePrice() {
    var totalPrice = 0;
    if (
      this.state.newselectedTransfer !== "" &&
      this.state.newtransferList.length > 0
    ) {
      totalPrice =
        this.state.newtransferList[this.state.newselectedTransfer].total_amount;
    }
    this.setState({ totalPrice: totalPrice });
  }

  addTransfer(index, item) {
    this.setState({ isLoading: true });
    showLoader("transfer_" + index);
    var postObject = {
      session_id: item.session_id,
      booking_id: item.booking_id,
    };
    axios
      .post(apiUrl + "transfer/reserveTransfer", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState(
            {
              reserveTransfer: res.data.result.reserve_info,
              newselectedTransfer: index,
              emptytransfer: "No",
              removetransfer: "No",
              addTransfer: "Yes",
            },
            function () {
              // this.calculatePrice();
              // $("html, body").animate(
              //   {
              //     scrollTop: $("#selected-transfer").offset().top - 100,
              //   },
              //   2000
              // );
              this.continueTransfer();
            }
          );
        } else {
          /*   this.setState({
            transferList: [],
            newtransferList:[],
            selectedTransfer: "",
            loadingtransfer: false,
          }); */
        }
        hideLoader("transfer_" + index);
      });
  }

  continueTransfer() {
    var bookingdetails = this.props.location.state;
    var transferType = this.state.transferType === "return" ? "Return" : "";
    
    bookingdetails[`showTransfer${transferType}`] = true;
    bookingdetails[`transferList${transferType}`] = this.state.newtransferList;
    bookingdetails[`selectedTransfer${transferType}`] =
      this.state.newselectedTransfer;
    bookingdetails[`reserveTransfer${transferType}`] =
      this.state.reserveTransfer;
    bookingdetails[`removetransfer${transferType}`] = this.state.removetransfer;
    bookingdetails[`addTransfer${transferType}`] = this.state.addTransfer;
    bookingdetails[`emptytransfer${transferType}`] = "No";
    if (this.state.removetransfer === "Yes") {
      bookingdetails[`addTransfer${transferType}`] = "No";
      bookingdetails[`transferList${transferType}`] = [];
      bookingdetails[`reserveTransfer${transferType}`] = [];
      bookingdetails[`emptytransfer${transferType}`] = "Yes";
    }else {
      bookingdetails[`emptytransfer${transferType}`] = "No";
    }
    // if (this.props.match.params.packageSlug === "create-own-package") {
    //   bookingdetails["emptytransfer"] = "No";
    //   bookingdetails["addTransfer"] = "Yes";
    // }
    this.setState({ isLoading: false });
    if (this.props.drawerOpened) {
      this.props.history.push({
        state: bookingdetails,
      });
      this.props.handleCloseSheet();
    } else {
      this.props.history.push({
        pathname: "/search-package/" + this.state.packageSlug,
        state: bookingdetails,
      });
    }
  }
  goback() {
    var bookingdetails = this.props.location.state;
    this.props.history.push({
      pathname: "/search-package/" + this.state.packageSlug,
      state: bookingdetails,
    });
  }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");
    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  clearAllFilters() {
    const minPrice = Math.min(
      ...this.state.newtransferList.map((item) => item.amount)
    );
    const maxPrice = Math.max(
      ...this.state.newtransferList.map((item) => item.amount)
    );
    this.setState(
      {
        loadingTransfer: true,
        selectedrating: [],
        suppliersList: [],
        startPrice: minPrice,
        endPrice: maxPrice,
        startPrice: [],
        endPrice: [],
        carTypeList: [],
      },
      function () {
        this.loadFilters();
        if (!this.props.drawerOpened) {
          const sliderElement = $("#rang");
          sliderElement.data("ionRangeSlider").update({
            from: minPrice,
            to: maxPrice,
          });
        }
      }
    );
    const checkboxes = document.querySelectorAll(".tag_filter");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setTimeout(() => {
      this.setState({ loadingTransfer: false });
    }, 2000);
  }
  handleFilterChange(type, val) {
    this.setState({ loadingTransfer: true });
    if (type === "suppliers") {
      var suppliersList = this.state.suppliersList;
      if (!suppliersList.includes(val)) {
        suppliersList.push(val);
      } else {
        var index = suppliersList.indexOf(val);
        if (index > -1) {
          suppliersList.splice(index, 1);
        }
      }
      this.setState({ suppliersList: suppliersList });
    }
    if (type === "carTypes") {
      var carTypeList = this.state.carTypeList;
      if (!carTypeList.includes(val)) {
        carTypeList.push(val);
      } else {
        var index = carTypeList.indexOf(val);
        if (index > -1) {
          carTypeList.splice(index, 1);
        }
      }
      this.setState({ carTypeList: carTypeList });
    }
    setTimeout(() => {
      this.setState({ loadingTransfer: false });
    }, 2000);
  }
  handleCheckbox(type, event) {
    this.setState({ loadingTransfer: true });
    if (type === "suppliers") {
      var suppliersList = this.state.suppliersList;
      if (event.target.checked === true) {
        suppliersList.push(event.target.value);
      } else {
        var index = suppliersList.indexOf(event.target.value);
        if (index > -1) {
          suppliersList.splice(index, 1);
        }
      }
      this.setState({ suppliersList: suppliersList });
    }
    if (type === "carTypes") {
      var carTypeList = this.state.carTypeList;
      if (event.target.checked === true) {
        carTypeList.push(event.target.value);
      } else {
        var index = carTypeList.indexOf(event.target.value);
        if (index > -1) {
          carTypeList.splice(index, 1);
        }
      }
      this.setState({ carTypeList: carTypeList });
    }
    setTimeout(() => {
      this.setState({ loadingTransfer: false });
    }, 2000);
  }
  applyFilter(item) {
    var result = true;
    if (this.state.carTypeList.length > 0) {
      if (this.state.carTypeList.indexOf(item.vehicle) >= 0) {
        result = true;
      } else {
        result = false;
      }
    }
    var hotel = true;
    if (
      this.state.startPrice > 0 &&
      item.total_amount < this.state.startPrice
    ) {
      hotel = false;
    }
    if (this.state.endPrice > 0 && item.total_amount > this.state.endPrice) {
      hotel = false;
    }
    var supplier = true;
    if (this.state.suppliersList.length > 0) {
      if (
        this.state.suppliersList.indexOf(item.transfer_company.toString()) >= 0
      ) {
        supplier = true;
      } else {
        supplier = false;
      }
    } else if (
      this.state.suppliersList.indexOf(item.transfer_company.toString()) === 0
    ) {
      classsucc = false;
    }
    var rating = true;
    if (this.state.selectedrating.length > 0) {
      if (this.state.selectedrating.indexOf(item.rating_class) >= 0) {
        rating = true;
      } else {
        rating = false;
      }
    }

    if (result && hotel && supplier && rating) {
      return true;
    } else {
      return false;
    }
  }
  filterBySortBy(arr) {
    if (this.state.sortBy === 'LH') {
      return arr.sort((a, b) => Number(a.total_amount) - Number(b.total_amount));
    } else if (this.state.sortBy === "HL") {
      return arr.sort((a, b) => Number(b.total_amount) - Number(a.total_amount));
    } else {
      return arr
    }
  }
  filterByRating(rating) {
    this.setState({ loadingTransfer: true });
    var selectedrating = "";
    if (
      this.state.selectedrating !== "" &&
      this.state.selectedrating === rating
    ) {
      selectedrating = "";
    } else {
      selectedrating = rating;
    }
    this.setState({ selectedrating: selectedrating }, function () {
      this.loadFilters();
    });
    setTimeout(() => {
      this.setState({ loadingTransfer: false });
    }, 2000);
  }
  // applyFilterMobile() {
  //   this.setState({ showFilter: false }, function () {
  //     this.applyFilter();
  //   });
  // }
  loadFilters(item) {
    if (this.state.newtransferList.length > 0) {
      this.state.newtransferList.map((item) => {
        if (this.state.transferFilter.indexOf(item.vehicle) < 0) {
          this.state.transferFilter.push(item.vehicle);
        }
        if (this.state.Suppliers.indexOf(item.transfer_company) < 0) {
          this.state.Suppliers.push(item.transfer_company);
        }
      });
    }
  }
  viewdisclaimer(selecetedTrans, loadinId) {
    showLoader(loadinId);

    var postObject = {
      session_id: selecetedTrans.session_id,
      booking_id: selecetedTrans.booking_id,
    };
    axios
      .post(apiUrl + "transfer/disclaimer", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          var result = res.data.result.disclaimer_info;
          this.setState(
            {
              disclaimertitle: result.disclaimer_title,
              disclaimerdescription:
                result.disclaimer_description !== "" &&
                result.disclaimer_description !== null
                  ? Parser(result.disclaimer_description)
                  : "",
            },
            function () {
              hideLoader(loadinId);
              $.magnificPopup.open({
                items: {
                  src: "#transfer_disclaimer_popup",
                },
                type: "inline",
              });
            }
          );
        } else {
          hideLoader(loadinId);
        }
      });
  }
  removeTransfer() {
    $.magnificPopup.open({
      items: {
        src: "#transfer_remove_popup",
      },
      type: "inline",
    });
  }
  confirmRemove(e) {
    $.magnificPopup.close();

    this.setState({
      newselectedTransfer: "",
      removetransfer: "Yes",
      emptytransfer: "Yes",
      selectedTransfer: "",
      totalPrice: 0,
    });
  }
  selectTripType(trip_type) {
    this.setState({ transfer_trip_type: trip_type });
  }
  getCountByRating(rating) {
    const filteredItems = this.state.transferList.filter(
      (item) => item.rating_class === rating
    );
    return filteredItems.length;
  }
  closePopup(index) {
    $.magnificPopup.close();
    this.setState(
      {
        errorpop: false,
      },
      function () {
        hideLoader("flight_" + index);
      }
    );
  }

  render() {
    const transferlist = this.filterBySortBy(this.state.newtransferList).filter((transfer) => {
      return this.applyFilter(transfer);
    });

    return (
      <>
        {!this.props.drawerOpened && (
          <Header {...this.props} sateValChange={this.sateValChange} />
        )}
        {this.state.isLoading === false ? (
          this.props.drawerOpened ? (
            <TransferSelection transferlist={transferlist} state={this.state} addTransfer={this.addTransfer.bind(this)} 
            loadRatingstar={this.loadRatingstar.bind(this)} handleCloseSheet={this.props.handleCloseSheet} getCountByRating={this.getCountByRating.bind(this)}
            filterByRating={this.filterByRating.bind(this)} handleFilterChange={this.handleFilterChange.bind(this)} clearAllFilters={this.clearAllFilters.bind(this)}
            setState={this.setState.bind(this)} /> 
          ) : (
            <section className="innersection">
              <div className="container">
                <div className="detail-top-header pb30">
                  <h1>Change transfers for the trip</h1>
                </div>
                <div className="package-details pb40">
                  <div className="package-details-lhs transfer-change-pack">
                    <div
                      className="hotel-search-result transport-search-result"
                      id="selected-transfer"
                    >
                      <h5 className="color-header mb15">
                        Currently added <span>transfer</span>
                      </h5>
                      {this.state.isMobile ? (
                        <ul id="selected_transfer">
                          {this.state.newtransferList.length > 0 &&
                            this.state.removetransfer !== "Yes" &&
                            this.state.totalPrice !== 0 && (
                              <li>
                                <div className="hsr-lhs">
                                  <div className="hsr-lhs-img textcenter">
                                    <img
                                      src={
                                        this.state.newtransferList[
                                          this.state.newselectedTransfer
                                        ].vehicle_image
                                      }
                                      alt=""
                                    />
                                    <div className="row">
                                      <figcaption className="col-1">
                                        <strong>
                                          {
                                            this.state.newtransferList[
                                              this.state.newselectedTransfer
                                            ].vehicle
                                          }
                                        </strong>
                                        <span>
                                          Or{" "}
                                          {
                                            this.state.newtransferList[
                                              this.state.newselectedTransfer
                                            ].transfer_company
                                          }
                                        </span>
                                      </figcaption>
                                      <div className="star-rating-review col-2">
                                        <div className="star-rating">
                                          {this.loadRatingstar(
                                            this.state.newtransferList[
                                              this.state.newselectedTransfer
                                            ].rating
                                          )}
                                        </div>
                                        <div className="sr-review">
                                          <strong>
                                            {" "}
                                            {
                                              this.state.newtransferList[
                                                this.state.newselectedTransfer
                                              ].rating_class
                                            }
                                          </strong>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="hsr-lhs-desc">
                                    <h4>
                                      {
                                        this.state.searchdata
                                          .location_destination
                                      }{" "}
                                      to {this.state.selectedHotel.hotel_name} -
                                      Round trip
                                    </h4>
                                    {/* <span>Nerul, Goa</span> */}
                                    <ul className="mainpoints">
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        Estimated journey{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].duration_min
                                        }{" "}
                                        min
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        Estimated distance{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].distance_km
                                        }{" "}
                                        km
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].minimum_occupancy_from
                                        }{" "}
                                        -{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].maximum_occupancy_to
                                        }{" "}
                                        passengers
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].number_of_big_bag
                                        }{" "}
                                        suitcases &{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].number_of_small_bag
                                        }{" "}
                                        Small bag per vehicle
                                      </li>
                                      {this.state.newtransferList[
                                        this.state.newselectedTransfer
                                      ].disclaimer === "1" && (
                                        <li>
                                          {" "}
                                          <img
                                            src={roundtick}
                                            alt="roundtick"
                                          />{" "}
                                          <a
                                            href={void 0}
                                            onClick={this.viewdisclaimer.bind(
                                              this,
                                              this.state.newtransferList[
                                                this.state.newselectedTransfer
                                              ],
                                              "selected_transfer"
                                            )}
                                          >
                                            {" "}
                                            Transfer disclaimer
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>

                                <div className="hsr-rhs">
                                  <p>
                                    +
                                    {showPriceValue(
                                      this.state.totalPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </p>
                                  <a
                                    href={void 0}
                                    onClick={this.removeTransfer.bind(this)}
                                    className="button ghost-button remove-btn"
                                  >
                                    Remove
                                  </a>
                                </div>
                              </li>
                            )}
                        </ul>
                      ) : (
                        <ul id="selected_transfer">
                          {this.state.newtransferList.length > 0 &&
                            this.state.removetransfer !== "Yes" &&
                            this.state.totalPrice !== 0 && (
                              <li>
                                <div className="hsr-lhs">
                                  <div className="hsr-lhs-img textcenter">
                                    <img
                                      src={
                                        this.state.newtransferList[
                                          this.state.newselectedTransfer
                                        ].vehicle_image
                                      }
                                      alt=""
                                    />
                                    <figcaption>
                                      <strong>
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].vehicle
                                        }
                                      </strong>
                                      <span>
                                        Or{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].transfer_company
                                        }
                                      </span>
                                    </figcaption>
                                  </div>
                                  <div className="hsr-lhs-desc">
                                    <div className="star-rating-review">
                                      <div className="star-rating">
                                        {this.loadRatingstar(
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].rating
                                        )}
                                      </div>
                                      <div className="sr-review">
                                        <strong>
                                          {" "}
                                          {
                                            this.state.newtransferList[
                                              this.state.newselectedTransfer
                                            ].rating_class
                                          }
                                        </strong>{" "}
                                      </div>
                                    </div>
                                    <h4>
                                      {
                                        this.state.searchdata
                                          .location_destination
                                      }{" "}
                                      to {this.state.selectedHotel.hotel_name} -
                                      Round trip
                                    </h4>
                                    {/* <span>Nerul, Goa</span> */}
                                    <ul className="mainpoints">
                                      <li>
                                        <img src={roundtick} roundtick />
                                        Estimated journey{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].duration_min
                                        }{" "}
                                        min
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        Estimated distance{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].distance_km
                                        }{" "}
                                        km
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].minimum_occupancy_from
                                        }{" "}
                                        -{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].maximum_occupancy_to
                                        }{" "}
                                        passengers
                                      </li>
                                      <li>
                                        <img src={roundtick} alt="roundtick" />
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].number_of_big_bag
                                        }{" "}
                                        suitcases &{" "}
                                        {
                                          this.state.newtransferList[
                                            this.state.newselectedTransfer
                                          ].number_of_small_bag
                                        }{" "}
                                        Small bag per vehicle
                                      </li>
                                      {this.state.newtransferList[
                                        this.state.newselectedTransfer
                                      ].disclaimer === "1" && (
                                        <li>
                                          {" "}
                                          <img
                                            src={roundtick}
                                            alt="roundtick"
                                          />{" "}
                                          <a
                                            href={void 0}
                                            onClick={this.viewdisclaimer.bind(
                                              this,
                                              this.state.newtransferList[
                                                this.state.newselectedTransfer
                                              ],
                                              "selected_transfer"
                                            )}
                                          >
                                            {" "}
                                            Transfer disclaimer
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>

                                <div className="hsr-rhs">
                                  <p>
                                    +
                                    {showPriceValue(
                                      this.state.totalPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </p>
                                  <a
                                    href={void 0}
                                    onClick={this.removeTransfer.bind(this)}
                                    className="button ghost-button remove-btn"
                                  >
                                    Remove
                                  </a>
                                </div>
                              </li>
                            )}
                        </ul>
                      )}
                    </div>
                  </div>
                  {!this.state.isMobile && (
                    <div className="package-details-rhs">
                      <h5 className="color-header mb15 vb-hidden">trip</h5>
                      <div className="detail-bottom-rhs-price rhs-white-box">
                        <h5>Price details</h5>
                        <ul>
                          <li>
                            <span className="service-content">
                              <strong>Transfer Price</strong>
                            </span>
                            <span className="service-amount">
                              <strong>
                                {showPriceValue(
                                  this.state.totalPrice,
                                  this.state.currentCurrency,
                                  this.state.currencyRate
                                )}
                              </strong>
                            </span>
                          </li>

                          <li>
                            <span className="service-content">Total</span>
                            <span className="service-amount">
                              {showPriceValue(
                                this.state.totalPrice,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="booknow-btm two-btn-merge">
                        <a
                          href={void 0}
                          onClick={this.goback.bind(this)}
                          className="button ghost-button"
                        >
                          Back
                        </a>
                        <a
                          className="button check_room_avail"
                          href={void 0}
                          onClick={this.continueTransfer.bind(this)}
                        >
                          Continue
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="color-header">
              <h3>
                Choose <span>transport</span>
              </h3>
            </div>
            <div className="flight-way-search choose-transport ">
              <div className="flight-way">
                <ul>
                  <li>
                    <input
                      type="radio"
                      name="transfer_trip_type"
                      defaultValue="OneWay"
                      checked={
                        this.state.transfer_trip_type === "OneWay"
                          ? true
                          : false
                      }
                      onChange={this.selectTripType.bind(this, "OneWay")}
                    />{" "}
                    One way
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="transfer_trip_type"
                      defaultValue="Return"
                      checked={
                        this.state.transfer_trip_type === "Return"
                          ? true
                          : false
                      }
                      onChange={this.selectTripType.bind(this, "Return")}
                    />{" "}
                    Return
                  </li>
                  <li>
                    <button
                      type="submit"
                      className="button fg-btn search_transfer"
                      onClick={this.loadTransfer.bind(this)}
                    >
                      <img src={searchlight} />
                    </button>
                  </li>
                </ul>
              </div>
            </div> */}

                <div className="outlet-listing-inner">
                  <div className="outlet-listing-lhs">
                    <h4>Filters</h4>
                    <div className="filter-option flight-option rating-option">
                      <h5
                        className="accordion-header"
                        onClick={this.toggleAccordion}
                      >
                        <img src={flightRight} alt="flightRight" />
                        Car Types
                      </h5>
                      <ul>
                        {this.state.transferFilter.map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                className="tag_filter"
                                value={item}
                                id={"classes_" + index}
                                onChange={this.handleCheckbox.bind(
                                  this,
                                  "carTypes"
                                )}
                              />
                              <label htmlFor={"classes_" + index}>{item}</label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="filter-option flight-option">
                      <h5
                        className="accordion-header"
                        onClick={this.toggleAccordion}
                      >
                        <img src={chair} alt="chair" />
                        Supplier
                      </h5>
                      <ul>
                        {this.state.Suppliers.map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                className="tag_filter"
                                value={item}
                                id={"classes_" + index}
                                onChange={this.handleCheckbox.bind(
                                  this,
                                  "suppliers"
                                )}
                              />
                              <label htmlFor={"classes_" + index}>{item}</label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="filter-option pricerange-option">
                      <h5
                        className="accordion-header"
                        onClick={this.toggleAccordion}
                      >
                        <img src={dollarblue} alt="dollar" />
                        Price range
                      </h5>
                      <p id="price_filter_dsiplay">
                        {showPriceValue(
                          Math.ceil(this.state.startPrice),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}{" "}
                        to{" "}
                        {showPriceValue(
                          Math.ceil(this.state.startPrice),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                        +
                      </p>
                      <div>
                        <input
                          type="text"
                          id="rang"
                          name="rang"
                          data-prefix="SGD$"
                          data-grid="false"
                        />
                      </div>
                    </div>
                    <div className="filter-option rating-option no-margin-btm">
                      <h5
                        className="accordion-header"
                        onClick={this.toggleAccordion}
                      >
                        <img src={stario} alt="star" />
                        Rating
                      </h5>
                      <ul>
                        <li
                          className={
                            this.state.selectedrating === "5" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "5")}
                          >
                            5 Excellent ({this.getCountByRating("5")}){" "}
                          </a>
                        </li>
                        <li
                          className={
                            this.state.selectedrating === "4" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "4")}
                          >
                            4 Good ({this.getCountByRating("4")})
                          </a>
                        </li>
                        <li
                          className={
                            this.state.selectedrating === "3" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "3")}
                          >
                            3 Good ({this.getCountByRating("3")})
                          </a>
                        </li>
                        <li
                          className={
                            this.state.selectedrating === "2" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "2")}
                          >
                            2 Poor ({this.getCountByRating("2")})
                          </a>
                        </li>
                        <li
                          className={
                            this.state.selectedrating === "1" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "1")}
                          >
                            1 Terrible ({this.getCountByRating("1")})
                          </a>
                        </li>
                        <li
                          className={
                            this.state.selectedrating === "0" ? "active" : ""
                          }
                        >
                          <a
                            href={void 0}
                            onClick={this.filterByRating.bind(this, "0")}
                          >
                            0 Rating ({this.getCountByRating("0")})
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="clearall">
                      <a
                        href={void 0}
                        onClick={this.clearAllFilters.bind(this)}
                      >
                        Clear all
                      </a>{" "}
                    </div>
                  </div>
                  <div className="outlet-listing-rhs transport-search-result">
                    {this.state.loadingTransfer === false && (
                      <div className="search-number">
                        <span>Search results</span>
                        <span>
                          {transferlist.length} of
                          {this.state.transferList.length} transport
                        </span>
                      </div>
                    )}
                    {console.log(
                      this.state.loadingTransfer,
                      "this.state.loadingTransfer"
                    )}
                    <div className="hotel-search-result">
                      <ul>
                        {this.state.loadingTransfer === true
                          ? Array(1, 2, 3).map((item) => {
                              return (
                                <li key={item}>
                                  <ContentLoader
                                    height={this.state.isMobile ? 70 : 140}
                                    speed={1}
                                    viewBox="0 0 380 70"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      rx="5"
                                      ry="5"
                                      width="70"
                                      height="70"
                                    />
                                    <rect
                                      x="80"
                                      y="17"
                                      rx="4"
                                      ry="4"
                                      width="300"
                                      height="13"
                                    />
                                    <rect
                                      x="80"
                                      y="40"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="10"
                                    />
                                  </ContentLoader>
                                </li>
                              );
                            })
                          : transferlist.length > 0 &&
                            transferlist.map((item, index) => {
                              var fliterRes = this.applyFilter(item);
                              if (fliterRes === true) {
                                return this.state.isMobile ? (
                                  <li key={index} id={"transfer_" + index}>
                                    <div className="hsr-lhs">
                                      <div className="hsr-lhs-img textcenter">
                                        <img src={item.vehicle_image} alt="" />
                                        <div className="row">
                                          <figcaption className="col-1">
                                            <strong>{item.vehicle}</strong>
                                            <span>
                                              Or {item.transfer_company}
                                            </span>
                                          </figcaption>
                                          <div className="star-rating-review col-2">
                                            <div className="star-rating">
                                              {this.loadRatingstar(item.rating)}
                                            </div>
                                            <div className="sr-review">
                                              <strong>{item.rating}</strong>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hsr-lhs-desc">
                                        <h4>
                                          {
                                            this.state.searchdata
                                              .location_destination
                                          }{" "}
                                          to{" "}
                                          {this.state.selectedHotel.hotel_name}{" "}
                                          - Round trip{" "}
                                          {/* need to be changed */}
                                        </h4>
                                        {/* <span>Nerul, Goa</span> */}
                                        <ul className="mainpoints">
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            Estimated journey{" "}
                                            {item.duration_min} min
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            Estimated distance{" "}
                                            {item.distance_km} km
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            {item.minimum_occupancy_from} -{" "}
                                            {item.maximum_occupancy_to}{" "}
                                            passengers
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            {item.number_of_big_bag} suitcases &{" "}
                                            {item.number_of_small_bag} Small bag
                                            per vehicle
                                          </li>
                                          {item.disclaimer === "1" && (
                                            <li>
                                              {" "}
                                              <img
                                                src={roundtick}
                                                alt="roundtick"
                                              />{" "}
                                              <a
                                                href={void 0}
                                                onClick={this.viewdisclaimer.bind(
                                                  this,
                                                  item,
                                                  "transfer_" + index
                                                )}
                                              >
                                                {" "}
                                                Transfer disclaimer
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="hsr-rhs">
                                      <p>
                                        {showPriceValue(
                                          item.total_amount,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </p>
                                      <a
                                        href={void 0}
                                        onClick={this.addTransfer.bind(
                                          this,
                                          index,
                                          item
                                        )}
                                        className="button"
                                      >
                                        Add
                                      </a>
                                    </div>
                                    <div className="best-flag">Transfers</div>
                                  </li>
                                ) : (
                                  <li key={index} id={"transfer_" + index}>
                                    <div className="hsr-lhs">
                                      <div className="hsr-lhs-img textcenter">
                                        <img src={item.vehicle_image} alt="" />
                                        <figcaption>
                                          <strong>{item.vehicle}</strong>
                                          <span>
                                            Or {item.transfer_company}
                                          </span>
                                        </figcaption>
                                      </div>
                                      <div className="hsr-lhs-desc">
                                        <div className="star-rating-review">
                                          <div className="star-rating">
                                            {this.loadRatingstar(item.rating)}
                                          </div>
                                          <div className="sr-review">
                                            <strong>{item.rating}</strong>
                                          </div>
                                        </div>
                                        <h4>
                                          {
                                            this.state.searchdata
                                              .location_destination
                                          }{" "}
                                          to{" "}
                                          {this.state.selectedHotel.hotel_name}-
                                          Round trip {/* need to be changed */}
                                        </h4>
                                        {/* <span>Nerul, Goa</span> */}
                                        <ul className="mainpoints">
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            Estimated journey{" "}
                                            {item.duration_min} min
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            Estimated distance{" "}
                                            {item.distance_km}
                                            km
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            {item.minimum_occupancy_from} -{" "}
                                            {item.maximum_occupancy_to}{" "}
                                            passengers
                                          </li>
                                          <li>
                                            <img
                                              src={roundtick}
                                              alt="roundtick"
                                            />
                                            {item.number_of_big_bag} suitcases &{" "}
                                            {item.number_of_small_bag} Small bag
                                            per vehicle
                                          </li>
                                          {item.disclaimer === "1" && (
                                            <li>
                                              {" "}
                                              <img
                                                src={roundtick}
                                                alt="roundtick"
                                              />{" "}
                                              <a
                                                href={void 0}
                                                onClick={this.viewdisclaimer.bind(
                                                  this,
                                                  item,
                                                  "transfer_" + index
                                                )}
                                              >
                                                {" "}
                                                Transfer disclaimer
                                              </a>
                                            </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="hsr-rhs">
                                      <p>
                                        {showPriceValue(
                                          item.total_amount,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </p>
                                      <a
                                        href={void 0}
                                        onClick={this.addTransfer.bind(
                                          this,
                                          index,
                                          item
                                        )}
                                        className="button"
                                      >
                                        Add
                                      </a>
                                    </div>
                                    <div className="best-flag">Transfers</div>
                                    {this.state.errorpop && (
                                      <div>
                                        <div
                                          id="earning-details"
                                          className="mfp-hide common-popup earnpop-details"
                                        >
                                          <div className="ed-header">
                                            <h3>{this.state.popupmsg}</h3>
                                          </div>
                                          <div className="ed-body">
                                            <button
                                              onClick={() =>
                                                this.closePopup(index)
                                              }
                                            >
                                              Ok
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                );
                              }
                            })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}

        <div
          id="transfer_disclaimer_popup"
          className="mfp-hide common-popup transfer-disclaimer-popup"
        >
          <div className="cp-header textcenter">
            <h4>Disclaimer</h4>
          </div>
          <div className="cp-body">
            <h4> {this.state.disclaimertitle}</h4>
            {this.state.disclaimerdescription}
          </div>
        </div>

        <div
          id="transfer_remove_popup"
          className="mfp-hide common-popup transfer-disclaimer-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h2 className="text-uppercase">Warning</h2>
                  <p>Are you sure want to remove transfer</p>

                  <div
                    className="alt_btns"
                    style={{ gap: "10px", display: "inline-flex" }}
                  >
                    <a
                      href={void 0}
                      className="button  ghost-button"
                      onClick={this.confirmRemove.bind(this)}
                    >
                      Yes
                    </a>

                    <a
                      href={void 0}
                      className="button popup-modal-dismiss disbl_href_action"
                    >
                      No
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!this.props.drawerOpened && <Footer {...this.props} />}

        {this.state.isMobile && !this.props.drawerOpened && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row">
                <div className="col-1">
                  <div className="title">
                    Total transfer <span>fare</span>
                  </div>
                  <div className="price">
                    {showPriceValue(
                      this.state.totalPrice,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </div>

                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>
                </div>
                <div className="col-2">
                  <a
                    className="button check_room_avail"
                    href={void 0}
                    onClick={this.continueTransfer.bind(this)}
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              <li>
                <span className="service-content">
                  <strong>Transfer Price</strong>
                </span>
                <span className="service-amount">
                  <strong>
                    {showPriceValue(
                      this.state.totalPrice,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </strong>
                </span>
              </li>
            </ul>
            <Divider />
            <div className="details">
              <div className="title">
                Total <span>fare</span>
              </div>
              <div className="col-2">
                <div className="price">
                  {" "}
                  {showPriceValue(
                    this.state.totalPrice,
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )}
                </div>
              </div>
            </div>
            <div className="booknow-btm two-btn-merge">
              <a
                href={void 0}
                onClick={this.goback.bind(this)}
                className="button ghost-button"
              >
                Back
              </a>
              <a
                className="button check_room_avail"
                href={void 0}
                onClick={this.continueTransfer.bind(this)}
              >
                Continue
              </a>
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
