/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_UPLOAD_FILES, SET_UPLOAD_FILES } from "../actions";
import { mediaUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetGetUploadFiles = function* () {
  yield takeEvery(GET_UPLOAD_FILES, workerGetUploadFiles);
};

function* workerGetUploadFiles({ formPayload }) {
  try {
    const result = yield call(GetUploadFiles, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_UPLOAD_FILES, value: resultArr });
  } catch {
    console.log("upload failed");
  }
}

function GetUploadFiles(formPayload) {
  return Axios.post(mediaUrl + "image/upload", formPayload, {
    headers: {
      Authorization: cookie.load("acccesstoken"),
    },
  });
}
