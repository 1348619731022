import React from "react";
import "./AssistanceCard.scss";
import { whatsAppUrl } from "../../Helpers/Config";
import { Button, AvatarGroup, Avatar } from "@mui/material";
import AsstBannerBgVec from "../../../common/images/asst-banner-bg.svg";
import AsstWhatappIcon from "../../../common/images/asst-whatsapp-icon.svg";
import AsstCallIcon from "../../../common/images/asst-call-icon.svg";

const AssistanceCard = (props) => {
  const { handleAssistancePopup } = props;
  return (
    <div className="asstCard-main">
      <img
        className="asstCard-main__bgVec"
        src={AsstBannerBgVec}
        alt="AsstBannerBgVec"
      />
      <h3 className="asstCard-main__title">Need help with customization?</h3>
      <div className="asstCard-main__btns">
        <Button
          className="asstCard-main__btns__whatsappBtn"
          onClick={() => window.open(whatsAppUrl, "_blank")}
        >
          <img src={AsstWhatappIcon} alt="AsstWhatappIcon" />
          Chat via WhatsApp
        </Button>
        <Button
          className="asstCard-main__btns__emailBtn"
          onClick={() => handleAssistancePopup("callback")}
        >
          <img src={AsstCallIcon} alt="AsstCallIcon" />
          Request call back
        </Button>
        <Button
          className="asstCard-main__btns__emailBtn"
          onClick={() => handleAssistancePopup("one-to-one")}
        >
          <AvatarGroup>
            <Avatar
              alt="Gowtham"
              src="https://images.fizota.com/Gowtham.webp"
            />
            <Avatar alt="Bose" src="https://images.fizota.com/Bose.webp" />
          </AvatarGroup>
          Free 1-on-1 Support
        </Button>
      </div>
    </div>
  );
};

export default AssistanceCard;
