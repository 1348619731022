import React from "react";
import "./TransferCard.scss";
import { showPriceValue } from "../../../Helpers/SettingHelper";

const TransferCard = (tProps) => {
  const { item, state, index, addTransfer, loadRatingstar } = tProps;
  const isMobile = window.innerWidth <= 480;

  return (
    <li className="transfer-card" key={index}>
      <div className="hsr-lhs">
        <div className="hsr-lhs-img textcenter">
          <img src={item.vehicle_image} alt="vehicle_image" />{" "}
        </div>
        <div className="hsr-lhs-desc">
          <h4>
            From{" "}
            {state.transferType === "onward"
              ? `${state.searchdata.destination_airport_name}(${state.searchdata.destination_airport_code}) - ${state.selectedHotel.hotel_name}`
              : `${state.selectedHotel.hotel_name} - to ${state.searchdata.destination_airport_name}(${state.searchdata.destination_airport_code})`}
          </h4>
          <div className="star-rating-review">
            <div className="star-rating">{loadRatingstar(item.rating)}</div>
            <div className="sr-review">
              <strong>{item.rating}</strong>
            </div>
          </div>
          <div className="scroll-cntnr">
            <ul className="mainpoints scroll-row list-mainpoints">
              <li>Estimated journey {item.duration_min} min</li>
              <li>Estimated distance {item.distance_km} km</li>
              <li>
                {item.minimum_occupancy_from} - {item.maximum_occupancy_to}{" "}
                passengers
              </li>
              <li>
                {item.number_of_big_bag} suitcases & {item.number_of_small_bag}{" "}
                Small bag per vehicle
              </li>
              {item.disclaimer === "1" && (
                <li>
                  {" "}
                  <a
                    href={() => false}
                    // onClick={this.props.viewdisclaimer.bind(
                    //   this,
                    //   transferList[selectedTransfer],
                    //   "selected_transfer",
                    //   this.props.transferType
                    // )}
                  >
                    {" "}
                    Transfer disclaimer
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="hsr-rhs">
          <p>
            +
            {showPriceValue(
              item.total_amount,
              state.currentCurrency,
              state.currencyRate
            )}
          </p>
        </div>
      )}
      <div className="footer transfer-footer">
        <div className="tags">
          <div className="tag">
            {item.vehicle_make_type !== ""
              ? item.vehicle_make_type
              : item.vehicle}{" "}
            Or {item.transfer_company}
          </div>
        </div>
        {isMobile && (
          <div className="hsr-rhs">
            <p>
              +
              {showPriceValue(
                item.total_amount,
                state.currentCurrency,
                state.currencyRate
              )}
            </p>
          </div>
        )}
        <a
          href={() => false}
          className="button add-btn"
          onClick={() => addTransfer(index, item)}
        >
          Add Transfer
        </a>
        {/* ) : (
          <a
            href={() => false}
            className="button ghost-button remove-btn"
            onClick={this.props.transferRemove.bind(
              this,
              this.props.transferType
            )}
          >
            Remove
          </a>
        )} */}
      </div>
    </li>
  );
};

export default TransferCard;
