import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import ContentLoader from "react-content-loader";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "../Home/Search/SearchComponent.scss";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl, cookieConfig, landingPageapiUrl } from "../Helpers/Config";
import { showPriceValue, checkingDiscount } from "../Helpers/SettingHelper";
import ExclamatoryIcon from "../../common/images/exclamatory-icon.svg";
import infoIcon from "../../common/images/info.svg";
import { actinfo1, flight, avholder, medal } from "../Helpers/Images";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heartfill.svg";
import filterIcon from "../../common/images/filterIcon.svg";
import sortIcon from "../../common/images/sort.svg";
import FilterSheet from "./FilterSheet";
import SignupComponent from "../Layout/SignupComponent";
import PackageLeadModel from "./PackageLeadModel/PackageLead";
import { throttle } from "lodash";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import FlightIcon from "../../common/images/p_flight.svg";
import TicketIcon from "../../common/images/p_ticket.svg";
import BedIcon from "../../common/images/p_bed.svg";
import CarIcon from "../../common/images/p_car.svg";
import foodIcon from "../../common/images/Food.svg";
import flagIcon from "../../common/images/flag.svg";
var qs = require("qs");

const priceFilterOptions = [
  {
    label: "Below S$500",
    value: "500-",
  },
  {
    label: "S$500 - S$1000",
    value: "500-1000",
  },
  {
    label: "S$2000 - S$5000",
    value: "2000-5000",
  },
  {
    label: "Above S$5000",
    value: "-5000",
  },
];
const daysFilter = [
  {
    label: "2 to 5 days",
    value: "2-5",
  },
  {
    label: "6 to 8 days",
    value: "6-8",
  },
  {
    label: "9 to 12 days",
    value: "9-12",
  },
  {
    label: "13 to 15 days",
    value: "13-15",
  },
  {
    label: "16 and more days",
    value: "16-25",
  },
];

const categoryFilter = ["Couple", "Solo", "Friends", "Family"];
class Customerpackage extends Component {
  constructor(props) {
    super(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    var start_date,
      end_date,
      search_days,
      adults,
      child,
      infant,
      source_airport_code,
      location_source,
      source_airport_id,
      destination_airport_code,
      location_destination,
      destination_airport_id,
      destinationCountry,
      tripcount,
      packageType,
      destinationCity,
      packageMode = "";

    var searchdata = "";
    var priceFilter = null;
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      searchdata = this.props.location.state;
      start_date = searchdata.start_date;
      end_date = searchdata.end_date;
      search_days = searchdata.search_days;
      adults = searchdata.adults;
      child = searchdata.child;
      infant = searchdata.infant;
      source_airport_code = searchdata.source_airport_code;
      location_source = searchdata.location_source;
      source_airport_id = searchdata.source_airport_id;
      destination_airport_code = searchdata.destination_airport_code;
      location_destination = searchdata.location_destination;
      destination_airport_id = searchdata.destination_airport_id;
      destinationCountry = searchdata.destinationCountry;
      destinationCity = searchdata.destinationCity;
      tripcount = searchdata.packageType !== "uop" ? searchdata.trip_count : "";
      packageType = searchdata.packageType;
      priceFilter = searchdata?.priceFilter || null;
      packageMode = searchdata?.packageMode || "";
    } else {
      this.props.history.push("/");
    }
    this.state = {
      triggerLogin: false,
      UserId: UserId,
      searchdata: searchdata,
      start_date: start_date,
      end_date: end_date,
      selectedMonth: Array.isArray(searchdata?.selectedMonth)
        ? searchdata?.selectedMonth
        : searchdata?.selectedMonth
        ? [searchdata?.selectedMonth]
        : [],
      search_days: Array.isArray(search_days)
        ? search_days
        : search_days
        ? [search_days]
        : [],
      adults: adults,
      child: child,
      infant: infant,
      source_airport_code: source_airport_code,
      location_source: location_source,
      source_airport_id: source_airport_id,
      destination_airport_code: destination_airport_code,
      location_destination: location_destination,
      destination_airport_id: destination_airport_id,
      destinationCountry: destinationCountry,
      destinationCity: destinationCity,
      display_package: "",
      display_No_package: "",
      loadmore: false,
      startPrice: "",
      endPrice: "",
      displayFilter: "",
      city_List_carousel: {},
      noofpackages: "",
      isMobile: window.innerWidth <= 480,
      openFilterSheet: false,
      openSortSheet: false,
      searchResult: [],
      category: ["Couple", "Solo", "Friends", "Family"],
      categorylist: [],
      filterResult: [],
      count: "",
      maxPrice: "",
      sortOption: "",
      wishlist: [],
      wishlistitem: "",
      addedWishlist: [],
      removedWishlist: [],
      locationData: {},
      priceFilter: priceFilter,
      inititalfilterApply: priceFilter !== null ? "Yes" : "No",
      tripcount: tripcount,
      trip_day_packages_status: "Yes",
      trid_day_message: "",
      filtermode_searchpackage: false,
      wish: [],
      isFlight: false,
      isActivities: false,
      isVacation: true,
      showMoreMonths: false,
      packageType: packageType,
      packageMode: packageMode,
      expandedFilters: ["category", "days", "month", "price", "segment"],
      selectedSegment: [],
      showMorefilter: false,
      segments: [],
      filterSegment: [],
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: {},
      searchfilter: true,
      showPackageLeadPopup: false,
      selectAutoPackageLeadPopup: false,
      selectedItem: {},
      showSignupPopup: false,
      pageState: 1,
      loadmoreState: false,
      uoptotalpackages: 0,
      vacationtotalpackages: 0,
      totalpackagecount: 0,
      loadMoreLoading: false,
      nextTwelveMonths: [],
      searchPopupFlow: this.props.location?.state?.searchPopupFilter || false,
    };
    this.segmentFilter = this.segmentFilter.bind(this);
    this.searchPackage = throttle(this.searchPackage.bind(this), 300);
    this.handleApplyFilter = throttle(this.handleApplyFilter.bind(this), 300);
  }
  generateNextMonths = (date = new Date(), count = 12, result = []) => {
    if (count === 0) return result;
    const label = date.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    const value = `${date.toLocaleString("default", {
      month: "long",
    })}-${date.getFullYear()}`;
    const nextDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return this.generateNextMonths(nextDate, count - 1, [
      ...result,
      { label, value },
    ]);
  };
  componentDidMount() {
    this.loadFilter();
    this.loadCity();
    this.fetchLocationData();
    if (this.state.searchdata !== "") {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
      });
    }
    const months = this.generateNextMonths();
    this.setState({ nextTwelveMonths: months });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.tripcount !== this.state.tripcount) {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
      });
    }
  }

  componentWillUnmount() {
    this.searchPackage.cancel();
    this.handleApplyFilter.cancel();
  }
  sateValChange = (field, value) => {
    if (field === "proceedtocontinue" && value === "Yes") {
      if (field === "searchpackage") {
        this.setState({ userID: cookie.load("UserId") }, function () {
          this.addToWishlist(this.state.wishlistitem);
        });
      }
    }
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };

  fetchLocationData() {
    axios
      .get(
        apiUrl + "package/getImages?city_name=" + this.state.destinationCity,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            locationData: res.data.result_set,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  processPackages(packages) {
    let wishlist = [...this.state.wish];

    let minPrice = Math.min(
      ...packages.map((item) => item.creator_package_price)
    );
    let maxPrice = Math.max(
      ...packages.map((item) => item.creator_package_price)
    );

    const wishlistCustomers = packages.filter(
      (customer) => customer.wishlist === "yes"
    );
    const wishlistCustomerIds = wishlistCustomers.map(
      (customer) => customer.creator_package_id
    );
    wishlist.push(...wishlistCustomerIds);

    const existingPackageIds = new Set(
      this.state.searchResult.map((pkg) => pkg.creator_package_id)
    );

    const filteredPackages = packages.filter(
      (item) =>
        item.creator_package_source === this.state.packageType &&
        !existingPackageIds.has(item.creator_package_id)
    );

    const searchResult = [...this.state.searchResult, ...filteredPackages];
    this.setState(
      {
        wish: wishlist,
        startPrice: minPrice,
        endPrice: maxPrice,
        maxPrice: maxPrice,
      },
      function () {
        this.loadFilter();
      }
    );
    if (this.state.sortOption !== "") {
      if (this.state.sortOption === "lowToHigh") {
        searchResult.sort(
          (a, b) => a.creator_package_price - b.creator_package_price
        );
      } else if (this.state.sortOption === "highToLow") {
        searchResult.sort(
          (a, b) => b.creator_package_price - a.creator_package_price
        );
      }
    }
    this.setState(
      {
        searchResult: searchResult,
        filterResult: searchResult,
        loadMoreLoading: false,
      },
      function () {
        this.displayPackage();
        this.handleApplyFilter();
        this.handleClearAll();
      }
    );
  }

  searchPackage(filter = "none") {
    let no_of_people = [];
    this.setState({
      loadmoreState: false,
      packageLoading: filter !== "loadmore" ? true : false,
    });

    if (this.state.adults > 0) {
      no_of_people.push({ people: "Adult", quantity: this.state.adults });
    }
    if (this.state.child > 0) {
      no_of_people.push({ people: "Child", quantity: this.state.child });
    }
    if (this.state.infant > 0) {
      no_of_people.push({ people: "Infant", quantity: this.state.infant });
    }
    if (this.props.location?.state?.packageMode === "homeLandingPackages") {
      let packages = this.props.location?.state?.homePackages.packages;
      packages = packages.filter(
        (pkg) => pkg.creator_package_source === this.state.packageType
      );
      this.processPackages(packages);
      this.filterSegment();
    } else {
      const {
        source_airport_code,
        destination_airport_code,
        destinationCountry,
        destinationCity,
        packageType,
        search_days,
      } = this.state;
      const queryParams = {
        source: source_airport_code,
        destination: destination_airport_code,
        return_source: destination_airport_code,
        return_destination: destinationCountry,
        country: destinationCountry,
        city: destinationCity,
        packageType: packageType,
      };
      if (filter === "none" || filter === "loadmore" || filter === "clearall") {
        queryParams.page = filter === "clearall" ? 1 : this.state.pageState;
        queryParams.limit = 10;
      } else if (filter === "filter") {
        const daysfilter = search_days
          .map((item) => `'${item}'`)
          .join(", ")
          .replace(/'/g, "");
        queryParams.creator_package_total_days = daysfilter;
        // queryParams.creator_package_travelling = categoryfilter;
        // queryParams.segment_theme = selectedseg;
        // queryParams.creator_package_travelling = 10;
      } else if (filter === "clearall") {
        this.handleClearAll();
      }

      const queryString = new URLSearchParams(queryParams).toString();
      axios
        .get(`${apiUrl}package/searchpackage?${queryString}`, {
          headers: { Authorization: cookie.load("acccesstoken") },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            let packages = res.data.result_set.packages;
            let showLoadMore =
              queryParams.page !== undefined && packages.length === 10;
            this.setState({
              currencyRate: res.data.currency,
              loadmoreState: showLoadMore,
              uoptotalpackages: res.data.uopPackages_count,
              vacationtotalpackages: res.data.vacationPackages_count,
              totalpackagecount: res.data.total_count,
              packageLoading: false,
            });
            if (filter === "filter") {
              this.handleApplyFilter(packages);
            }
            if (filter === "clearall") {
              this.setState(
                {
                  searchResult: packages,
                  filterResult: packages,
                  loadMoreLoading: false,
                  pageState: 1,
                },
                () => {
                  this.handleClearAll();
                }
              );
            } else if (filter === "loadmore") {
              const existingPackageIds = new Set(
                this.state.searchResult.map((pkg) => pkg.creator_package_id)
              );

              const filteredPackages = packages.filter(
                (item) =>
                  item.creator_package_source === this.state.packageType &&
                  !existingPackageIds.has(item.creator_package_id)
              );
              let searchResult = [
                ...this.state.searchResult,
                ...filteredPackages,
              ];
              this.setState((prevState) => ({
                searchResult,
                filterResult: searchResult,
                loadMoreLoading: false,
                pageState: prevState.pageState,
              }));
            } else if (filter === "none" || filter === "") {
              this.setState(
                {
                  searchResult: packages,
                  filterResult: packages,
                },
                () => {
                  this.handleApplyFilter(packages);
                }
              );
            }
            //  this.processPackages(packages);
            this.filterSegment();
            this.displayPackage();
          }
          if (res.data.trip_status === "no") {
            this.setState({
              trip_day_packages_status: "No",
              trid_day_message: res.data.message,
            });
          } else {
            this.setState({
              trip_day_packages_status: "Yes",
              trid_day_message: "",
            });
          }
        });
    }
  }

  displayPackage() {
    var display_package = "";
    var display_No_package = "";
    if (this.state.isMobile) {
      if (this.state.searchResult && this.state.searchResult.length > 0) {
        display_package =
          this.state.searchResult.length > 0
            ? this.state.filterResult.map((item, index) => {
                var discount = checkingDiscount(
                  item.discount,
                  item.creator_package_price
                );
                if (
                  (this.state.packageType === "uop" &&
                    item.creator_package_source === "uop") ||
                  (this.state.packageType !== "uop" &&
                    item.creator_package_source === "vacation")
                ) {
                  return (
                    <li key={index}>
                      <div
                        className="product-list-parent"
                        onClick={this.selectPackage.bind(this, item)}
                      >
                        <div className="product-img">
                          <a
                            href={void 0}
                            className="like"
                            onClick={() => this.addToWishlist(item)}
                          >
                            {this.state?.wish[0]?.includes(
                              item?.creator_package_id
                            ) ? (
                              <img src={heartfill} alt="Filled Heart" />
                            ) : (
                              <img src={heart} alt="Empty Heart" />
                            )}
                          </a>
                          <a
                            href={void 0}
                            className="pt-img"
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            <img
                              src={
                                this.state.isMobile
                                  ? item?.creator_package_thumbnail_mobile ||
                                    actinfo1
                                  : item?.creator_package_thumbnail_web ||
                                    actinfo1
                              }
                              alt="actinfo"
                              loading="lazy"
                            />
                          </a>
                          <div className="ptd-day">
                            {item.creator_package_total_days}D/
                            {item.creator_package_total_days - 1}N
                          </div>
                          {discount !== "" && (
                            <div className="package-discount">
                              {showPriceValue(
                                discount.amount,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}{" "}
                              Off
                            </div>
                          )}
                        </div>
                        <div className="product-description">
                          <div className="tags-row">
                            <div className="new-tags">
                              {item.creator_package_travelling && (
                                <div className="c-tags">
                                  {item.creator_package_travelling}
                                </div>
                              )}
                              {item?.category_title && (
                                <div className="e-tags">
                                  {item?.category_title}
                                </div>
                              )}
                            </div>
                            {!this.state.isMobile && (
                              <div className="days-col">
                                {item.creator_package_total_days} Days,{" "}
                                {item.creator_package_total_days - 1} Nights
                              </div>
                            )}
                          </div>
                          <div className="product-description-title">
                            <div className="title-col">
                              <h5>
                                <a
                                  href={0}
                                  onClick={this.selectPackage.bind(this, item)}
                                >
                                  {item.creator_package_name}
                                </a>{" "}
                              </h5>
                            </div>
                            {item.creator_package_rating !== "" &&
                              item.creator_package_rating !== null && (
                                <div className="review-col pdr-lhs">
                                  <span>
                                    <strong>
                                      {item.creator_package_rating}
                                    </strong>{" "}
                                    ({item.creator_package_total_rating})
                                    Reviews
                                  </span>
                                  <div className="star-rating">
                                    {this.loadRatingstar(
                                      item.creator_package_id === "382"
                                        ? "4"
                                        : item.creator_package_rating
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="product-created-content">
                            <div className="created-av">
                              <img
                                src={
                                  item.creator_image !== "" &&
                                  item.creator_image !== null
                                    ? item.creator_image
                                    : avholder
                                }
                                alt={item.creator_image}
                              />
                            </div>
                            <div className="created-av-title">
                              <h4>
                                <span>
                                  {item?.creator_package_source === "uop"
                                    ? "Operated by"
                                    : "Created by"}
                                </span>

                                {item.creator_handle_name}
                              </h4>
                              <div className="creator-badge">
                                <span>
                                  <img src={medal} alt="Medal" />
                                </span>{" "}
                                <strong>
                                  Level <em>01</em>
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`sites-content ${
                              this.state.packageType === "uop" ? "mb" : ""
                            }`}
                          >
                            <ul>
                              {item?.creator_package_included
                                ?.split(",")
                                ?.map((item, index) => (
                                  <li key={index}>
                                    {item?.includes("Flight") && (
                                      <img src={FlightIcon} alt="FlightIcon" />
                                    )}
                                    {item?.includes("Stay") && (
                                      <img src={BedIcon} alt="BedIcon" />
                                    )}
                                    {item?.includes("Transport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Airport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Things") && (
                                      <img src={TicketIcon} alt="TicketIcon" />
                                    )}
                                    {item?.includes("Guide") && (
                                      <img src={flagIcon} alt="flagIcon" />
                                    )}
                                    {item?.includes("Food") && (
                                      <img src={foodIcon} alt="foodIcon" />
                                    )}
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>{item}</figcaption>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="footer-main">
                            <div className="product-description-review">
                              <div className="pdr-lhs"></div>
                              {item?.creator_package_source === "uop" && (
                                <div className="pdr-rhs">
                                  {discount !== "" ? (
                                    <>
                                      <span className="package-original-amt">
                                        {showPriceValue(
                                          item.creator_package_price,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <em>Starts from</em>
                                  )}
                                  <strong>
                                    {showPriceValue(
                                      item.creator_package_price,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>{" "}
                                </div>
                              )}
                            </div>
                            <div className="cp-main">
                              <Button variant="contained">
                                {item?.creator_package_source === "uop"
                                  ? "View package"
                                  : "Customize your trip"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })
            : !this.state.searchfilter
            ? `NO PACKAGES FOUND for ${this.state.search_days} days`
            : "NO PACKAGES FOUND";
      }
    } else {
      if (this.state.filterResult && this.state.filterResult.length > 0) {
        display_package =
          this.state.filterResult.length > 0 &&
          this.state.filterResult.map((item, index) => {
            var discount = checkingDiscount(
              item.discount,
              item.creator_package_price
            );
            var displyAmount = item.creator_package_price;
            if (discount !== "") {
              displyAmount =
                parseFloat(item.creator_package_price) -
                parseFloat(discount.amount);
            }
            if (
              (this.state.packageType === "uop" &&
                item.creator_package_source === "uop") ||
              (this.state.packageType !== "uop" &&
                item.creator_package_source === "vacation")
            ) {
              return (
                <li key={index}>
                  <div className="product-list-parent">
                    <div className="product-img">
                      <a
                        className="pt-img"
                        href={void 0}
                        onClick={this.selectPackage.bind(this, item)}
                      >
                        <img
                          src={
                            this.state.isMobile
                              ? item?.creator_package_thumbnail_mobile ||
                                actinfo1
                              : item?.creator_package_thumbnail_web || actinfo1
                          }
                          alt="actinfo"
                          loading="lazy"
                        />
                      </a>
                      <div className="ptd-day">
                        {" "}
                        {item.creator_package_total_days}D/
                        {item.creator_package_total_days - 1}N
                      </div>
                      {discount !== "" && (
                        <div className="package-discount">
                          {showPriceValue(
                            discount.amount,
                            this.state.currentCurrency,
                            this.state.currencyRate
                          )}{" "}
                          Off
                        </div>
                      )}
                    </div>
                    <div className="product-description">
                      <div className="new-tags">
                        {item.creator_package_travelling !== "" &&
                          item.creator_package_travelling !== null && (
                            <div className="c-tags">
                              {item?.creator_package_travelling}
                            </div>
                          )}
                        {item.category_title !== "" &&
                          item.category_title !== null && (
                            <div className="e-tags">{item?.category_title}</div>
                          )}
                      </div>
                      <a
                        href={void 0}
                        className="like"
                        onClick={() => this.addToWishlist(item)}
                      >
                        {this.state?.wish[0]?.includes(
                          item?.creator_package_id
                        ) ? (
                          <img src={heartfill} alt="Filled Heart" />
                        ) : (
                          <img src={heart} alt="Empty Heart" />
                        )}
                      </a>
                      <div className="product-description-title">
                        <h5>
                          <a
                            href={void 0}
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            {item.creator_package_name}
                          </a>{" "}
                        </h5>
                      </div>
                      <div className="product-created-content">
                        <div className="created-av">
                          <img
                            src={
                              item.creator_image !== "" &&
                              item.creator_image !== null
                                ? item.creator_image
                                : avholder
                            }
                            alt={item.creator_image}
                          />
                        </div>
                        <div className="created-av-title">
                          <h4>
                            <span>
                              {item?.creator_package_source === "uop"
                                ? "Operated by"
                                : "Created by"}
                            </span>

                            {item.creator_handle_name}
                          </h4>
                          <div className="creator-badge">
                            <span>
                              <img src={medal} alt="Medal" />
                            </span>{" "}
                            <strong>
                              Level <em>01</em>
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="m-row">
                        <div className="col1">
                          <div className="sites-content">
                            <ul>
                              {item?.creator_package_included
                                ?.split(",")
                                ?.map((item, index) => (
                                  <li key={index}>
                                    {item?.includes("Flight") && (
                                      <img src={FlightIcon} alt="" />
                                    )}
                                    {item?.includes("Stay") && (
                                      <img src={BedIcon} alt="" />
                                    )}
                                    {item?.includes("Transport") && (
                                      <img src={CarIcon} alt="" />
                                    )}
                                    {item?.includes("Airport") && (
                                      <img src={CarIcon} alt="" />
                                    )}
                                    {item?.includes("Things") && (
                                      <img src={TicketIcon} alt="TicketIcon" />
                                    )}
                                    {item?.includes("Guide") && (
                                      <img src={flagIcon} alt="flagIcon" />
                                    )}
                                    {item?.includes("Food") && (
                                      <img src={foodIcon} alt="foodIcon" />
                                    )}
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>{item}</figcaption>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col3"></div>
                        {item?.creator_package_source === "uop" && (
                          <div className="col2">
                            {discount !== "" ? (
                              <div>
                                <span className="package-discount">
                                  {showPriceValue(
                                    discount.amount,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}{" "}
                                  Off
                                </span>
                              </div>
                            ) : null}
                            <>
                              <div className="price-row">
                                <em>Starts from</em>
                                {discount !== "" ? (
                                  <div className="package-original-amt">
                                    {showPriceValue(
                                      item.creator_package_price,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </div>
                                ) : null}
                                <strong>
                                  {showPriceValue(
                                    displyAmount,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>{" "}
                              </div>
                            </>
                          </div>
                        )}
                      </div>

                      <div className="product-description-review">
                        <div className="pdr-rhs">
                          {item.creator_package_rating !== "" &&
                            item.creator_package_rating !== null && (
                              <span>
                                <strong>{item.creator_package_rating}</strong> (
                                {item.creator_package_total_rating}) Reviews
                              </span>
                            )}
                          <div className="star-rating">
                            {this.loadRatingstar(
                              item.creator_package_id === "382"
                                ? "4"
                                : item.creator_package_rating
                            )}
                          </div>
                          {item?.next_availability_slot && (
                            <div className="cp-tags">
                              Next Available slot: {item.next_availability_slot}
                            </div>
                          )}
                        </div>
                        <div className="pdr-rhs">
                          <a
                            href={void 0}
                            className="button button-fill"
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            {item?.creator_package_source === "uop"
                              ? "View package"
                              : "Customize your trip"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
            return null;
          });
      }
    }
    if (this.state.isMobile) {
      if (this.state.searchResult && this.state.searchResult.length > 0) {
        display_No_package =
          this.state.searchResult.length > 0
            ? this.state.searchResult.map((item, index) => {
                var discount = checkingDiscount(
                  item.discount,
                  item.creator_package_price
                );
                if (
                  (this.state.packageType === "uop" &&
                    item.creator_package_source === "uop") ||
                  (this.state.packageType !== "uop" &&
                    item.creator_package_source === "vacation")
                ) {
                  return (
                    <li key={index}>
                      <div
                        className="product-list-parent"
                        onClick={this.selectPackage.bind(this, item)}
                      >
                        <div className="product-img">
                          <a
                            href={void 0}
                            className="like"
                            onClick={() => this.addToWishlist(item)}
                          >
                            {this.state?.wish[0]?.includes(
                              item?.creator_package_id
                            ) ? (
                              <img src={heartfill} alt="Filled Heart" />
                            ) : (
                              <img src={heart} alt="Empty Heart" />
                            )}
                          </a>
                          <a
                            className="pt-img"
                            href={void 0}
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            <img
                              src={
                                this.state.isMobile
                                  ? item?.creator_package_thumbnail_mobile ||
                                    actinfo1
                                  : item?.creator_package_thumbnail_web ||
                                    actinfo1
                              }
                              alt="actinfo"
                              loading="lazy"
                            />
                          </a>
                          <div className="ptd-day">
                            {item.creator_package_total_days}D/
                            {item.creator_package_total_days - 1}N
                          </div>
                          {discount !== "" && (
                            <div className="package-discount">
                              {showPriceValue(
                                discount.amount,
                                this.state.currentCurrency,
                                this.state.currencyRate
                              )}{" "}
                              Off
                            </div>
                          )}
                        </div>
                        <div className="product-description">
                          <div className="tags-row">
                            <div className="new-tags">
                              {item.creator_package_travelling && (
                                <div className="c-tags">
                                  {item.creator_package_travelling}
                                </div>
                              )}
                              {item?.category_title && (
                                <div className="e-tags">
                                  {item?.category_title}
                                </div>
                              )}
                            </div>
                            {!this.state.isMobile && (
                              <div className="days-col">
                                {item.creator_package_total_days} Days,{" "}
                                {item.creator_package_total_days - 1} Nights
                              </div>
                            )}
                          </div>
                          <div className="product-description-title">
                            <div className="title-col">
                              <h5>
                                <a
                                  href={void 0}
                                  onClick={this.selectPackage.bind(this, item)}
                                >
                                  {item.creator_package_name}
                                </a>{" "}
                              </h5>
                            </div>
                            {item.creator_package_rating !== "" &&
                              item.creator_package_rating !== null && (
                                <div className="review-col pdr-lhs">
                                  <span>
                                    <strong>
                                      {item.creator_package_rating}
                                    </strong>{" "}
                                    ({item.creator_package_total_rating})
                                    Reviews
                                  </span>
                                  <div className="star-rating">
                                    {this.loadRatingstar(
                                      item.creator_package_id === "382"
                                        ? "4"
                                        : item.creator_package_rating
                                    )}
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="product-created-content">
                            <div className="created-av">
                              <img
                                src={
                                  item.creator_image !== "" &&
                                  item.creator_image !== null
                                    ? item.creator_image
                                    : avholder
                                }
                                alt={item.creator_image}
                              />
                            </div>
                            <div className="created-av-title">
                              <h4>
                                <span>
                                  {item?.creator_package_source === "uop"
                                    ? "Operated by"
                                    : "Created by"}
                                </span>

                                {item.creator_handle_name}
                              </h4>
                              <div className="creator-badge">
                                <span>
                                  <img src={medal} alt="Medal" />
                                </span>{" "}
                                <strong>
                                  Level <em>01</em>
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div className="sites-content">
                            <ul>
                              {item?.creator_package_included
                                ?.split(",")
                                ?.map((item, index) => (
                                  <li key={index}>
                                    {item?.includes("Flight") && (
                                      <img src={FlightIcon} alt="FlightIcon" />
                                    )}
                                    {item?.includes("Stay") && (
                                      <img src={BedIcon} alt="BedIcon" />
                                    )}
                                    {item?.includes("Transport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Airport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Things") && (
                                      <img src={TicketIcon} alt="TicketIcon" />
                                    )}
                                    {item?.includes("Guide") && (
                                      <img src={flagIcon} alt="flagIcon" />
                                    )}
                                    {item?.includes("Food") && (
                                      <img src={foodIcon} alt="foodIcon" />
                                    )}
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>{item}</figcaption>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="footer-main">
                            <div className="product-description-review">
                              <div className="pdr-lhs"></div>
                              {item?.creator_package_source === "uop" && (
                                <div className="pdr-rhs">
                                  {discount !== "" ? (
                                    <>
                                      <span className="package-original-amt">
                                        {showPriceValue(
                                          item.creator_package_price,
                                          this.state.currentCurrency,
                                          this.state.currencyRate
                                        )}
                                      </span>
                                    </>
                                  ) : (
                                    <em>Starts from</em>
                                  )}
                                  <strong>
                                    {showPriceValue(
                                      item.creator_package_price,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>{" "}
                                </div>
                              )}
                            </div>
                            <div className="cp-main">
                              <Button variant="contained">
                                {item?.creator_package_source === "uop"
                                  ? "View package"
                                  : "Customize your trip"}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })
            : !this.state.searchfilter
            ? `NO PACKAGES FOUND for ${this.state.search_days} days`
            : "NO PACKAGES FOUND";
      }
    } else {
      if (this.state.searchResult && this.state.searchResult.length > 0) {
        display_No_package =
          this.state.searchResult.length > 0 &&
          this.state.searchResult.map((item, index) => {
            var discount = checkingDiscount(
              item.discount,
              item.creator_package_price
            );
            var displyAmount = item.creator_package_price;
            if (discount !== "") {
              displyAmount =
                parseFloat(item.creator_package_price) -
                parseFloat(discount.amount);
            }
            if (
              (this.state.packageType === "uop" &&
                item.creator_package_source === "uop") ||
              (this.state.packageType !== "uop" &&
                item.creator_package_source === "vacation")
            ) {
              return (
                <li key={index}>
                  <div className="product-list-parent">
                    <div className="product-img">
                      <a
                        className="pt-img"
                        href={void 0}
                        onClick={this.selectPackage.bind(this, item)}
                      >
                        <img
                          src={
                            this.state.isMobile
                              ? item?.creator_package_thumbnail_mobile ||
                                actinfo1
                              : item?.creator_package_thumbnail_web || actinfo1
                          }
                          alt="actinfo"
                          loading="lazy"
                        />
                      </a>
                      <div className="ptd-day">
                        {" "}
                        {item.creator_package_total_days} Days
                      </div>
                      {discount !== "" && (
                        <div className="package-discount">
                          {showPriceValue(
                            discount.amount,
                            this.state.currentCurrency,
                            this.state.currencyRate
                          )}{" "}
                          Off
                        </div>
                      )}
                    </div>
                    <div className="product-description">
                      <div className="new-tags">
                        {item.creator_package_travelling !== "" &&
                          item.creator_package_travelling !== null && (
                            <div className="c-tags">
                              {item?.creator_package_travelling}
                            </div>
                          )}
                        {item.category_title !== "" &&
                          item.category_title !== null && (
                            <div className="e-tags">{item?.category_title}</div>
                          )}
                        {item?.creator_package_source === "uop" && (
                          <div className="cp-tags">Curated package</div>
                        )}
                      </div>
                      <a
                        href={void 0}
                        className="like"
                        onClick={() => this.addToWishlist(item)}
                      >
                        {this.state?.wish[0]?.includes(
                          item?.creator_package_id
                        ) ? (
                          <img src={heartfill} alt="Filled Heart" />
                        ) : (
                          <img src={heart} alt="Empty Heart" />
                        )}
                      </a>
                      <div className="product-description-title">
                        <h5>
                          <a
                            href={void 0}
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            {item.creator_package_name}
                          </a>{" "}
                        </h5>
                      </div>
                      <div className="product-created-content">
                        <div className="created-av">
                          <img
                            src={
                              item.creator_image !== "" &&
                              item.creator_image !== null
                                ? item.creator_image
                                : avholder
                            }
                            alt={item.creator_image}
                          />
                        </div>
                        <div className="created-av-title">
                          <h4>
                            <span>
                              {item?.creator_package_source === "uop"
                                ? "Operated by"
                                : "Created by"}
                            </span>

                            {item.creator_handle_name}
                          </h4>
                          <div className="creator-badge">
                            <span>
                              <img src={medal} alt="Medal" />
                            </span>{" "}
                            <strong>
                              Level <em>01</em>
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="m-row">
                        <div className="col1">
                          <div className="sites-content">
                            <ul>
                              {item?.creator_package_included
                                ?.split(",")
                                ?.map((item, index) => (
                                  <li key={index}>
                                    {item?.includes("Flight") && (
                                      <img src={FlightIcon} alt="FlightIcon" />
                                    )}
                                    {item?.includes("Stay") && (
                                      <img src={BedIcon} alt="BedIcon" />
                                    )}
                                    {item?.includes("Transport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Airport") && (
                                      <img src={CarIcon} alt="CarIcon" />
                                    )}
                                    {item?.includes("Things") && (
                                      <img src={TicketIcon} alt="TicketIcon" />
                                    )}
                                    {item?.includes("Guide") && (
                                      <img src={flagIcon} alt="flagIcon" />
                                    )}
                                    {item?.includes("Food") && (
                                      <img src={foodIcon} alt="foodIcon" />
                                    )}
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>{item}</figcaption>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col3"></div>
                        {item?.creator_package_source === "uop" && (
                          <div className="col2">
                            {discount !== "" ? (
                              <div>
                                <span className="package-discount">
                                  {showPriceValue(
                                    discount.amount,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}{" "}
                                  Off
                                </span>
                              </div>
                            ) : null}
                            <>
                              <div className="price-row">
                                <em>Starts from</em>
                                {discount !== "" ? (
                                  <div className="package-original-amt">
                                    {showPriceValue(
                                      item.creator_package_price,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </div>
                                ) : null}
                                <strong>
                                  {showPriceValue(
                                    displyAmount,
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>{" "}
                              </div>
                            </>
                          </div>
                        )}
                      </div>

                      <div className="product-description-review">
                        <div className="pdr-rhs">
                          {item.creator_package_rating !== "" &&
                            item.creator_package_rating !== null && (
                              <span>
                                <strong>{item.creator_package_rating}</strong> (
                                {item.creator_package_total_rating}) Reviews
                              </span>
                            )}
                          <div className="star-rating">
                            {this.loadRatingstar(
                              item.creator_package_id === "382"
                                ? "4"
                                : item.creator_package_rating
                            )}
                          </div>
                          {item?.next_availability_slot && (
                            <div className="cp-tags">
                              Next Available slot: {item.next_availability_slot}
                            </div>
                          )}
                        </div>
                        <div className="pdr-rhs">
                          <button
                            href={void 0}
                            className="button button-fill"
                            onClick={this.selectPackage.bind(this, item)}
                          >
                            {item?.creator_package_source === "uop"
                              ? "View package"
                              : "Customize your trip"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            }
            return null;
          });
      }
    }

    this.setState({ display_package, display_No_package }, function () {
      setTimeout(() => {
        this.setState({ packageLoading: false });
      }, 2000);
    });
  }
  handleChange(type, event) {
    if (type === "category") {
      var categorylist = [...this.state.categorylist];

      var index = categorylist.indexOf(event);
      if (index > -1) {
        categorylist.splice(index, 1);
      } else {
        categorylist.push(event);
      }

      this.setState({ categorylist: categorylist });
    } else if (type === "month") {
      let monthlist = [...this.state.selectedMonth];
      let index = monthlist.indexOf(event);
      if (index > -1) {
        monthlist.splice(index, 1);
      } else {
        monthlist.push(event);
      }
      this.setState({ selectedMonth: monthlist });
    } else if (type === "days") {
      let searchdays = [...this.state.search_days];
      let index = searchdays.indexOf(event);
      if (index > -1) {
        searchdays.splice(index, 1);
      } else {
        searchdays.push(event);
      }
      this.setState({
        search_days: searchdays,
      });
    } else if (type === "theme") {
      let selectedSegment = [...this.state.selectedSegment];
      let index = selectedSegment.indexOf(event.segment_short_name);
      if (index > -1) {
        selectedSegment.splice(index, 1);
      } else {
        selectedSegment.push(event.segment_short_name);
      }
      this.setState({
        selectedSegment,
      });
    } else if (type === "sortOption") {
      this.setState({ sortOption: event.target.value }, () => {
        this.handleApplyFilter(this.state.searchResult);
      });
    }
  }

  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        let currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        }
        return null;
      });
    }
  }

  async selectPackage(item) {
    var leadEmail = localStorage.getItem("leadEmail") || "";
    if (leadEmail !== "") {
      this.setState({ selectedItem: item, selectAutoPackageLeadPopup: true });
    } else {
      this.setState({ selectedItem: item, showPackageLeadPopup: true });
    }
  }

  viewPackage(item) {
    var selected_City = "";
    Object.values(this.state.city_List_carousel).forEach((country) => {
      var countryDetails = Object.values(country);
      countryDetails[1].map((city) => {
        if (
          city?.city_name?.toLowerCase() ===
          item?.creator_package_city?.toLowerCase()
        ) {
          selected_City = city;
        }
        return null;
      });
    });
    var searchdata = this.state.searchdata;
    searchdata["packageType"] = item?.creator_package_source;
    if (searchdata?.destinationCity === "") {
      searchdata["airportLat"] = selected_City?.airport_latitude;
      searchdata["airportLon"] = selected_City?.airport_longitude;
      searchdata["destinationCity"] = selected_City?.city_name;
      searchdata["destinationCountry"] = selected_City?.country_name;
      searchdata["destinationLat"] = selected_City?.airport_latitude;
      searchdata["destinationLon"] = selected_City?.airport_longitude;
      searchdata["destinationWebbedsCity"] = selected_City?.webbeds_city_code;
      searchdata["destination_airport_address"] =
        selected_City?.airport_address;
      searchdata["destination_airport_code"] = selected_City?.airport_code;
      searchdata["destination_airport_id"] = selected_City?.airport_id;
      searchdata["destination_airport_lat"] = selected_City?.airport_latitude;
      searchdata["destination_airport_lon"] = selected_City?.airport_longitude;
      searchdata["location_destination"] = selected_City?.airport_address;
    }
    searchdata["total_activities"] = item?.total_activities;
    searchdata["creator_package_source"] = item?.creator_package_source;
    var endDate = new Date(searchdata.start_date);
    searchdata["end_date"] = new Date(
      endDate.setDate(
        parseInt(endDate.getDate()) +
          parseInt(item?.creator_package_total_days) -
          1
      )
    );
    searchdata["checkinTime"] =
      item?.hotel_check_in_time !== "" &&
      typeof item?.hotel_check_in_time !== undefined &&
      typeof item?.hotel_check_in_time !== "undefined"
        ? item?.hotel_check_in_time
        : "";
    var postObject = {
      creator_id: item?.creator_id,
      package_id: item?.creator_package_id,
    };

    axios
      .post(apiUrl + "package/packageClick", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.remove("flightStatus", cookieConfig);
          cookie.remove("researchFlight", cookieConfig);
          cookie.remove("flightReturnStatus", cookieConfig);

          var packagedetails = {
            searchdata: { ...searchdata, isShowEditor: false },
            hotel_check_in_time: item.hotel_check_in_time,
            creator_package_total_days: item.creator_package_total_days,
          };
          this.props.history.push({
            pathname: "/search-package/" + item.creator_package_slug,
            state: packagedetails,
          });
        } else {
          console.log("ClickApi response", res.data);
        }
      });
  }

  createOwnPackage() {
    var date = this.state.searchdata.start_date;
    const startDate = new Date(date);

    const date2String = this.state.searchdata.search_days;

    let daysToAdd;

    if (date2String === "2-5") {
      daysToAdd = 3;
    } else if (date2String === "6-8") {
      daysToAdd = 6;
    } else if (date2String === "9-12") {
      daysToAdd = 10;
    } else if (date2String === "13-15") {
      daysToAdd = 13;
    }

    const dates = [];

    for (let i = 1; i <= daysToAdd; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      dates.push(currentDate.toDateString());
    }

    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    var activities = dates.map((date, index) => ({
      day: parseInt(index) + 1,
      date: formatDate(date),
      emptyactivity: "Yes",
      activities: ["", "", ""],
    }));
    var searchdata = this.state.searchdata;
    let endDate = new Date();
    if (searchdata.end_date !== "") {
      endDate = new Date(searchdata.end_date);
    } else {
      var end_date = new Date(searchdata.start_date);
      var addedDay = end_date.setDate(
        parseInt(end_date.getDate()) + parseInt(daysToAdd + 1)
      );
      endDate = new Date(addedDay);
    }

    searchdata["end_date"] = endDate;
    searchdata["checkinTime"] = "";
    var packagedetails = {
      searchdata: searchdata,
      activities: activities,
    };

    this.props.history.push({
      pathname: "/search-package/create-own-package",
      state: packagedetails,
    });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");
    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  loadFilter() {
    var displayfilter = (
      <div className="package-top-filters">
        <div className="ptf-options fIcon">
          <ul>
            <li>
              <a href={void 0} onClick={this.openfilter.bind(this)}>
                {this.state.count > 0 && (
                  <div className="active-options">{this.state.count}</div>
                )}
                <span>
                  <img src={filterIcon} alt="filter" /> Filters
                </span>
              </a>
            </li>
          </ul>
        </div>
        {this.state.isMobile && (
          <div className="ptf-options">
            <ul>
              <li>
                <a
                  href={void 0}
                  onClick={() => this.setState({ openSortSheet: true })}
                >
                  <img src={sortIcon} alt="sortIcon" />
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
    this.setState({
      displayFilter: displayfilter,
    });
  }
  applyFilter(item) {
    var packages = false;

    if (this.state.priceFilter) {
      if (
        Number(this.state.priceFilter?.split("-")[0]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) === 0
      ) {
        packages = true;
      }
      if (
        Number(this.state.priceFilter?.split("-")[1]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) === 0
      ) {
        packages = true;
      }

      if (
        Number(this.state.priceFilter?.split("-")[0]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) !== 0 &&
        Number(this.state.priceFilter?.split("-")[1]) !== 0
      ) {
        packages = true;
      }
    } else {
      packages = true;
    }
    var category = true;
    if (this.state.categorylist.length > 0) {
      if (
        this.state.categorylist.indexOf(item.creator_package_travelling) === -1
      ) {
        category = false;
      }
    }
    var themes = true;

    if (this.state.selectedSegment.length > 0) {
      if (this.state.selectedSegment.indexOf(item.segment_theme) === -1) {
        themes = false;
      }
    }
    let monthFilter = true;
    if (
      this.state.packageType === "uop" &&
      this.state.selectedMonth.length > 0
    ) {
      const selectedMonth = this.state.selectedMonth;
      monthFilter = selectedMonth?.some((formattedMonth) =>
        item.uopavailability?.months?.includes(formattedMonth)
      );
    }
    if (packages && category && monthFilter && themes) {
      return true;
    } else {
      return false;
    }
  }
  handleApplyFilter(filter) {
    const filteredPackages =
      Array.isArray(filter) && filter.length > 0
        ? filter.filter((item) => this.applyFilter(item))
        : [];
    const sortedPackages = [...filteredPackages];

    if (this.state.sortOption === "lowToHigh") {
      sortedPackages.sort(
        (a, b) => a.creator_package_price - b.creator_package_price
      );
    } else if (this.state.sortOption === "highToLow") {
      sortedPackages.sort(
        (a, b) => b.creator_package_price - a.creator_package_price
      );
    }
    var count = 0;
    if (this.state.categorylist.length > 0) {
      count++;
    }
    this.setState({ count: count }, function () {
      this.loadFilter();
    });

    this.setState({ filterResult: sortedPackages }, function () {
      this.displayPackage();
    });
  }

  openfilter() {
    this.setState({ openFilterSheet: true });
  }

  openPopup = () => {
    this.setState({ showSignupPopup: true });
  };

  handleDaysFilter(val) {
    this.setState({
      search_days: val,
      searchfilter: false,
      filtermode_searchpackage: true,
    });
  }
  handlePriceFilter(val) {
    this.setState({ priceFilter: this.state.priceFilter === val ? null : val });
  }
  handleClearAll() {
    var { searchResult } = this.state;
    var minPrice = Math.min(
      ...searchResult.map((item) => item.creator_package_price)
    );
    var maxPrice = Math.max(
      ...searchResult.map((item) => item.creator_package_price)
    );

    this.setState(
      {
        startPrice: minPrice,
        endPrice: maxPrice,
        categorylist: [],
        count: "",
        selectedSegment: [], // Reset the selected segments
        filterResult: this.state?.searchResult,
        search_days: [],
        selectedMonth: [],
        priceFilter: "",
        pageState: 1,
        sortOption: "",
        searchPopupFlow: false,
      },
      function () {
        this.loadFilter();
        this.displayPackage();
      }
    );

    var checkboxes = document.querySelectorAll(".tag_filter");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  addToWishlist = (item) => {
    if (this.state.UserId !== "") {
      var wishlist = [...this.state.wish];
      var itemIndex = wishlist[0].indexOf(item.creator_package_id);
      if (itemIndex === -1) {
        wishlist[0].push(item.creator_package_id);
      }
      if (itemIndex !== -1) {
        wishlist[0].splice(itemIndex, 1);
      }
      var addedWishlist = [...this.state.addedWishlist];
      var formData = new FormData();
      formData.append("customer_id", cookie.load("UserId"));
      formData.append("package_id", item.creator_package_id);
      axios
        .post(apiUrl + "package/addToPackageWishlist", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((response) => {
          if (response.data.status === "ok") {
            var index = addedWishlist.findIndex(
              (removedItem) =>
                removedItem.creator_package_id === item.creator_package_id
            );
            addedWishlist.splice(index, 1);
            this.setState(
              {
                addedWishlist: addedWishlist,
                wish: wishlist,
              },
              function () {
                this.displayPackage();
                // this.addwishlist(item);
              }
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (cookie.load("UserId") !== "") {
        this.setState({ wishlistitem: item });
      }
    }
  };

  monthFilter = (month) => {
    var monthlist = [...this.state.selectedMonth];
    var index = monthlist.indexOf(month);
    if (index > -1) {
      monthlist.splice(index, 1);
    } else {
      monthlist.push(month);
    }
    this.setState({ selectedMonth: monthlist });
  };
  handlepackageType(packageType) {
    this.setState({ packageType: packageType, pageState: 1 }, () => {
      this.displayPackage();
      this.searchPackage();
      this.handleClearAll(1);
    });
  }

  handleFilterExpand(opt) {
    if (this.state.expandedFilters.includes(opt)) {
      const temp = this.state.expandedFilters.filter(
        (filter) => filter !== opt
      );
      this.setState({ expandedFilters: temp });
    } else {
      this.setState({ expandedFilters: [...this.state.expandedFilters, opt] });
    }
  }
  loadCity = async () => {
    try {
      await axios
        .get(apiUrl + "settings/getCityList", {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({
              city_List_carousel: res.data.result_set,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  onChange = (item) => (event) => {
    this.segmentFilter(item, event);
  };

  segmentFilter(item, event) {
    let selectedSegment = [...this.state.selectedSegment];

    if (event?.target) {
      if (event.target.checked === true) {
        selectedSegment.push(item.segment_short_name);
      } else {
        let index = selectedSegment.indexOf(item.segment_short_name);
        if (index > -1) {
          selectedSegment.splice(index, 1);
        }
      }
    } else {
      let index = selectedSegment.indexOf(event);
      if (index > -1) {
        selectedSegment.splice(index, 1);
      } else {
        selectedSegment.push(event);
      }
    }

    this.setState({ packageLoading: true, selectedSegment: selectedSegment });
  }
  filterSegment = () => {
    axios
      .get(landingPageapiUrl + "package/getsegments", {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ segments: res.data.response });
          const filter = res.data.response.map(
            (item) => item.segment_short_name
          );
          const filterSegment = [...new Set(filter)];
          this.setState({ filterSegment });
        }
      });
  };
  loadMore() {
    this.setState(
      (prevState) => ({
        pageState: prevState.pageState + 1,
        loadMoreLoading: true,
      }),
      () => {
        this.searchPackage("loadmore");
      }
    );
  }

  render() {
    const { packageType } = this.state;
    return (
      <>
        <Header
          {...this.props}
          sateValChange={this.sateValChange}
          triggerLogin={this.state.triggerLogin}
        />
        <PackageLeadModel
          open={this.state.showPackageLeadPopup}
          selectAutoPackageLeadPopup={this.state.selectAutoPackageLeadPopup}
          handleClose={() =>
            this.setState({
              showPackageLeadPopup: false,
            })
          }
          data={this.state.selectedItem}
          viewPackage={this.viewPackage.bind(this)}
          locationData={this.state}
        />
        <div className="search-nav">
          <div className="container"></div>
        </div>

        {!this.state.isMobile && (
          <section className="cp-list-img map-banner-parent">
            <div className="banner-map">
              <div className="bm-lhs">
                {this.state?.packageMode === "homeLandingPackages" ? (
                  <img
                    src={
                      this.props.location?.state?.homePackages?.segment_image
                    }
                    alt=""
                  />
                ) : (
                  <img src={this.state.locationData?.large_image} alt="" />
                )}
                <div className="pl-name">
                  {this.state?.packageMode === "homeLandingPackages" ? (
                    <h2>
                      {this.props.location?.state?.homePackages?.segment_name}
                    </h2>
                  ) : (
                    <h2>{this.state?.destinationCity}</h2>
                  )}
                  {this.state?.packageMode === "homeLandingPackages" ? (
                    <p>
                      {
                        this.props.location?.state?.homePackages
                          ?.segment_description
                      }
                    </p>
                  ) : (
                    <p>{this.state?.locationData?.description}</p>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="cp-list-content innersection">
          <div className="container home-page">
            <div className="package-av-list">
              <div className="package-top-filters color-head-merge">
                {this.state.isMobile && (
                  <div className="fRow">
                    <div>
                      <h2 className="color-header">
                        Your holiday packages for{" "}
                        <span>
                          {this.state.searchdata !== ""
                            ? this.state.searchdata.destinationCity
                            : ""}
                        </span>
                      </h2>
                    </div>
                    {this.state.displayFilter}
                  </div>
                )}
                <div className="category-filter">
                  <div
                    className={`category-box ${
                      this.state.packageType === "uop" ? "active" : ""
                    } ${this.state.packageLoading ? "box-disabled" : ""}`}
                    onClick={() => this.handlepackageType("uop")}
                  >
                    <div className="ctitle">Curated</div>
                    {!this.state.isMobile && (
                      <div className="cdesp">
                        Curated packages <br />
                        from Top creators
                      </div>
                    )}
                  </div>
                  <div
                    className={`category-box ${
                      this.state.packageType === "vacation" ? "active" : ""
                    } ${this.state.packageLoading ? "box-disabled" : ""}`}
                    onClick={() => this.handlepackageType("vacation")}
                  >
                    <div className="ctitle">Customizable</div>
                    {!this.state.isMobile && (
                      <div className="cdesp">
                        Hyper Customise your
                        <br /> vacation package
                      </div>
                    )}
                  </div>
                </div>
                {this.state.isMobile && (
                  <div className="sub-txt">
                    <img src={infoIcon} alt="infoIcon" />
                    {this.state.packageType === "uop"
                      ? "Curated packages from Top creators"
                      : "Customization packages from Top creators"}
                  </div>
                )}
              </div>

              <div
                className={`${this.state.isMobile ? "" : "package-list-row"}`}
              >
                {!this.state.isMobile && (
                  <div className="left-col">
                    <div className="filter-main-sticky">
                      <div className="filter-title">
                        Filters{" "}
                        <div className="action-row">
                          <div
                            onClick={this.searchPackage.bind(this, "filter")}
                            className="apply"
                          >
                            Apply
                          </div>
                          &nbsp;&nbsp;
                          <div
                            onClick={this.searchPackage.bind(this, "clearall")}
                            className="clear"
                          >
                            Clear all
                          </div>
                        </div>
                      </div>
                      <div className="filter-content">
                        <div className="filter-head">Category</div>
                        {this.state.expandedFilters.includes("category") && (
                          <div className="fliter-option-row cat-filter">
                            {categoryFilter?.map((item, index) => (
                              <div
                                className={`box ${
                                  this.state.categorylist?.includes(item)
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.handleChange("category", item)
                                }
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        )}
                        <br />
                        <div className="filter-head">Days</div>
                        {this.state.expandedFilters.includes("days") && (
                          <div className="fliter-option-row">
                            <FormGroup>
                              {daysFilter?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          color: "#4258BF",
                                        },
                                      }}
                                      checked={this.state.search_days.includes(
                                        item?.value
                                      )}
                                      onChange={() =>
                                        this.handleChange("days", item?.value)
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: 14 }}
                                      className="label"
                                    >
                                      {item?.label}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </div>
                        )}
                        <br />
                        <div className="filter-head">
                          Themes
                          <div
                            onClick={() =>
                              this.setState({
                                showMorefilter: !this.state.showMorefilter,
                              })
                            }
                            className="showMoreBtn"
                          >
                            {this.state.showMorefilter
                              ? "Show less"
                              : "Show more"}
                          </div>
                        </div>
                        {this.state.expandedFilters.includes("segment") && (
                          <div className="fliter-option-row cat-filter">
                            {this.state.segments?.map((item, index) => {
                              const displayedSegments = new Set();
                              const filterSegment = item.segment_short_name;
                              if (
                                displayedSegments.has(filterSegment) ||
                                filterSegment === null ||
                                filterSegment === ""
                              ) {
                                return null;
                              }
                              displayedSegments.add(filterSegment);
                              return (!this.state.showMorefilter &&
                                filterSegment !== "" &&
                                index < 5) ||
                                this.state.showMorefilter ? (
                                <div
                                  className={`box ${
                                    this.state.selectedSegment.includes(
                                      filterSegment
                                    )
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleChange("theme", item)
                                  }
                                >
                                  {filterSegment}
                                </div>
                              ) : null;
                            })}
                          </div>
                        )}
                        <br />
                        {packageType === "uop" && (
                          <>
                            <div className="filter-head">
                              Months
                              <div
                                onClick={() =>
                                  this.setState({
                                    showMoreMonths: !this.state.showMoreMonths,
                                  })
                                }
                                className="showMoreBtn"
                              >
                                {this.state.showMoreMonths
                                  ? "Show less"
                                  : "Show more"}
                              </div>
                            </div>
                            {this.state.expandedFilters.includes("month") && (
                              <div className="fliter-option-row">
                                <FormGroup>
                                  {this.state.nextTwelveMonths?.map(
                                    (item, index) => {
                                      return (!this.state.showMoreMonths &&
                                        index < 5) ||
                                        this.state.showMoreMonths ? (
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Checkbox
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  color: "#4258BF",
                                                },
                                              }}
                                              checked={this.state.selectedMonth.includes(
                                                item?.value || item?.label
                                              )}
                                              onChange={() =>
                                                this.handleChange(
                                                  "month",
                                                  item?.value
                                                )
                                              }
                                            />
                                          }
                                          label={
                                            <Typography
                                              sx={{ fontSize: 14 }}
                                              className="label"
                                            >
                                              {item?.label}
                                            </Typography>
                                          }
                                        />
                                      ) : null;
                                    }
                                  )}
                                </FormGroup>
                              </div>
                            )}
                            <br />
                          </>
                        )}
                        <div className="filter-head">Price</div>
                        {this.state.expandedFilters.includes("price") && (
                          <div className="fliter-option-row">
                            <FormGroup>
                              {priceFilterOptions?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          color: "#4258BF",
                                        },
                                      }}
                                      checked={
                                        this.state.priceFilter === item?.value
                                      }
                                      onChange={() =>
                                        this.handlePriceFilter(item?.value)
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ fontSize: 14 }}
                                      className="label"
                                    >
                                      {item?.label}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${this.state.isMobile ? "col" : "right-col"}`}>
                  {this.state.packageLoading === false && (
                    <div className="showing-header">
                      <span className="av-result">
                        {this.state.totalpackagecount} packages available
                      </span>
                      {!this.state.isMobile && (
                        <div className="filter-row-new">
                          <h5>Sort By</h5>
                          <div>
                            <select
                              value={this.state.sortOption}
                              onChange={this.handleChange.bind(
                                this,
                                "sortOption"
                              )}
                            >
                              <option value="">Recommended</option>
                              <option value="lowToHigh">
                                Price: Low to High
                              </option>
                              <option value="highToLow">
                                Price: High to Low
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.packageLoading === true ? (
                    <div className="search-loader">
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <ContentLoader
                            viewBox={`0 0 ${
                              this.state.isMobile ? "380" : "100%"
                            } 280`}
                            height={280}
                            width={`${this.state.isMobile ? "380" : "100%"}`}
                            speed={3}
                            key={index}
                          >
                            <rect
                              x="3"
                              y="3"
                              rx="10"
                              ry="10"
                              width={`${this.state.isMobile ? "380" : "100%"}`}
                              height="180"
                            />
                            <rect
                              x="6"
                              y="190"
                              rx="0"
                              ry="0"
                              width={`${this.state.isMobile ? "320" : "60%"}`}
                              height="20"
                            />
                            <rect
                              x="4"
                              y="215"
                              rx="0"
                              ry="0"
                              width="320"
                              height="20"
                            />
                            <rect
                              x="4"
                              y="242"
                              rx="0"
                              ry="0"
                              width="380"
                              height="20"
                            />
                          </ContentLoader>
                        );
                      })}
                    </div>
                  ) : (
                    <ul
                      className={`${
                        this.state.isMobile
                          ? "ml-view package-ul"
                          : "package-ul1"
                      }`}
                    >
                      {this.state.packageLoading === false ? (
                        <>
                          <>
                            {this.state.filterResult.length === 0 && (
                              <div className="no-package-container">
                                <div className="no-package-icon">
                                  <img src={ExclamatoryIcon} alt="NoImage" />
                                </div>
                                <div className="no-package-text">
                                  <h3>
                                    {!this.state.searchfilter
                                      ? `NO PACKAGES FOUND FOR ${this.state.search_days} DAYS`
                                      : "NO PACKAGES FOUND"}
                                  </h3>
                                  <p>
                                    Currently no packages available for this
                                    filter.Please select another days filter to
                                    see available packages
                                  </p>
                                </div>
                              </div>
                            )}
                            <>
                              {this.state.filterResult.length === 0 && (
                                <>
                                  <div>Recommended packages</div>
                                  <>{this.state.display_No_package}</>
                                </>
                              )}
                            </>
                          </>

                          <>
                            {" "}
                            {this.state.filterResult.length > 0 &&
                              this.state.display_package}
                          </>
                          {this.state.loadMoreLoading && (
                            <div className="search-loader">
                              {[1, 2, 3, 4].map((item, index) => {
                                return (
                                  <ContentLoader
                                    viewBox={`0 0 ${
                                      this.state.isMobile ? "380" : "100%"
                                    } 280`}
                                    height={280}
                                    width={`${
                                      this.state.isMobile ? "380" : "100%"
                                    }`}
                                    speed={3}
                                    key={index}
                                  >
                                    <rect
                                      x="3"
                                      y="3"
                                      rx="10"
                                      ry="10"
                                      width={`${
                                        this.state.isMobile ? "380" : "100%"
                                      }`}
                                      height="180"
                                    />
                                    <rect
                                      x="6"
                                      y="190"
                                      rx="0"
                                      ry="0"
                                      width={`${
                                        this.state.isMobile ? "320" : "60%"
                                      }`}
                                      height="20"
                                    />
                                    <rect
                                      x="4"
                                      y="215"
                                      rx="0"
                                      ry="0"
                                      width="320"
                                      height="20"
                                    />
                                    <rect
                                      x="4"
                                      y="242"
                                      rx="0"
                                      ry="0"
                                      width="380"
                                      height="20"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </div>
                          )}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </ul>
                  )}
                  {!this.state.searchPopupFlow && this.state.loadmoreState && (
                    <div
                      className="loadmorepackage"
                      onClick={this.loadMore.bind(this)}
                    >
                      <button>load more</button>
                    </div>
                  )}
                </div>
              </div>
              <div className="offer-main">
                <div className="offer-design-card">
                  <div className="text1">
                    Enjoy <span>10%</span> Off when you signup and
                    <br /> other exclusive offers
                  </div>
                  <div className="text2">
                    Use code <span>FIRSTBOOKING10</span>
                  </div>
                  <div className="text3">T&C apply*</div>
                  <button
                    href={void 0}
                    onClick={this.openPopup}
                    className="button cy-pack"
                  >
                    Signup now
                  </button>
                </div>
              </div>

              <ModalPopup
                open={this.state.showSignupPopup}
                handleClose={() => this.setState({ showSignupPopup: false })}
                classNames="common-popup customer-signup-popup"
              >
                <SignupComponent />
              </ModalPopup>
            </div>
          </div>
        </section>

        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <FilterSheet
            state={this.state}
            currentThis={this}
            categorylist={this.state.categorylist}
            handleChange={(type, event) => this.handleChange(type, event)}
            handleApplyFilter={this.searchPackage.bind(this, "filter")}
            handleClearAll={this.searchPackage.bind(this, "clearall")}
            daysFilter={daysFilter}
            search_days={this.state.search_days}
            handleDaysFilter={this.handleDaysFilter.bind(this)}
            nextnextTwelveMonths={this.state.nextTwelveMonths}
            closeFilterSheet={() => this.setState({ openFilterSheet: false })}
            segmentFilter={this.segmentFilter.bind(this)}
            priceFilterOptions={priceFilterOptions}
            priceFilter={this.state.priceFilter}
            handlePriceFilter={this.handlePriceFilter.bind(this)}
          />
        </BottomSheet>
        <BottomSheet
          open={this.state.openSortSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openSortSheet: false })}
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="searchContainer__bottomSheetMain__container__sourceAir">
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === ""
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                Recommended
              </div>
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "lowToHigh"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "lowToHigh" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                Price: Low to High
              </div>
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "highToLow"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "highToLow" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                Price: High to Low
              </div>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

export default Customerpackage;
