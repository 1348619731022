import React from "react";
import "./SearchComponent.scss";
import Curated from "../../../common/images/Curated_Icon.svg";
import Customize from "../../../common/images/Customize_Icon.svg";
import mobsearch from "../../../common/images/search-blue.svg";
import destim from "../../../common/images/europe.jpg";
import { Calendar } from "react-date-range";
import { Box, Button, Modal } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "100%",
  overflowY: "hidden",
  height: "90%",
};

const SearchComponent = (sProps) => {
  const {
    open,
    handleTextChange,
    state,
    handleClose,
    setState,
    selectDestination,
    props,
    nextYearMonths,
    toggleShowMore,
    priceFilterOptions,
    searchClearAll,
  } = sProps;

  const handleSearch = () => {
    props.continueExplore();
  };
  const cityClearAll = () => {
    searchClearAll();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className={`pk-search-main common-popup search-popup`}
        tabIndex={-1}
      >
        <div className="pk-search-main__tabs">
          <div
            className={`pk-search-main__tabs__tab ${
              props.packageType === "vacation" && "active"
            }`}
            onClick={() => props.selectPacakgeType("vacation")}
          >
            <img src={Customize} />
            Customizable
          </div>
          <div
            className={`pk-search-main__tabs__tab ${
              props.packageType === "uop" && "active"
            }`}
            onClick={() => props.selectPacakgeType("uop")}
          >
            <img src={Curated} />
            Curated
          </div>
          <div
            className="pk-search-main__tabs__closeBtn"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="pk-search-main__content">
          <div className="pk-search-main__search-main">
            <div className="search-pop">
              <input
                type="text"
                id="search_city"
                placeholder="Search for Destinations"
                onChange={handleTextChange}
                onClick={() => {
                  setState(
                    {
                      opendestinationList: true,
                    },
                    () => {
                      props.filterSearchCity();
                    }
                  );
                }}
                value={state.searchCityKeyWord}
              />
              <img src={mobsearch} />
            </div>
            {state.opendestinationList === true &&
              state.searchCityList !== "" &&
              state.searchCityKeyWord !== "" && (
                <div className="search-results">
                  <div className="search-with-padd">
                    <div className="search-with-img-result mCustomScrollbar">
                      {state.searchCityList !== "" && (
                        <ul>
                          {state.searchCityList.map((item, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={void 0}
                                  onClick={() => selectDestination(item)}
                                >
                                  <img
                                    src={
                                      item.thumbnail !== "" &&
                                      item.thumbnail !== null
                                        ? item.thumbnail
                                        : item.images !== "" &&
                                          item.images !== null
                                        ? item.images
                                        : destim
                                    }
                                  />
                                  <span>
                                    <strong>{item.city_name}</strong>
                                    <em>{item.country_name}</em>
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              )}
            {props.locationValidation === true && (
              <span class="error">Please Select Your Destinations</span>
            )}
          </div>
          {/* <div className="pk-search-main__suggestions">
            {[1, 2, 3, 4, 5, 5].map((val, index) => (
              <div className="pk-search-main__suggestions__tag" key={index}>
                Singapore
              </div>
            ))}
          </div> */}
          {props.packageType === "uop" && (
            <>
              <div className="pk-search-main__divider"></div>
              <div className="pk-search-main__row">
                <div className="pk-search-main__title">Month of vacation</div>
                <div className="option-row1">
                  {nextYearMonths?.map((item, index) => {
                    return (!state.showMoreMonths && index < 5) ||
                      state.showMoreMonths ? (
                      <div
                        key={index}
                        className={`option ${
                          item.value === props.selectedMonth ? "active" : ""
                        }`}
                        onClick={props.monthFilter.bind(this, item?.value)}
                      >
                        <div className="month-filter-font">{item?.label}</div>
                      </div>
                    ) : null;
                  })}
                  <a
                    onClick={() => toggleShowMore()}
                    className="month-showMoreBtn"
                  >
                    {state.showMoreMonths ? "Show Less" : "Show More"}
                  </a>
                </div>
              </div>
              {props.error_month === true && (
                <span class="error">Please Select Your vacation Month</span>
              )}
            </>
          )}
          <div className="pk-search-main__divider"></div>
          {props.packageType === "vacation" && (
            <>
              <div className="pk-search-main__row">
                <div className="pk-search-main__title">Select start date</div>
                <div>
                  <div className="step-content-calender textcenter searchContainer__bottomSheetMain__container__date">
                    <Calendar
                      onChange={props.handleSelectDate}
                      minDate={new Date()}
                      date={props.homeState.start_date}
                      months={props.homeState.isMobile ? 1 : 1}
                      preventSnapRefocus={true}
                      direction="horizontal"
                    />
                  </div>
                  {props.startDateValidation === true && (
                    <span class="error">Please Select Your start Date</span>
                  )}
                </div>
              </div>
              <div className="pk-search-main__divider"></div>
            </>
          )}
          <div className="pk-search-main__row">
            <div className="pk-search-main__title">Price range</div>
            <div className="option-row1">
              {priceFilterOptions?.map((item, index) => (
                <div
                  className={`option ${
                    item.value === props.priceVal ? "active" : ""
                  }`}
                  onClick={() => props.sateValChange("price", item.value)}
                  key={index}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pk-search-main__actions">
          <Button
            variant="text"
            className="pk-search-main__actions__clearBtn"
            onClick={() => {
              props.clearSearch();
              cityClearAll();
            }}
          >
            Clear all
          </Button>
          <Button
            variant="contained"
            className="pk-search-main__actions__searchBtn"
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SearchComponent;
