import React from "react";
import jwt_decode from "jwt-decode";
import { googleAppIduser } from "../../Helpers/Config";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import "./GoogleloginComponent.scss";

const GoogleLoginComponent = ({ loginGoogle, isMobile }) => {
  const handleLoginFailure = (err) => {
    console.log("err", err);
  };

  const handleLoginSuccess = (response) => {
    if (response.credential) {
      try {
        const profile = jwt_decode(response.credential);
        loginGoogle({
          name: profile.given_name,
          email: profile.email,
          picture: profile.picture,
          id: profile.sub,
        });
      } catch (error) {
        console.error("Error decoding Google JWT:", error);
      }
    }
  };

  return (
    <GoogleOAuthProvider clientId={googleAppIduser}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginFailure}
        useOneTap
        width={isMobile ? window.innerWidth - 40 : "400"}
        size="large"
        theme="outline"
        logo_alignment="center"
        shape="square"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
